import React from 'react'
import { Checkbox, FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { colors } from '../../../utils/colorVariables'
import { ELogActionTypes } from 'src/state/effector/history/logs.types'
import isEqual from 'lodash/isEqual'

export interface SelectOption {
  label: string
  value: ELogActionTypes
}

export const selectOptions: SelectOption[] = [
  {
    label: 'Change Role',
    value: ELogActionTypes.changeRole
  },
  {
    label: 'Change Whitelist',
    value: ELogActionTypes.changeWhitelist
  },
  {
    label: 'Send NFT',
    value: ELogActionTypes.sendNft
  },
  {
    label: 'Upgrade NFT',
    value: ELogActionTypes.upgradeNft
  },
  {
    label: 'Change US/International',
    value: ELogActionTypes.changeCountry
  },
  {
    label: 'Create template',
    value: ELogActionTypes.createTemplate
  },
  {
    label: 'Edit template',
    value: ELogActionTypes.editTemplate
  },
  {
    label: 'Archive template',
    value: ELogActionTypes.archiveTemplate
  },
  {
    label: 'Unarchive template',
    value: ELogActionTypes.unarchiveTemplate
  },
  {
    label: 'Submit airdrop',
    value: ELogActionTypes.submitAirdrop
  },
  {
    label: 'Airdrop note',
    value: ELogActionTypes.airdropNote
  },
  {
    label: 'Maintenance mode',
    value: ELogActionTypes.updateMaintenance
  }
]

interface Props {
  value: ELogActionTypes[]
  onChange: (event: SelectChangeEvent<ELogActionTypes[]>) => void
}

export const ActionTypeSelect: React.FC<Props> = ({ value, onChange }) => {
  const getSelectedOptionLabel = (element: ELogActionTypes) => {
    const selectedOption = selectOptions.find((opt: SelectOption) => opt.value === element)
    if (selectedOption) return selectedOption.label
    else return ''
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        multiple
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        renderValue={(selected: ELogActionTypes[]) => {
          return !isEqual(selected, []) ? (
            selected.map((element) => getSelectedOptionLabel(element)).join(', ')
          ) : (
            <>Show All Actions</>
          )
        }}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {selectOptions.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            <CheckboxStyled checked={value?.includes(optionValue)} size="small" />
          </MenuItemWrapper>
        ))}
        <MenuItemWrapper
          key="select-show-all"
          onClick={() => {
            if (!isEqual(value, [])) onChange({ target: { value: [] } } as SelectChangeEvent<[]>)
          }}
        >
          Show All Actions
          <CheckboxStyled checked={isEqual(value, [])} size="small" />
        </MenuItemWrapper>
      </Select>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.$black};
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
