import React, { Suspense, useLayoutEffect, useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/system'
import { useStore } from 'effector-react'

import { Navigation } from '../AdminPanel/Navigation'
import { Header } from '../AdminPanel/Header'
import { isMobile } from 'src/utils/isMobile'
import LoadingIndicator from '../LoadingIndicator'
import { AdminLayoutWrapper, BoxWrapper, ContentWrapper } from './styles'
import { useAccessController } from 'src/hooks/useAccessController'
import useMetaMask from 'src/hooks/useMetaMask'
import { $auth, $isMaintenanceModeActive } from 'src/state/effector/store'
import { $user, $isUserDeactivated } from 'src/state/effector/users/users.store'

import { UserRoles } from '../../state/effector/users/user.types'
import { MaintenanceModeOnBlock } from '../AdminPanel/MaintenanceMode/MaintenanceModeOnBlock'

const AdminLayout: React.FC = () => {
  const { isAllowed } = useAccessController(UserRoles.Viewer)
  const { account, isLoading } = useMetaMask()
  const auth = useStore($auth)
  const user = useStore($user)
  const isUserDeactivated = useStore($isUserDeactivated)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  const mobile = isMobile()

  const location = useLocation()

  useLayoutEffect(() => {
    // Destroy support beacon for Admin panel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window?.Beacon) window.Beacon!('destroy')
  }, [location.pathname])

  const needRedirect = useMemo(() => {
    if (isLoading) return false

    if (auth?.[account]) {
      if (user && (isUserDeactivated || !isAllowed)) {
        return true
      }
      return false
    }
    return true
  }, [auth, account, user, isUserDeactivated, isAllowed, isLoading])

  if (needRedirect) {
    let to = '/'
    if (!auth?.[account]) to = '/?redirectTo=/admin'

    return <Navigate to={to} replace={true} />
  }

  // Destroy support beacon for Admin panel
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window?.Beacon) window.Beacon('destroy')

  if (!isAllowed) {
    return <LoadingIndicator />
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Box position="relative" sx={{ paddingTop: isMaintenanceModeActive ? '37px' : 0 }}>
        <MaintenanceModeOnBlock />
        <AdminLayoutWrapper isMaintenanceModeActive={isMaintenanceModeActive}>
          <Navigation />

          <BoxWrapper
            mobile={mobile}
            isMaintenanceModeActive={isMaintenanceModeActive}
            className="hide-scroll"
          >
            <Header />

            <ContentWrapper mobile={mobile}>
              <Outlet />
            </ContentWrapper>
          </BoxWrapper>
        </AdminLayoutWrapper>
      </Box>
    </Suspense>
  )
}

export default AdminLayout
