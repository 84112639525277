import React, { useMemo, useState } from 'react'
import {
  AddressWrapper,
  CopiedMessageUpdated,
  CopyIconWrapper,
  MemberWrapper,
  renderNameWithTruncate
} from './index'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { truncateAddress } from '../../../utils/truncateAddress'
import styled from '@emotion/styled'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { colors } from '../../../utils/colorVariables'
import { isMobile } from '../../../utils/isMobile'
import usFlugIcon from 'src/assets/usFlag.svg'
import { TREASURY_ADDRESS_US } from 'src/constants/treasuryAddresses'

interface Copied {
  [key: string]: boolean
}

interface IProps {
  address: string
  name?: string
  id: string | number
  isShowUsIcon?: boolean
  isNoAccount?: boolean
  isNoAccountMsg?: string
  addressClassName?: string
}

export const AddressesCell: React.FC<IProps> = ({
  address,
  name,
  id,
  isShowUsIcon,
  isNoAccount,
  isNoAccountMsg,
  addressClassName
}) => {
  const [copied, setCopied] = useState<Copied>({})
  const mobile = isMobile()

  const isShowIcon = useMemo(
    () => isShowUsIcon || address.toLowerCase() === TREASURY_ADDRESS_US.toLowerCase(),
    [address, isShowUsIcon]
  )

  const onCopy = (copyId: string | number) => {
    setCopied((prev) => ({ ...prev, [copyId]: true }))
    setTimeout(() => setCopied((prev) => ({ ...prev, [copyId]: false })), 1000)
  }

  const getTruncatedAddress = (addressData: string) => truncateAddress(addressData, 2)

  return (
    <div style={{ width: '100%' }}>
      <AddressWrapper className={addressClassName}>
        <div className="text-to-copy">
          {isNoAccount && !!isNoAccountMsg ? isNoAccountMsg : getTruncatedAddress(address)}
        </div>
        {!isNoAccount && (
          <CopyIconWrapper height={mobile ? '18px' : '22px'}>
            <CopyToClipboard onCopy={() => onCopy(id)} text={address}>
              <StyledContentCopyIcon
                className="copy-icon"
                sx={{
                  color: '#A4B4D2',
                  cursor: 'pointer',
                  fontSize: mobile ? '18px' : '22px'
                }}
              />
            </CopyToClipboard>
            {copied[id] ? (
              <CopiedMessageUpdated className="copied">Copied!</CopiedMessageUpdated>
            ) : (
              ''
            )}
          </CopyIconWrapper>
        )}
      </AddressWrapper>

      {name ? (
        <MemberWrapper>
          {isShowIcon && <img src={usFlugIcon} alt="us_icon" />}
          {renderNameWithTruncate(name)}
        </MemberWrapper>
      ) : null}
    </div>
  )
}

const StyledContentCopyIcon = styled(ContentCopyIcon)`
  transition: 0.3s;

  &:hover {
    color: ${colors.$lightBlue};
  }
`
