import React from 'react'
import { useStore } from 'effector-react'
import styled from '@emotion/styled'

import { colors } from '../../../../utils/colorVariables'
import { Box, Typography } from '@mui/material'
import { isMobile } from '../../../../utils/isMobile'
import { UserRoles } from '../../../../state/effector/users/user.types'
import RadioButton from '../../../RadioButton'
import { ROLES } from '../../../../hooks/useAccessController'
import { $user } from '../../../../state/effector/users/users.store'

interface Props {
  role: UserRoles
  onClick: (val: UserRoles) => void
}

const RoleSelector: React.FC<Props> = ({ role = UserRoles.Viewer, onClick }) => {
  const mobile = isMobile()
  const user = useStore($user)
  const userRole = user?.role || UserRoles.Viewer

  const roles = [UserRoles.Admin, UserRoles.SuperAdmin]

  const checkIsNotAllowed = (accessRole: UserRoles) =>
    roles.includes(userRole) ? !(ROLES.indexOf(userRole) >= ROLES.indexOf(accessRole)) : true

  const handleOnClick = (accessRole: UserRoles) => {
    if (!checkIsNotAllowed(accessRole)) {
      onClick(accessRole)
    }
  }

  return (
    <Box width={'100%'}>
      <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
        Role
      </TypographyWrapper>

      <RoleItem
        role={UserRoles.SuperAdmin}
        disabled={checkIsNotAllowed(UserRoles.SuperAdmin)}
        onClick={() => handleOnClick(UserRoles.SuperAdmin)}
      >
        <RadioButton
          checked={role === UserRoles.SuperAdmin}
          disabled={checkIsNotAllowed(UserRoles.SuperAdmin)}
        />
        Super-Admin
      </RoleItem>

      <RoleItem
        role={UserRoles.Admin}
        disabled={checkIsNotAllowed(UserRoles.Admin)}
        onClick={() => handleOnClick(UserRoles.Admin)}
      >
        <RadioButton
          checked={role === UserRoles.Admin}
          disabled={checkIsNotAllowed(UserRoles.Admin)}
        />
        Admin
      </RoleItem>

      <RoleItem
        role={UserRoles.Editor}
        disabled={checkIsNotAllowed(UserRoles.Editor)}
        onClick={() => handleOnClick(UserRoles.Editor)}
      >
        <RadioButton
          checked={role === UserRoles.Editor}
          disabled={checkIsNotAllowed(UserRoles.Editor)}
        />
        Editor
      </RoleItem>

      <RoleItem
        role={UserRoles.Viewer}
        disabled={false}
        onClick={() => handleOnClick(UserRoles.Viewer)}
      >
        <RadioButton checked={role === UserRoles.Viewer} disabled={false} />
        Viewer
      </RoleItem>
    </Box>
  )
}

export default RoleSelector

interface StyledProps {
  mobile: boolean
}

interface RoleItemProps {
  role: UserRoles
  disabled: boolean
}

const TypographyWrapper = styled(Typography)<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  span {
    color: ${colors.$primary};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const RoleItem = styled('div')<RoleItemProps>`
  margin-bottom: 15px;
  background: #f8fafc;
  cursor: pointer;

  border: 1px solid #d4dbe7;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  height: 53px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: black;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  pointer-events: ${({ disabled }) => (disabled ? 'none !important' : 'auto')};

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  img {
    margin-right: 10px;
  }
`
