import React, { useMemo, useState } from 'react'
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import logoDarkSm from 'src/assets/logo_dark_sm.svg'

import useMetaMask from '../../hooks/useMetaMask'
import { truncateAddress } from '../../utils/truncateAddress'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { colors } from '../../utils/colorVariables'
import { ButtonTransparent } from '../Button'
import { isMobile } from '../../utils/isMobile'
import metamask from '../../assets/metamask.svg'
import GTIcon from '../../assets/goerliTestnetIcon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAccessController } from 'src/hooks/useAccessController'
import { networks } from 'src/constants/currency'
import { ENetworkTypes } from 'src/types/common.types'
import { useStore } from 'effector-react'
import { $auth } from '../../state/effector/store'
import { $user } from 'src/state/effector/users/users.store'
import { UserRoles } from '../../state/effector/users/user.types'

const AddressDropdown = () => {
  const { account, disconnect, chainId, alias } = useMetaMask()
  const networkType = networks[chainId as keyof typeof networks]?.networkType
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [copied, setCopied] = useState(false)
  const auth = useStore($auth)
  const user = useStore($user)

  const open = Boolean(anchorEl)

  const mobile = isMobile()
  const { pathname } = useLocation()
  const { isAllowed } = useAccessController(UserRoles.Viewer)

  const isAllowedToShowLink = useMemo(
    () =>
      !user?.deactivated &&
      auth?.[account] &&
      isAllowed &&
      pathname.slice(0, 6) !== '/admin' &&
      user?.whiteListStatus === 'ACCEPTED',
    [pathname, isAllowed, account, auth, user]
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const nav = useNavigate()
  const handleLogout = () => {
    localStorage.clear()
    disconnect()
    nav('/')
    window.location.reload()
  }

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  const userRoleSymbol = useMemo(
    () =>
      user?.role && window.location.pathname.includes('/admin')
        ? (user.role === UserRoles.User && '') ||
          (user.role === UserRoles.Viewer && '(V)') ||
          (user.role === UserRoles.Editor && '(E)') ||
          (user.role === UserRoles.Admin && '(A)') ||
          (user.role === UserRoles.SuperAdmin && '(SA)')
        : '',
    [user?.role]
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ButtonTransparent
        sx={{
          color: colors.$footer,
          fontWeight: 400,
          fontSize: mobile ? 12 : 16,
          background: '#F8FAFC',
          ...(alias && {
            textTransform: 'none'
          })
        }}
        width="auto"
        variant="outlined"
        border="false"
        boxshadow="true"
        endIcon={<KeyboardArrowDownIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Box mr={1} display="flex" justifyContent="flex-start" alignItems="center">
          {networkType === ENetworkTypes.SEPOLIA && <img src={GTIcon} />}
        </Box>
        {alias || truncateAddress(account)}
        <img style={{ margin: '0 5px 0 10px' }} src={metamask} alt="metamask" />
        {userRoleSymbol}
      </ButtonTransparent>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          sx={{
            width: mobile ? 175 : 200,
            borderBottom: '1px solid #E5E5E5',
            padding: '10px 15px',
            fontWeight: 'bold'
          }}
        >
          <div
            style={{
              width: '100%'
            }}
          >
            {copied ? <CopiedMessage>Copied!</CopiedMessage> : ''}
            <CopyToClipboard onCopy={onCopy} text={account}>
              <ListItemIcon sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <ContentCopyIcon sx={{ fontSize: 18, marginRight: '15px' }} />
                <ListItemText sx={{ color: colors.$footer }}>
                  {truncateAddress(account)}
                </ListItemText>
              </ListItemIcon>
            </CopyToClipboard>
          </div>
        </MenuItem>
        {alias && (
          <MenuItem
            sx={{
              width: mobile ? 175 : 200,
              borderBottom: '1px solid #E5E5E5',
              padding: '10px 15px',
              fontWeight: 'bold'
            }}
          >
            <div
              style={{
                width: '100%'
              }}
            >
              <ListItemIcon sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <ListItemText sx={{ color: colors.$footer }}>{alias}</ListItemText>
              </ListItemIcon>
            </div>
          </MenuItem>
        )}
        {isAllowedToShowLink ? (
          <MenuItem
            sx={{
              width: mobile ? 175 : 200,
              borderBottom: '1px solid #E5E5E5',
              fontWeight: 'bold',
              padding: 0
            }}
          >
            <StyledLink to="/admin">
              <ListItemIcon>
                <ManageAccountsIcon sx={{ fontSize: 18 }} />
              </ListItemIcon>
              <ListItemText sx={{ color: colors.$footer }}>Admin Panel</ListItemText>
            </StyledLink>
          </MenuItem>
        ) : null}
        {window.location.pathname.includes('/admin') ? (
          <MenuItem
            sx={{
              width: mobile ? 175 : 200,
              borderBottom: '1px solid #E5E5E5',
              padding: 0,
              fontWeight: 'bold'
            }}
          >
            <StyledLink to="/" target="_blank" rel="noopener noreferrer">
              <ListItemIcon sx={{ marginLeft: '-1px' }}>
                <img src={logoDarkSm} />
              </ListItemIcon>
              <ListItemText sx={{ color: colors.$footer }}>Open Dapp</ListItemText>
            </StyledLink>
          </MenuItem>
        ) : null}
        <MenuItem
          sx={{
            width: mobile ? 175 : 200,
            borderBottom: '1px solid #E5E5E5',
            padding: '10px 15px',
            fontWeight: 'bold'
          }}
          onClick={handleLogout}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ fontSize: 18 }} />
          </ListItemIcon>
          <ListItemText sx={{ color: colors.$footer }}>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default AddressDropdown

const StyledLink = styled(Link)`
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const CopiedMessage = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  background: ${colors.$primaryLight};
  color: ${colors.$white};
  border-radius: 10px;
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 700;
  z-index: 1;
`
