import React, { useMemo } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import styled from '@emotion/styled'

import { isMobile } from 'src/utils/isMobile'
import { colors } from 'src/utils/colorVariables'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'
import warningIcon from 'src/assets/warning.png'

import { AvailableContainer } from './AvailableContainer'
import { IResources } from '../../../../types/common.types'
import { numberFormatter } from '../../../../utils/numberFormatter'
import useMetaMask from 'src/hooks/useMetaMask'
import { networks } from 'src/constants/currency'

interface IResourcesPanel {
  resources: IResources
}

const titles = {
  usdc: 'International Airdrop Safe',
  usdcUS: 'USA Airdrop Safe',
  eth: 'Gas Wallet',
  foundingOwners: 'NFT Safe'
} as { [key: string]: string }

// const valueString = {
//   usdc: 'USDC',
//   usdcUS: 'USDC',
//   eth: 'ETH',
//   foundingOwners: 'SXT',
//   secondOwners: 'SXT2023'
// } as { [key: string]: string }

const icons = {
  usdc: <img src={internationalIcon} alt="internationalIcon" />,
  usdcUS: <img src={usFlagIcon} alt="usFlagIcon" width="16px" />
} as Record<string, JSX.Element>

export const Resources: React.FC<IResourcesPanel> = ({ resources }) => {
  const mobile = isMobile()
  const { chainId } = useMetaMask()

  const addresses = useMemo(
    () =>
      ({
        eth: networks[chainId as keyof typeof networks]?.networkAddresses?.OperatorAddress || '',
        usdc:
          networks[chainId as keyof typeof networks]?.networkAddresses?.InternationalAirdropSafe ||
          '',
        usdcUS: networks[chainId as keyof typeof networks]?.networkAddresses?.UsAirdropSafe || '',
        foundingOwners: networks[chainId as keyof typeof networks]?.networkAddresses?.NFTSafe || '',
        secondOwners: networks[chainId as keyof typeof networks]?.networkAddresses?.NFTSafe || ''
      } as Record<string, string>),
    [chainId, networks]
  )

  const resourcesMap = useMemo(() => {
    return Object.keys(resources).map((key) => {
      const name = resources[key as keyof typeof resources]?.name
      const balance = resources[key as keyof typeof resources]?.balance
        ? String(resources[key as keyof typeof resources]?.balance).split(' ')[0]
        : 0

      const valueString = resources[key as keyof typeof resources]?.balance
        ? String(resources[key as keyof typeof resources]?.balance).split(' ')[1]
        : ''

      const threshold = resources[key as keyof typeof resources]?.threshold
        ? Number(resources[key as keyof typeof resources]?.threshold)
        : 0

      const formattedBalance =
        +balance < 1000000 ? Number(balance)?.toLocaleString('en-US') : numberFormatter(+balance, 2)

      const title = titles[key] || name || key

      return (
        <div key={key} className="item">
          <AvailableContainer
            address={addresses[key]}
            icon={icons[key]}
            title={title}
            value={formattedBalance}
            valueString={valueString}
            isNotAvailable={+balance < threshold}
          />
        </div>
      )
    })
  }, [resources, addresses])

  return (
    <div>
      {!isEmpty(resources) ? (
        <>
          <Typography
            color={colors.$secondary}
            sx={{ fontSize: mobile ? 18 : 20, fontWeight: 500 }}
            variant="subtitle1"
            pb={1}
          >
            Available Resources
          </Typography>
          <Typography
            gap="8px"
            display="flex"
            alignItems="center"
            color={colors.$secondary}
            sx={{ fontSize: 16, fontWeight: 500 }}
            variant="subtitle1"
            pb={2}
          >
            <img src={warningIcon} alt="warningIcon" />
            If you see this icon near a wallet, contact a manager to topup
          </Typography>
          <Container>{resourcesMap}</Container>
        </>
      ) : (
        <Box display="flex" justifyContent="center" py={5}>
          <CircularProgress />
        </Box>
      )}
    </div>
  )
}

const Container = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr));

  @media (max-width: 1090px) {
    grid-template-columns: repeat(2, minmax(240px, 1fr));
  }
  gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .item {
    & > div {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`
