import { ENftTypes } from '../../../../constants/nftTypes'
import { IValues } from './types'
import { ENetworkTypes, IResources } from '../../../../types/common.types'

export const getNFTTypeByIndex = (index: number) => {
  switch (index) {
    case 0:
      return ENftTypes.Founding
    case 1:
    case 2:
      return ENftTypes.Second
    default:
      return ENftTypes.Founding
  }
}

export const serializedValues = (values: IValues, networkType: ENetworkTypes) => {
  return {
    ...values,
    networkType
  }
}

export const getAvailableResource = (
  nftKey: string,
  resources: IResources,
  isFunding?: boolean
) => {
  const key = isFunding ? nftKey : nftKey.toLowerCase()
  const resource = resources && nftKey ? `${resources[key]?.balance}` : '0'

  return +resource.substring(0, resource.indexOf(' '))
}

export const getNFTIndexByName = (name: ENftTypes) => {
  switch (name) {
    case ENftTypes.Founding:
      return 0
    case ENftTypes.Second:
      return 1
    default:
      return undefined
  }
}
