import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'
import { IconButton, InputAdornment } from '@mui/material'
import { Box } from '@mui/system'

import { colors } from 'src/utils/colorVariables'
import { ButtonPrimaryOutlined } from 'src/components/Button'
import { TextFieldPrimary } from 'src/components/TextField'
import deleteIcon from 'src/assets/minus.jpg'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { User } from 'src/state/effector/users/user.types'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'
import ErrorMessage from 'src/components/ErrorMessage'
import { CHAIN_INFO } from 'src/constants'
import useMetaMask from 'src/hooks/useMetaMask'

export interface SpecificMember {
  name: string
  ethAddress: string
  amount: string
  tribePoints?: string
  percent: string
  country: string
  address?: string
  balance?: number
  user?: {
    ethAddress: string
    name: string
    country: string
  }
  reward?: {
    status: string
    txHash: string
  }
  isVouter?: boolean
  isNftOwner?: boolean
}

interface Props {
  setFieldValue: (name: string, value: any, shouldValidate?: boolean) => void
  data: Array<SpecificMember>
  budget: string
  error?: false | string | Array<Record<string, string> | undefined>
  touched?: any
  budgetType?: string
  region: string
  template: Record<string, any>
}

let timer = null as any

let defaultData = {
  inputState: ['search'],
  search: [''],
  showSearchResult: [false],
  membersBySearch: [[]] as Array<User[]>
}

export const SpecificMembers = ({
  data = [],
  setFieldValue,
  region,
  budget,
  error,
  touched = [],
  budgetType,
  template
}: Props) => {
  const { chainId } = useMetaMask()
  const [membersBySearch, handleMembersBySearch] = useState<Array<User[]>>(
    defaultData.membersBySearch
  )
  const [isLoading, handleIsLoading] = useState(false)
  const [showSearchResult, handleShowSearchResult] = useState<boolean[]>(
    defaultData.showSearchResult
  )
  const [search, handleSearch] = useState<string[]>(defaultData.search)
  const [inputState, handleInputState] = useState<string[]>(defaultData.inputState)

  const addedAmount = useMemo(
    () => data.reduce((acc, next) => acc + (+next.amount || 0), 0),
    [data]
  )

  useEffect(() => {
    return () => {
      defaultData = {
        inputState: ['search'],
        search: [''],
        showSearchResult: [false],
        membersBySearch: [[]] as Array<User[]>
      }
    }
  }, [])

  useEffect(() => {
    amountsRecalculation()
  }, [budget])

  useEffect(() => {
    defaultData = {
      inputState: ['search'],
      search: [''],
      showSearchResult: [false],
      membersBySearch: [[]] as Array<User[]>
    }
    handleSearch([''])
    handleInputState(['search'])
    handleMembersBySearch([[]])
    handleShowSearchResult([false])
    setFieldValue(
      'specificMembers',
      [
        {
          name: '',
          ethAddress: '',
          country: '',
          amount: '',
          percent: ''
        }
      ],
      true
    )
  }, [region])

  const amountsRecalculation = () => {
    const tempData = [...data].map((el) => {
      const tempEl = { ...el }
      if (budgetType === 'sum') {
        tempEl.percent = Number(((+el.amount * 100) / (+budget || 1)).toFixed(2)).toString()
      } else {
        tempEl.amount = Number(((+budget || 1) * (+el.percent / 100)).toFixed(6)).toString()
      }
      return tempEl
    })

    setFieldValue('specificMembers', tempData, true)
  }

  const addNewMember = () => {
    const tempData = [...data]
    tempData.push({ name: '', ethAddress: '', country: '', amount: '', percent: '' })
    setFieldValue('specificMembers', tempData, true)
    handleSearch((state) => {
      const res = [...state, '']
      defaultData.search = res
      return res
    })
    handleInputState((state) => {
      const res = [...state, 'search']
      defaultData.inputState = res
      return res
    })
    handleMembersBySearch((state) => {
      const res = [...state, []]
      defaultData.membersBySearch = res
      return res
    })
  }

  const changeAmount = (event: any, index: number) => {
    const tempData = [...data]
    const value =
      budgetType === 'sum'
        ? event.target.value.match(/[0-9]*[.]?[0-9]{0,6}/g)[0]
        : event.target.value.match(/[0-9]*[.]?[0-9]{0,2}/g)[0]

    if (budgetType === 'sum') {
      tempData[index].amount = value
      tempData[index].percent = Number(((+value * 100) / (+budget || 1)).toFixed(2)).toString()
    } else {
      tempData[index].amount = Number(((+budget || 1) * (+value / 100)).toFixed(6)).toString()
      tempData[index].percent = value
    }

    setFieldValue('specificMembers', tempData, true)
  }

  const deleteMember = (index: number) => {
    const tempData = [...data]

    tempData.splice(index, 1)
    setFieldValue('specificMembers', tempData, true)

    handleSearch((state) => {
      state.splice(index, 1)
      defaultData.search = state
      return state
    })
    handleInputState((state) => {
      state.splice(index, 1)
      defaultData.inputState = state
      return state
    })
    handleMembersBySearch((state) => {
      state.splice(index, 1)
      defaultData.membersBySearch = state
      return state
    })

    handleShowSearchResult((state) => {
      state.splice(index, 1)
      defaultData.showSearchResult = state
      return state
    })
  }

  const searchMembers = useCallback(
    async (index: number, value: string) => {
      try {
        handleIsLoading(true)
        const res = await apiService.getMembersForAirdrop({
          search: value,
          region
        })
        const tempData = [...membersBySearch]

        tempData[index] = res.data

        defaultData.membersBySearch = tempData
        handleMembersBySearch(tempData)
      } catch (e) {
        console.log('log => error', e)
      } finally {
        handleIsLoading(false)
      }
    },
    [membersBySearch]
  )

  const onSearch = (index: number, value: string) => {
    clearTimeout(timer)
    const tempData = [...search]
    tempData[index] = value
    defaultData.search = tempData
    handleSearch(tempData)
    timer = setTimeout(() => searchMembers(index, value), 500)
  }

  const changeInputState = (index: number, value: string) => {
    const tempdata = [...inputState]
    tempdata[index] = value
    defaultData.inputState = tempdata
    handleInputState(tempdata)
    if (value === 'search') {
      const tempData = [...data]
      tempData.splice(index, 1, {
        ...data[index],
        ethAddress: ''
      })
      setFieldValue('specificMembers', tempData, true)
      setTimeout(() => document.getElementById('search')?.focus(), 250)
    }
  }

  const toggleShowSearchResult = async (index: number, value: boolean) => {
    if (!value) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true)
        }, 250)
      })
    }
    handleShowSearchResult((state) => {
      const tempData = [...state]
      tempData[index] = value
      defaultData.showSearchResult = tempData
      return tempData
    })
  }

  const onSelecteMember = async (index: number, user: User, e: any) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      handleIsLoading(true)

      const verifyUser = await apiService.verifyMemberNft({
        ethAddress: user.ethAddress,
        network: CHAIN_INFO[chainId].networkType,
        isVotingRequired: template.isVotingRequired === 'yes',
        frequency: template.frequency
      })
      const tempData = [...data]
      tempData.splice(index, 1, {
        ...data[index],
        name: user.name || '-',
        ethAddress: user.ethAddress,
        country: user.country,
        ...verifyUser.data
      })

      setFieldValue('specificMembers', tempData)
      changeInputState(index, 'member')
    } catch (err) {
    } finally {
      handleIsLoading(false)
    }
  }

  const formattedAvailableBudget = useMemo(() => {
    if (budgetType === 'sum') {
      return `${Number((+budget - addedAmount).toFixed(6))} USDC `
    }
    return `${Number(Number(100 - ((addedAmount || 0) * 100) / (+budget || 1)).toFixed(2))}%`
  }, [budgetType, budget, addedAmount])

  const getMemberErrorString = (memeber: SpecificMember) => {
    const errors = []
    if (!memeber.isNftOwner) errors.push('No NFT')
    if (!memeber.isVouter) errors.push('No Vote')
    return errors.join(' / ')
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Container>
        <div className="title">Which members you want to reward and how much?</div>
        <MemberRows>
          {data.map((member, index) => (
            <>
              <MemberRow key={index}>
                {inputState[index] === 'search' ? (
                  <div>
                    <SearchInputContainer>
                      <TextFieldPrimary
                        id="search"
                        onFocus={() => toggleShowSearchResult(index, true)}
                        onBlur={() => toggleShowSearchResult(index, false)}
                        fullWidth
                        value={search[index]}
                        placeholder="Search by name or wallet"
                        onChange={(e: any) => onSearch(index, e.target.value)}
                        InputProps={{
                          style: {
                            height: '60px',
                            fontSize: 16
                          }
                        }}
                      />
                      {showSearchResult[index] && (
                        <SearchResults>
                          {membersBySearch[index]?.length ? (
                            <>
                              {membersBySearch[index].map(
                                (el, i) =>
                                  data.every(
                                    (specificMember) => specificMember.ethAddress !== el.ethAddress
                                  ) && (
                                    <>
                                      <div
                                        className="row"
                                        onClick={(e: any) => onSelecteMember(index, el, e)}
                                      >
                                        <div className="ethAddress">{el.ethAddress}</div>
                                        <Box display="flex" alignItems="center" minWidth="1px">
                                          <img
                                            src={
                                              el.country === 'US' ? usFlagIcon : internationalIcon
                                            }
                                            alt="country"
                                          />
                                          <div className="name">{el.name}</div>
                                        </Box>
                                      </div>
                                      {i < membersBySearch[index]?.length - 1 && (
                                        <div className="hr" />
                                      )}
                                    </>
                                  )
                              )}
                            </>
                          ) : (
                            Boolean(search[index]) && (
                              <div className="row">
                                <div className="empty">Nothing was found.</div>
                              </div>
                            )
                          )}
                        </SearchResults>
                      )}
                    </SearchInputContainer>
                    {Array.isArray(error) && touched[index] && error[index]?.ethAddress && (
                      <ErrorMessage message={error[index]?.ethAddress} isError />
                    )}
                  </div>
                ) : (
                  <MemberInfo onClick={() => changeInputState(index, 'search')}>
                    <div className="ethAddress">{member.ethAddress}</div>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center" minWidth="1px">
                        <img
                          src={member.country === 'US' ? usFlagIcon : internationalIcon}
                          alt="country"
                        />
                        <div className="name">{member.name}</div>
                      </Box>
                      <NoNftOrVote>{getMemberErrorString(member)}</NoNftOrVote>
                    </Box>
                  </MemberInfo>
                )}
                <div>
                  <AmountFiledContainer>
                    <TextFieldPrimary
                      name="amount"
                      value={budgetType === 'sum' ? member.amount : member.percent}
                      placeholder="Amount"
                      onChange={(e: any) => changeAmount(e, index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {budgetType === 'sum' ? 'USDC' : '%'}
                          </InputAdornment>
                        ),
                        className: budgetType === 'sum' ? 'sumAmount' : 'percentAmount'
                      }}
                      sx={{ maxWidth: '180px' }}
                    />
                    {budgetType !== 'sum' && (
                      <div className="percentInUSDC">{member.amount} USDC</div>
                    )}
                  </AmountFiledContainer>
                  {Array.isArray(error) && touched[index] && error[index]?.amount && (
                    <ErrorMessage message={error[index]?.amount} isError />
                  )}
                </div>
                {data.length > 1 && (
                  <DeleteRowBtn onClick={() => deleteMember(index)}>
                    <img src={deleteIcon} alt="deleteIcon" />
                  </DeleteRowBtn>
                )}
              </MemberRow>
            </>
          ))}
        </MemberRows>
        <AddMemberBtn onClick={addNewMember}>
          Add Member
          <AddIcon sx={{ color: colors.$blue, width: '20px', marginLeft: '12px' }} />
        </AddMemberBtn>
        <Hr />
        <AvailableContainer>
          <div className="label">Available:</div>
          {Boolean(+budget) ? (
            <div className="values">
              {formattedAvailableBudget}
              <span> / </span>
              {budgetType === 'sum' ? budget : '100%'}
            </div>
          ) : (
            '-'
          )}
        </AvailableContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  background-color: #f8fafc;
  border: 1px solid ${colors.$borderSecondary};
  padding: 24px;
  margin-top: 32px;
  .title {
    color: ${colors.$secondary};
    font-weight: 600;
    margin-bottom: 16px;
    font-size: 16px;
  }
  .percentAmount {
    color: ${colors.$secondary};
    height: 60px;
    align-items: flex-start;
    font-size: 16px;
    input {
      font-size: 16px;
      padding-top: 8px;
    }
    .MuiInputAdornment-root {
      align-items: flex-start;
      padding-top: 6px;
      > * {
        color: ${colors.$secondary};
      }
    }
  }
  .sumAmount {
    color: ${colors.$secondary};
    input {
      font-size: 16px;
    }
    height: 60px;
    font-size: 16px;
    .MuiInputAdornment-root {
      > * {
        color: ${colors.$secondary};
      }
    }
  }
`

const AddMemberBtn = styled(ButtonPrimaryOutlined)`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px !important;
  text-transform: none;
  min-height: 32px;
  width: 180px;
  padding: 8px 16px;
  height: 32px;
  margin: 21px 0px;
`

const Hr = styled.div`
  background-color: ${colors.$borderSecondary};
  width: 100%;
  height: 1px;
  margin-bottom: 21px;
`

const AvailableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    color: ${colors.$primary2};
    font-weight: 400;
    font-size: 14px;
  }
  .values {
    color: ${colors.$blue};
    font-weight: 600;
    font-size: 14px;
  }
  span {
    color: ${colors.$primaryLight};
    font-weight: 400;
    font-size: 14px;
  }
`

const MemberRow = styled.div`
  display: flex;
  /* align-items: center; */
  gap: 16px;
  position: relative;
  > div:first-of-type {
    flex: 1;
  }
`
const MemberRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const DeleteRowBtn = styled(IconButton)`
  position: absolute;
  top: -18px;
  right: -18px;
`
const MemberInfo = styled.div`
  min-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  /* width: 100%; */
  background-color: white;
  height: 60px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  .ethAddress {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    margin-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const SearchInputContainer = styled.div`
  position: relative;
`

const SearchResults = styled.div`
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  position: absolute;
  z-index: 3;
  top: 64px;
  left: 0px;
  width: 100%;
  max-height: 182px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .row {
    height: 60px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    .ethAddress {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      margin-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .empty {
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .hr {
    width: 100%;
    min-height: 1px;
    background-color: ${colors.$borderSecondary};
  }
`

const NoNftOrVote = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #d63232;
  white-space: nowrap;
`

const AmountFiledContainer = styled.div`
  position: relative;
  .percentInUSDC {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 13px;
    line-height: 19.5px;
    color: #545e7880;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 32px);
  }
`
