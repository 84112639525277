import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { CircularProgress, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useStore } from 'effector-react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import { colors } from '../../../utils/colorVariables'
import { isMobile } from '../../../utils/isMobile'
import { TabStyled } from '../Tabs/styles'
import { ECountry } from '../../../state/effector/users/user.types'
import apiService from '../../../services/api'
import { SalesTable } from './sales-table'
import {
  $sellTransactions,
  SaleTransactions,
  saveSellTransactionsInStore
} from '../../../state/effector/token/token.store'

import { TablePagination } from '../Table/Pagination'
import EmptyStateBox from '../Members/EmptyStateBox'
import { Filters } from './filters'
import { HideUnhideSwitch } from '../Members/HideUnhideSwitch'
import useMetaMask from '../../../hooks/useMetaMask'
import { Paths } from '../../../constants/currency'
import { getNftName } from 'src/utils/nft.utils'
import LoadingIndicator from 'src/components/LoadingIndicator'

const OFFSET_VALUE = 25
let timer = null as any

export const Sales = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryTab: ECountry = searchParams.get('tab') as ECountry
  const { chainId } = useMetaMask()
  const paths = Paths[chainId]

  const [tab, setTab] = useState(queryTab || ECountry.NonUS)
  const [page, setPage] = useState(1)
  const [showHideTx, setShowHideTx] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filters, handleFilters] = useState({
    walletAddress: '',
    introducerWalletAddress: '',
    nftAddress: 'all',
    transactionType: 'all',
    type: 'all',
    fromDate: '',
    toDate: ''
  })

  const mobile = isMobile()
  const sellTransactions = useStore($sellTransactions)

  const handleGetSellTransactions = async () => {
    try {
      setIsLoading(true)
      const validFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(value && value !== 'all' && { [key]: value }),
          mode: showHideTx ? 'TEST' : 'PROD'
        }),
        {}
      )

      const { data } = await apiService.getSellTransactions({
        page,
        offset: OFFSET_VALUE,
        country: tab,
        ...validFilters
      })

      saveSellTransactionsInStore(data)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      handleGetSellTransactions()
    }, 250)
  }, [tab, page, filters, showHideTx])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: ECountry) => {
    setTab(newValue)
    setPage(1)
    setSearchParams({ tab: newValue })
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const onFiltersChange = (callback: any) => {
    setPage(1)
    handleFilters(callback)
  }

  const onSetShowHideTx = (value: boolean) => {
    setPage(1)
    setShowHideTx(value)
  }

  const onExport = async () => {
    setIsLoading(true)
    const transactionTypes = {
      DApp: 'DApp',
      SendViaPanel: 'Panel',
      Manual: 'Manual'
    } as Record<string, string>

    const validFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value && value !== 'all' && { [key]: value }),
        mode: showHideTx ? 'TEST' : 'PROD'
      }),
      {}
    )
    const response = await apiService.getSellTransactions({
      page: 1,
      offset: sellTransactions?.totalItems || OFFSET_VALUE,
      country: tab,
      ...validFilters
    })

    const data: SaleTransactions = response?.data

    const rows = [
      [
        'Totals',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        `${Number(data?.totalPurchase || 0)}`,
        '',
        '',
        '',
        '',
        '',
        `${Number(data?.totalCommission || 0)}`,
        `${Number(data?.totalDeposit || 0)}`,
        `${Number(data?.totalProfit || 0)}`,
        '',
        ''
      ],
      [
        'Date of sale',
        'Hash for sale',
        `Member's name`,
        `Member's wallet`,
        `Member's country`,
        'Quantity',
        'NFT Type',
        'Currency',
        'Payment',
        'Date of commission',
        'Hash for commission',
        `Introducer's name`,
        `Introducer's wallet`,
        `Introducer's country`,
        'Commission',
        'Airdrop deposit',
        'Profit',
        'Execution',
        'Type'
      ]
    ]

    data.items?.forEach((item) => {
      const row = [
        moment(item.createdAt).utc().format('YYYY-MM-DD'),
        item.txHash || '-',
        item.user?.name || '-',
        item.user?.ethAddress || '-',
        item.user?.country ? (item.user?.country === 'US' ? 'US' : 'International') : '-',
        item.amount.toString(),
        getNftName(chainId, item.nftAddress),
        item.paymentCurrencyPath === 'Fiat' ? '$' : paths[item?.paymentCurrencyPath]?.symbol,
        Number(item.investment).toFixed(2),
        item.commissionDate ? moment(item.commissionDate).utc().format('YYYY-MM-DD') : 'No Date',
        item.commissionHash || '-',
        item.buddyModel?.name || '-',
        item.buddyModel?.ethAddress || '-',
        item.buddyModel?.country
          ? item.buddyModel?.country === 'US'
            ? 'US'
            : 'International'
          : '-',
        Number(item.commission).toFixed(2),
        Number(item.airdropDeposit || 0).toFixed(2),
        Number(item.treasuryPayments).toFixed(2),
        transactionTypes[item.transactionType],
        item.type || '-'
      ]

      rows.push(row)
    })

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `sales-${tab === 'US' ? 'us' : 'international'}-${moment().format('DD-MM-YYYY')}.csv`
    )
    document.body.appendChild(link)

    link.click()
    setIsLoading(false)
  }

  const renderTabContent = () => (
    <>
      <Filters filters={filters} handleFilters={onFiltersChange} />
      {sellTransactions?.items?.length ? (
        <>
          <Box>
            <SalesTable />
          </Box>
          <TablePagination
            response={sellTransactions}
            handleChange={handleChangePagination}
            page={page}
            onExport={onExport}
          />
        </>
      ) : (
        <EmptyStateBox message="No records found" />
      )}
      <HideUnhideSwitch
        checked={showHideTx}
        onChange={onSetShowHideTx}
        text="Show test txns"
        withIcon={false}
      />
    </>
  )

  return (
    <Box sx={{ minHeight: '100%' }}>
      {isLoading && <LoadingIndicator />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '25px',
          marginTop: mobile ? '25px' : 0
        }}
      >
        <Typography
          color={colors.$secondary}
          sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300, marginRight: '20px' }}
          variant="subtitle1"
        >
          Sales
        </Typography>
      </Box>
      {sellTransactions ? (
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'LightGray' }}>
            <TabList
              aria-label="lab API tabs"
              onChange={handleChangeTab}
              sx={{
                height: '25px',
                '.MuiTab-textColorPrimary.Mui-selected': {
                  color: '#0B1730',
                  '&: hover': {
                    color: '#0B1730'
                  }
                },
                '.MuiTab-textColorPrimary': {
                  color: '#8B8E95',
                  letterSpacing: 'normal',
                  transition: '.3s',
                  '&: hover': {
                    color: colors.$primary2
                  }
                }
              }}
              TabIndicatorProps={{
                sx: {
                  height: 4,
                  borderRadius: 2
                }
              }}
            >
              <TabStyled
                label="International Sales"
                value={ECountry.NonUS}
                sx={{
                  marginRight: mobile ? '10px' : '20px',
                  fontWeight: tab === ECountry.NonUS ? 700 : 400
                }}
              />
              <TabStyled
                label={'US Sales'}
                value={ECountry.US}
                sx={{
                  marginLeft: mobile ? '10px' : '20px',
                  fontWeight: tab === ECountry.US ? 700 : 400
                }}
              />
            </TabList>
          </Box>
          <TabPanel value={ECountry.NonUS} sx={{ padding: '0' }}>
            {renderTabContent()}
          </TabPanel>
          <TabPanel value={ECountry.US} sx={{ padding: '0' }}>
            {renderTabContent()}
          </TabPanel>
        </TabContext>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
