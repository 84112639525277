import React from 'react'
import { Box, Grid } from '@mui/material'
import styled from '@emotion/styled'

import { ButtonBasePrimary, ButtonBasePrimaryOutlined } from '../../Button'
import CustomModal from '../../Modal/customModal'
import { truncateAddress } from 'src/utils/truncateAddress'
import { colors } from '../../../utils/colorVariables'
import { getNftName } from 'src/utils/nft.utils'
import useMetaMask from 'src/hooks/useMetaMask'

interface IProps {
  isOpen: boolean
  handleClose: () => void
  isLoading?: boolean
  onSubmit: () => void
  ethAddress: string
  amount: number
  upgrade?: boolean
  nftAddress?: string
}
export const SentNFTConfirmModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  isLoading,
  onSubmit,
  ethAddress,
  amount,
  upgrade = false,
  nftAddress
}) => {
  const { chainId } = useMetaMask()

  return (
    <CustomModal
      isOpen={isOpen}
      title={upgrade ? 'Confirm NFT Upgrade' : 'Confirm Send NFT'}
      handleClose={handleClose}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledContentWrapper>
            {upgrade ? (
              <>
                Are you sure you want to upgrade 1 {getNftName(chainId, nftAddress)} for&nbsp;
                <code>{truncateAddress(ethAddress)}</code>?
              </>
            ) : (
              <>
                Are you sure you want to send {amount} {getNftName(chainId, nftAddress)} NFT
                to&nbsp;
                <code>{truncateAddress(ethAddress)}</code>?
              </>
            )}
          </StyledContentWrapper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <StyledButtonWrapper>
                <ButtonBasePrimaryOutlined onClick={handleClose} disabled={isLoading}>
                  Cancel
                </ButtonBasePrimaryOutlined>
              </StyledButtonWrapper>
            </Grid>
            <Grid item xs={6} md={6}>
              <StyledButtonWrapper>
                <ButtonBasePrimary disabled={isLoading} onClick={onSubmit}>
                  {upgrade ? 'Upgrade' : 'Send'}
                </ButtonBasePrimary>
              </StyledButtonWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  )
}

const StyledButtonWrapper = styled(Box)`
  margin-top: 20px;

  button {
    width: 100%;
    text-transform: none;
    font-size: 16px;
    padding: 10px;
    min-height: 0;
    border-radius: 8px;
  }
`
const StyledContentWrapper = styled(Box)`
  color: ${colors.$primary};
  /* padding: 0 10%; */
  margin-top: 20px;
  font-weight: 300;
  text-align: center;
`
