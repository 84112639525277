import { object, string, array } from 'yup'

const validationForStep = {
  budget: string()
    .test('required', 'This field is required', (value, { parent }) => {
      if (parent.rewardType !== 'IsFixedAmount') {
        return true
      }

      return Boolean(value)
    })
    .test('min', 'Value must be positive', (value, { parent }) => {
      if (parent.rewardType !== 'IsFixedAmount') {
        return true
      }
      return +(value || 0) > 0
    }),
  specificMembers: (calulatedBudget = 0) =>
    array()
      .of(
        object().shape({
          name: string().required('This field is required'),
          ethAddress: string().required('Invalid wallet format, should be an Ethereum address'),
          amount: string().required('This field is required'),
          percent: string().required('This field is required')
        })
      )
      .min(1, 'Must be at least 1 member')
      .test('insufficientBudget', 'Insufficient budget', (value: any, { parent }) => {
        const budget = parent.budget || calulatedBudget
        const addedAmount = value.reduce(
          (acc: number, next: { amount: string }) => acc + (+next.amount || 0),
          0
        )
        return +budget - +addedAmount.toFixed(6) >= 0
      })
      .test('remainedBudget', 'Use all budget', (value: any, { parent }) => {
        const budget = parent.budget || calulatedBudget

        const addedAmount = value.reduce(
          (acc: number, next: { amount: string }) => acc + (+next.amount || 0),
          0
        )
        return +budget - +addedAmount.toFixed(6) === 0
      })
}

export const validationSchema = (steps: string[], calulatedBudget = 0) =>
  object().shape({
    name: string()
      .required('This field is required')
      .min(3, 'Airdrop name is too short. Min 3 characters')
      .max(50, 'Airdrop name is too long. Max 50 characters'),
    ...(steps.includes('budget') && { budget: validationForStep.budget }),
    ...(steps.includes('specificMembers') && {
      specificMembers: validationForStep.specificMembers(calulatedBudget)
    })
  })
