import React from 'react'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import RadioButton from '../../../RadioButton'
import { useAccessController } from 'src/hooks/useAccessController'
import { colors } from 'src/utils/colorVariables'
import { ECountry, UserRoles } from 'src/state/effector/users/user.types'

interface Props {
  isUsCustomer: ECountry | null
  isAdd: boolean
  onChange: (value: ECountry) => void
  disabled?: boolean
  invalid?: boolean
}

const UsCustomer: React.FC<Props> = ({ isAdd, isUsCustomer, onChange, invalid }) => {
  const { isAllowed } = useAccessController(UserRoles.Admin)
  const isDisabled = isAdd ? false : !isAllowed

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <UsCustomerItem
          onClick={() => !isDisabled && onChange(ECountry.US)}
          disabled={isDisabled}
          invalid={invalid}
        >
          <RadioButton checked={isUsCustomer === ECountry.US} />
          {'US Customer'}
        </UsCustomerItem>
      </Grid>

      <Grid item xs={6}>
        <UsCustomerItem
          onClick={() => !isDisabled && onChange(ECountry.NonUS)}
          disabled={isDisabled}
          invalid={invalid}
        >
          <RadioButton checked={isUsCustomer === ECountry.NonUS} />
          International Customer
        </UsCustomerItem>
      </Grid>
    </Grid>
  )
}

export default UsCustomer

interface UsCustomerItemProps {
  disabled?: boolean
  invalid?: boolean
}

const getBorderColor = (props: UsCustomerItemProps) => {
  if (props.invalid) {
    return colors.$error
  }
  if (props.disabled) {
    return 'rgba(118, 118, 118, 0.3)'
  }

  return '#D4DBE7'
}

const UsCustomerItem = styled('div')<UsCustomerItemProps>`
  background-color: ${({ disabled, invalid }) =>
    (disabled && colors.$disabledBg3) || invalid || '#F8FAFC'};
  border: 1px solid #d4dbe7;
  border-color: ${(props) => `${getBorderColor(props)}`};
  cursor: pointer;

  border-radius: 8px;
  padding: 12px;
  width: 100%;
  height: 53px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  img {
    margin-right: 10px;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  }
`
