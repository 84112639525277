import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import styled from '@emotion/styled'
import { useStore } from 'effector-react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import SearchBar from 'src/components/SearchBar'
import { EUseGetUsers, getUsers } from 'src/hooks/useGetUsers'
import {
  $isLoadingUsers,
  $pendingUsers,
  $pendingUsersTrigger,
  $showHiddenUsers,
  changeShowHiddenUsersCount
} from 'src/state/effector/users/users.store'
import { EUsersHiddenTypes } from 'src/state/effector/users/user.types'
import { colors } from 'src/utils/colorVariables'
import { isAddressValid } from 'src/utils/truncateAddress'
import Switch from 'src/components/Switch'

import EmptyStateBox from '../EmptyStateBox'
import PendingWhitelistTable from './PendingWhitelistTable'

const OFFSET_VALUE = 25

const PendingWhitelist = () => {
  const [searchByWalletValue, setSearchByWalletValue] = useState<string>('')
  const [searchByNameValue, setSearchByNameValue] = useState<string>('')
  const [offset, setOffset] = useState<number>(OFFSET_VALUE)

  const pendingUsers = useStore($pendingUsers)
  const isLoading = useStore($isLoadingUsers)
  const trigger = useStore($pendingUsersTrigger)
  const { pendingWhitelist } = useStore($showHiddenUsers)

  useEffect(() => {
    const getUsersFn = getUsers(
      {
        offset,
        page: 1,
        wallet: searchByWalletValue,
        name: searchByNameValue,
        whitelist: 'PENDING',
        all: true,
        showHiddenUsers: pendingWhitelist
      },
      EUseGetUsers.pendingUsers
    )

    getUsersFn()
  }, [offset, searchByWalletValue, searchByNameValue, pendingWhitelist, trigger])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.currentTarget.value
    const searchWorldWithTrim = searchWord.trim()

    if (isAddressValid(searchWorldWithTrim)) {
      setSearchByWalletValue(searchWorldWithTrim)
      setSearchByNameValue('')
    } else {
      setSearchByNameValue(searchWord)
      setSearchByWalletValue('')
    }
  }

  const handleShowMore = () => {
    if (!pendingUsers?.nextPage) return
    setOffset((prev) => prev + OFFSET_VALUE)
  }

  const handleChangeSwitch = () => {
    changeShowHiddenUsersCount(EUsersHiddenTypes.pendingWhitelist)
  }

  return (
    <>
      <Box sx={{ padding: '25px 0' }}>
        <SearchBar
          name="searchByWalletOrName"
          value={searchByWalletValue || searchByNameValue}
          onChange={handleSearchChange}
          placeholder="Search by Wallet or Name"
        />
      </Box>
      <Box marginBottom="24px">
        <Switch
          checked={pendingWhitelist}
          onChange={handleChangeSwitch}
          label={
            <Box display="flex" alignItems="center" justifyContent="start">
              <Box mr={1}>Show hidden users</Box>
              <Box display="flex" alignItems="center" justifyContent="start">
                {pendingWhitelist ? (
                  <VisibilityOffOutlinedIcon fontSize="small" />
                ) : (
                  <VisibilityOutlinedIcon fontSize="small" />
                )}
              </Box>
            </Box>
          }
          type="primary"
        />
      </Box>
      {pendingUsers?.items?.length ? (
        <Box>
          <PendingWhitelistTable />
        </Box>
      ) : (
        <EmptyStateBox
          message={
            (searchByWalletValue || searchByNameValue).length
              ? 'No records found'
              : 'All done! Great job 👍'
          }
          isLoading={!pendingUsers}
        />
      )}
      {pendingUsers?.items?.length && pendingUsers?.items?.length >= OFFSET_VALUE ? (
        <Box
          sx={{
            padding: '20px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingButtonStyled
            color="info"
            variant="outlined"
            disabled={!pendingUsers?.nextPage}
            onClick={handleShowMore}
            loading={isLoading}
          >
            Show more
          </LoadingButtonStyled>
        </Box>
      ) : null}
    </>
  )
}

export default PendingWhitelist

const LoadingButtonStyled = styled(LoadingButton)`
  text-transform: none;
  border-radius: 6px;
  font-weight: 600;
  color: ${colors.$blue};
  border-color: ${colors.$blue};
`
