import React, { FC } from 'react'
import { IResponseWrapper, ResponseType } from './helpers/types'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { colors } from '../../../utils/colorVariables'

export const ResponseWrapper: FC<IResponseWrapper> = ({ children, type }) => {
  return <StyledResponse type={type}>{children}</StyledResponse>
}

const StyledResponse = styled(Box)<{ type: ResponseType }>`
  margin: 12px 0;
  border-radius: 8px;
  padding: 16px;
  background-color: ${({ type }) => (type === 'error' ? 'rgba(246, 185, 28, 0.12)' : '#EBF6F7')};
  color: ${colors.$primary2};
`
