import React, { FC } from 'react'
import { styled, Chip as ChipMaterial } from '@mui/material'
import { colors } from '../../../utils/colorVariables'
import { IChip } from './Chip.types'

const Chip: FC<IChip> = ({ allowToMyCollection, handleClick, myCollection }) => {
  return myCollection ? (
    <ChipWrapper disabled={!allowToMyCollection} onClick={handleClick} label={myCollection} />
  ) : (
    <></>
  )
}

const ChipWrapper = styled(ChipMaterial)`
  height: 16px;
  background: ${colors.$successOrange};
  font-size: 14px;
  font-weight: 500;
  color: #0b1730;
  padding: 6px 8px;
  margin-left: 12px;

  ${(props) => props.theme.breakpoints.down('md')} {
    &:hover {
      background: ${colors.$successOrange};
    }
  }

  .MuiChip-label {
    padding: 0;
  }

  @media (max-width: 599px) {
    font-size: 12px;
    height: 20px;
    margin: 0 14px;
  }
`

export default Chip
