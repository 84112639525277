import isPropValid from '@emotion/is-prop-valid'
import { styled, TextField } from '@mui/material'
import { colors } from '../../utils/colorVariables'
import { isMobile } from '../../utils/isMobile'

interface Props {
  invalid?: boolean
}

export const TextFieldPrimary = styled(TextField)<Props>(() => ({
  ['& input']: {
    fontSize: isMobile() ? '12px' : '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    paddingRight: 30,
    borderRadius: '4px'
  },
  ['& input.Mui-disabled']: {
    backgroundColor: colors.$disabledBg3,
    color: colors.$primaryLight,
    paddingRight: 45
  },
  ['& input.Mui-disabled ~ fieldset']: {
    color: colors.$primaryLight
  },
  ['& .Mui-disabled']: {
    paddingRight: 0
  }
}))
