import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/system'

import useMetaMask from 'src/hooks/useMetaMask'
import { colors } from 'src/utils/colorVariables'
import { isMobile } from 'src/utils/isMobile'
import apiService from 'src/services/api'
import intFlag from 'src/assets/int-flag.png'
import usFlag from 'src/assets/us-flag.png'

import { OverviewItem, OverviewItemRow, RowItem } from './OverviewItem'
import { IGetOverview } from './overview.types'

export const Overview: React.FC = () => {
  const mobile = isMobile()
  const { chainId } = useMetaMask()

  const [overview, setOverview] = useState<IGetOverview | null>(null)
  const [budget, setBudget] = useState({
    budgetInt: 0,
    budgetUS: 0
  })

  const [lastBudget, setLastBudget] = useState({
    budgetInt: 0,
    budgetUS: 0
  })

  useEffect(() => {
    const fetch = async () => {
      try {
        const [overview, budget, lastBudget] = await Promise.all([
          apiService.getOverview(chainId),
          apiService.getBudgetPayment({ chainId, percentage: 5 }),
          apiService.getLastBudgetPayment({ chainId, percentage: 5 })
        ])
        setOverview(overview.message)
        setBudget(budget.data)
        setLastBudget(lastBudget.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetch()
  }, [chainId])

  return (
    <Box>
      <Typography
        color={colors.$secondary}
        sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300 }}
        variant="subtitle1"
      >
        Overview
      </Typography>
      <Box mt={2}>
        {overview ? (
          <Grid container spacing={2}>
            <Grid container xs={12} spacing={2} p={0} m={0}>
              <Grid item xs={12} md={6} lg={4}>
                {/* computedTotalTribers */}
                <OverviewItem>
                  <OverviewItemRow>
                    <RowItem
                      title="Total Tribers"
                      body={overview.computedTotalTribers.allTribers}
                    />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem>
                    <RowItem
                      title="Introducers"
                      body={overview.computedTotalTribers.activeTribers}
                    />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {/* computedSoldNFTs */}
                <OverviewItem>
                  <OverviewItemRow>
                    <RowItem title="Total NFT sold" body={overview.computedSoldNFTs.total} />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem>
                    <RowItem
                      title="Founding NFT"
                      body={overview.computedSoldNFTs.soldFoundingNFTs}
                      xs={6}
                    />
                    <RowItem
                      title="Annual NFT"
                      body={overview.computedSoldNFTs.soldSecondNFTs}
                      xs={6}
                    />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OverviewItem>
                  <OverviewItemRow>
                    <RowItem
                      title="Commission Paid"
                      currencySymbol="$"
                      body={overview.computedTotalCommission.toFixed(0)}
                    />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem>
                    <RowItem
                      title="This Month"
                      currencySymbol="$"
                      body={overview.computedThisMonthCommission.toFixed(0)}
                      xs={12}
                      md={6}
                    />
                    <RowItem
                      title="Last Month"
                      currencySymbol="$"
                      body={overview.computedLastMonthCommission.toFixed(0)}
                      xs={12}
                      md={6}
                    />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} p={0} m={0}>
              <Grid item xs={12} md={6}>
                {/* currentMonthStats */}
                <OverviewItem title="This Month">
                  <OverviewItemRow>
                    <RowItem
                      title="New Tribers"
                      body={overview.currentMonthStats.newTribers}
                      xs={6}
                    />
                    <RowItem title="NFT Sold" body={overview.currentMonthStats.nftSold} xs={6} />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem withHrLine>
                    <RowItem
                      title="Founding NFT"
                      body={overview.currentMonthStats.nftFounding}
                      xs={6}
                    />
                    <RowItem
                      title="Annual NFT"
                      body={overview.currentMonthStats.nftSecond}
                      xs={6}
                    />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* lastMonthStats */}
                <OverviewItem title="Last Month">
                  <OverviewItemRow>
                    <RowItem title="New Tribers" body={overview.lastMonthStats.newTribers} xs={6} />
                    <RowItem title="NFT Sold" body={overview.lastMonthStats.nftSold} xs={6} />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem withHrLine>
                    <RowItem
                      title="Founding NFT"
                      body={overview.lastMonthStats.nftFounding}
                      xs={6}
                    />
                    <RowItem title="Annual NFT" body={overview.lastMonthStats.nftSecond} xs={6} />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>

              <Grid item xs={12} md={6}>
                <OverviewItem title="Airdrop Forecast">
                  <OverviewItemRow>
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={intFlag} height="16px" />
                          &nbsp;
                          <b>World </b>- Reward (5%)
                        </span>
                      }
                      currencySymbol="$"
                      body={budget.budgetInt}
                      xs={6}
                    />
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={intFlag} height="16px" />
                          &nbsp;
                          <b>World </b>- Excellence (5%)
                        </span>
                      }
                      currencySymbol="$"
                      body={budget.budgetInt}
                      xs={6}
                    />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem withHrLine>
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={usFlag} height="16px" />
                          &nbsp;
                          <b>USA </b>- Reward (5%)
                        </span>
                      }
                      body={budget.budgetUS}
                      currencySymbol="$"
                      xs={6}
                    />
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={usFlag} height="16px" />
                          &nbsp;
                          <b>USA </b>- Excellence (5%)
                        </span>
                      }
                      body={budget.budgetUS}
                      currencySymbol="$"
                      xs={6}
                    />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <OverviewItem title="Last Airdrop">
                  <OverviewItemRow>
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={intFlag} height="16px" />
                          &nbsp;
                          <b>World </b>- Reward (5%)
                        </span>
                      }
                      currencySymbol="$"
                      body={lastBudget.budgetInt}
                      xs={6}
                    />
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={intFlag} height="16px" />
                          &nbsp;
                          <b>World </b>- Excellence (5%)
                        </span>
                      }
                      currencySymbol="$"
                      body={lastBudget.budgetInt}
                      xs={6}
                    />
                  </OverviewItemRow>
                  <OverviewItemRow nextItem withHrLine>
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={usFlag} height="16px" />
                          &nbsp;
                          <b>USA </b>- Reward (5%)
                        </span>
                      }
                      body={lastBudget.budgetUS}
                      currencySymbol="$"
                      xs={6}
                    />
                    <RowItem
                      title={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={usFlag} height="16px" />
                          &nbsp;
                          <b>USA </b>- Excellence (5%)
                        </span>
                      }
                      body={lastBudget.budgetUS}
                      currencySymbol="$"
                      xs={6}
                    />
                  </OverviewItemRow>
                </OverviewItem>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  )
}
