import styled from '@emotion/styled'

import { colors } from 'src/utils/colorVariables'

import { BodyRow, CellPrimary } from '../Table'
import { useUnit } from 'effector-react'
import { $sellTransactions } from 'src/state/effector/token/token.store'
import { Tooltip } from '@mui/material'

export const Totals = () => {
  const sales = useUnit($sellTransactions)
  return (
    <Container>
      <CellPrimary />
      <CellPrimary>Totals</CellPrimary>
      <CellPrimary />
      <CellPrimary>
        <Tooltip title={`$${Number(sales?.totalPurchase || 0).toLocaleString('en-US')}`} arrow>
          <div className="text-ellipsis">
            ${Number(sales?.totalPurchase || 0).toLocaleString('en-US')}
          </div>
        </Tooltip>
      </CellPrimary>
      <CellPrimary />
      <CellPrimary>
        <Tooltip title={`$${Number(sales?.totalCommission || 0).toLocaleString('en-US')}`} arrow>
          <div className="text-ellipsis">
            ${Number(sales?.totalCommission || 0).toLocaleString('en-US')}
          </div>
        </Tooltip>
      </CellPrimary>
      <CellPrimary>
        <Tooltip title={`$${Number(sales?.totalDeposit || 0).toLocaleString('en-US')}`} arrow>
          <div className="text-ellipsis">
            ${Number(sales?.totalDeposit || 0).toLocaleString('en-US')}
          </div>
        </Tooltip>
      </CellPrimary>
      <CellPrimary>
        <Tooltip title={`$${Number(sales?.totalProfit || 0).toLocaleString('en-US')}`} arrow>
          <div className="text-ellipsis">
            ${Number(sales?.totalProfit || 0).toLocaleString('en-US')}
          </div>
        </Tooltip>
      </CellPrimary>
      <CellPrimary />
    </Container>
  )
}

const Container = styled(BodyRow)`
  min-width: 1270px;
  margin: 16px 0px;
  grid-template-columns:
    72px minmax(120px, 1fr) minmax(170px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr)
    repeat(3, minmax(120px, 150px)) 110px 60px;
  color: ${colors.$secondary};
  background: rgba(212, 219, 231, 0.16);
  font-weight: 600;
  line-height: 100%;
  > div:not(:nth-child(2)) {
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
  }
`
