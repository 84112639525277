import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, styled } from '@mui/material'
import { useStore } from 'effector-react'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import {
  $users,
  $usersTrigger,
  changeShowHiddenUsersCount,
  $showHiddenUsers
} from 'src/state/effector/users/users.store'
import { EUsersHiddenTypes } from 'src/state/effector/users/user.types'
import { EUseGetUsers, getUsers } from 'src/hooks/useGetUsers'
import { WhitelistTypes } from 'src/state/response/response.types'
import { colors } from 'src/utils/colorVariables'
import { isAddressValid } from 'src/utils/truncateAddress'

import SearchBar from '../../../SearchBar'
import Switch from '../../../Switch'
import AllTribersTable from './AllTribersTable'
import TribersSelect from './TribersSelect'
import EmptyStateBox from '../EmptyStateBox'
import { TablePagination } from '../../Table/Pagination'

interface IProps {
  type?: 'all' | 'pending'
}

const OFFSET_VALUE = 25

const ALlTribers: React.FC<IProps> = ({ type = 'all' }) => {
  const [searchByWalletValue, setSearchByWalletValue] = useState<string>('')
  const [searchByIntroducerValue, setSearchByIntroducerValue] = useState<string>('')
  const [searchByIntroducerName, setSearchByIntroducerName] = useState<string>('')
  const [searchByNameValue, setSearchByNameValue] = useState<string>('')
  const [select, setSelect] = useState<WhitelistTypes | 'ALL' | undefined>(undefined)
  const showHiddenUsers = useStore($showHiddenUsers)

  const [showNotATriber, setShowNotATriber] = useState(false)

  const [page, setPage] = useState<number>(1)

  const users = useStore($users)
  const trigger = useStore($usersTrigger)

  useEffect(() => {
    const getUsersFn = getUsers(
      {
        page,
        wallet: searchByWalletValue,
        introducer: searchByIntroducerValue,
        introducerName: searchByIntroducerName,
        name: searchByNameValue,
        showPastTeamMembers: true,
        offset: OFFSET_VALUE,
        showOnlyMembers: !showNotATriber,
        showHiddenUsers:
          type === 'all' ? showHiddenUsers.allTribers : showHiddenUsers.pendingTribers,
        all: type === 'pending' || !!searchByNameValue.length || !!searchByWalletValue.length,
        showPendingTribers: type === 'pending',
        whitelist:
          select === 'ALL' || (!!searchByWalletValue.length && select === undefined)
            ? null
            : select || 'ACCEPTED'
      },
      EUseGetUsers.all
    )

    getUsersFn()
  }, [
    page,
    select,
    searchByWalletValue,
    searchByIntroducerValue,
    searchByIntroducerName,
    searchByNameValue,
    trigger,
    showHiddenUsers,
    showNotATriber
  ])

  const isShowHiddenUsers = useMemo(
    () => (type === 'all' ? showHiddenUsers.allTribers : showHiddenUsers.pendingTribers),
    [showHiddenUsers.allTribers, showHiddenUsers.pendingTribers, type]
  )

  const handleResetPage = () => (page !== 1 ? setPage(1) : null)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.currentTarget.value
    const searchWorldWithTrim = searchWord.trim()
    const inputName = event.target.name

    if (inputName === 'searchByWalletOrName') {
      if (isAddressValid(searchWorldWithTrim)) {
        setSearchByWalletValue(searchWorldWithTrim)
        setSearchByNameValue('')
      } else {
        setSearchByNameValue(searchWord)
        setSearchByWalletValue('')
      }
    }
    if (inputName === 'searchByIntroducer') {
      if (isAddressValid(searchWorldWithTrim)) {
        setSearchByIntroducerValue(searchWorldWithTrim)
        setSearchByIntroducerName('')
      } else {
        setSearchByIntroducerName(searchWord)
        setSearchByIntroducerValue('')
      }
    }

    handleResetPage()
  }

  const handleChangeSwitch = () => {
    changeShowHiddenUsersCount(
      type === 'all' ? EUsersHiddenTypes.allTribers : EUsersHiddenTypes.pendingTribers
    )
    handleResetPage()
  }

  const chnageShowNotATriber = (val: boolean) => {
    setShowNotATriber(val)
    handleResetPage()
  }

  const handleSelect = (event: SelectChangeEvent) => {
    const selectedValue: any = event.target.value
    setSelect(selectedValue)
    handleResetPage()
  }

  const handleClearFilters = () => {
    setSearchByWalletValue('')
    setSearchByIntroducerValue('')
    setSearchByNameValue('')
    setSearchByIntroducerName('')
    setSelect(undefined)
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ padding: '25px 0' }}>
        <Grid item lg={type !== 'pending' ? 3 : 5} md={type !== 'pending' ? 6 : 4} xs={12}>
          <SearchBar
            name="searchByWalletOrName"
            value={searchByWalletValue || searchByNameValue}
            onChange={handleSearchChange}
            placeholder="Search by Wallet or Name"
          />
        </Grid>
        <Grid item lg={type !== 'pending' ? 3 : 5} md={type !== 'pending' ? 6 : 4} xs={12}>
          <SearchBar
            name="searchByIntroducer"
            value={searchByIntroducerValue || searchByIntroducerName}
            onChange={handleSearchChange}
            placeholder="Search by Introducer"
          />
        </Grid>
        {type !== 'pending' && (
          <Grid item lg={4} md={8} xs={12}>
            <TribersSelect value={select} onChange={handleSelect} />
          </Grid>
        )}
        <Grid item lg={2} md={type !== 'pending' ? 3 : 4} xs={12}>
          <ClearFilterButton variant="contained" onClick={handleClearFilters}>
            Clear Filters
          </ClearFilterButton>
        </Grid>
      </Grid>
      <Box marginBottom="24px" display="flex" alignItems="center" gap="48px">
        {type !== 'pending' && (
          <Switch
            checked={showNotATriber}
            onChange={chnageShowNotATriber}
            label="Show “Pending tribers”"
            type="primary"
          />
        )}
        <Switch
          checked={isShowHiddenUsers}
          onChange={handleChangeSwitch}
          label={
            <Box display="flex" alignItems="center" justifyContent="start">
              <Box mr={1}>Show hidden users</Box>
              <Box display="flex" alignItems="center" justifyContent="start">
                {isShowHiddenUsers ? (
                  <VisibilityOffOutlinedIcon fontSize="small" />
                ) : (
                  <VisibilityOutlinedIcon fontSize="small" />
                )}
              </Box>
            </Box>
          }
          type="primary"
        />
      </Box>

      {users?.items?.length ? (
        <>
          <Box>
            <AllTribersTable type={type} />
          </Box>
        </>
      ) : (
        <EmptyStateBox message="No records found" isLoading={!users} />
      )}
      {!!users?.items?.length && (
        <TablePagination response={users} page={page} handleChange={handleChangePagination} />
      )}
    </>
  )
}

export default ALlTribers

const ClearFilterButton = styled(Button)`
  background-color: black;
  color: white;
  white-space: nowrap;
  height: 100%;
  line-height: 2;
  text-transform: none;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: ${colors.$gray2};
  }
`
