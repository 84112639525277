import React from 'react'
import logoDark from '../../assets/logo_dark.svg'
import logoLight from '../../assets/logo_light.svg'
import smallDark from '../../assets/small-dark-logo.svg'

interface LogoProp {
  logoView?: string | unknown
  padding?: string
  width?: number | string
  size?: string
}

export const AppLogo = ({ logoView, size, ...props }: LogoProp) => {
  return logoView === 'dark' ? (
    <img src={logoDark} style={props} alt="" />
  ) : size === 'small' ? (
    <img src={smallDark} style={props} alt="" />
  ) : (
    <img src={logoLight} style={props} alt="" />
  )
}
