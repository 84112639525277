import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .max(90, 'Name must be at most 90 characters')
    .required('Name is required field'),
  email: Yup.string()
    .email('Invalid email format')
    .max(90, 'Email Address must be at most 90 characters'),
  country: Yup.string().required(),
  ethAddress: Yup.string().required('Member’s wallet required field'),
  introducerAddress: Yup.string().required('Introducer wallet required field'),
  whitelist: Yup.string().required()
})
