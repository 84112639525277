import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, TableContainer, Tooltip, Typography } from '@mui/material'
import {
  Table,
  HeaderRow,
  BodyRow,
  CellPrimary,
  CellLightRow,
  renderCellWithTwoRows,
  renderNameWithTruncate
} from '../../Table'
import moment from 'moment'
import { AddressesCell } from '../../Table/AddressesCell'
import styled from '@emotion/styled'
import { colors } from '../../../../utils/colorVariables'
import { isMobile } from '../../../../utils/isMobile'
import apiService from '../../../../services/api'
import { ResponseWithPagination } from '../../../../state/response/response.types'
import { IBookNftRequestData } from '../../BookNFTModal/BookNFTModal.types'
import useMetaMask from '../../../../hooks/useMetaMask'
import { numberFormatter } from '../../../../utils/numberFormatter'
import LoadingButton from '@mui/lab/LoadingButton'
import { ReviewBookNFTModal } from '../../BookNFTModal/ReviewBookNFTModal'
import { $isNftTransferBooked, saveNftTransferBooked } from '../../../../state/effector/store'
import { useStore } from 'effector-react'
import { css } from '@emotion/react'
import { getNftName } from 'src/utils/nft.utils'

const headerData: string[] = [
  'Request date',
  'Requester',
  'Amount',
  'To member',
  'Commission',
  'To Introducer',
  ''
]

const OFFSET_VALUE = 10

export const PendingTransfers = () => {
  const [pendingTransactions, setPendingTransactions] = useState<ResponseWithPagination<
    IBookNftRequestData[]
  > | null>(null)

  const isNftTransferBooked = useStore($isNftTransferBooked)

  const [reviewBookNftOpen, setReviewBookNftOpen] = useState<IBookNftRequestData | null>(null)
  const [offset, setOffset] = useState(OFFSET_VALUE)
  const [isLoading, setIsLoading] = useState(false)
  const mobile = isMobile()
  const { chainId } = useMetaMask()

  const handleShowMore = () => {
    if (!pendingTransactions?.nextPage) return
    setOffset((prev) => prev + OFFSET_VALUE)
  }

  const loadTransactions = (offsetValue: number) => {
    setIsLoading(true)
    apiService
      .getBookedNFTList({
        offset: offsetValue,
        page: 1
      })
      .then(({ data }) => {
        setPendingTransactions(data)
        saveNftTransferBooked(false)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadTransactions(offset)
  }, [offset])

  useEffect(() => {
    if (isNftTransferBooked) {
      loadTransactions(offset)
    }
  }, [isNftTransferBooked, offset])

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" py={5}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            color={colors.$secondary}
            sx={{ fontSize: mobile ? 18 : 20, fontWeight: 500 }}
            variant="subtitle1"
            pb={3}
            mt={4}
          >
            Pending NFT Transfers ({pendingTransactions?.totalItems || 0})
          </Typography>
          <TableContainer>
            <Table aria-label="customized table" sx={{ minWidth: '1100px' }}>
              <HeaderRow rowTemplate={rowTemplate}>
                {headerData.map((header) => (
                  <CellPrimary key={header}>{header}</CellPrimary>
                ))}
              </HeaderRow>
              <>
                {pendingTransactions?.items
                  ? pendingTransactions?.items.map((row: IBookNftRequestData, idx) => (
                      <BodyRow key={row?.id} rowTemplate={rowTemplate}>
                        <CellPrimary>
                          <Tooltip
                            title={
                              <>
                                {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                                <br />
                                {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                              </>
                            }
                            placement="top"
                            arrow
                          >
                            <div>
                              {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)
                            </div>
                          </Tooltip>
                        </CellPrimary>
                        <CellPrimary>
                          {renderCellWithTwoRows(
                            <Typography>
                              {renderNameWithTruncate(row?.requester?.name as string) || ''}
                            </Typography>,
                            <CellLightRow>{row?.requester?.role}</CellLightRow>
                          )}
                        </CellPrimary>
                        <CellPrimary>
                          {row?.amount} {getNftName(chainId, row?.nftAddress)}
                        </CellPrimary>
                        <CellPrimary>
                          <AddressesCell
                            address={row?.member?.ethAddress || '-'}
                            name={row?.member?.name}
                            id={`${idx}_buyer`}
                            isShowUsIcon={row.member.country === 'US'}
                          />
                        </CellPrimary>
                        <CellPrimary>
                          {row?.commission < 1000000
                            ? row?.commission?.toLocaleString('en-US')
                            : numberFormatter(row?.commission, 2)}{' '}
                          USDC
                        </CellPrimary>
                        <CellPrimary>
                          <AddressesCell
                            address={row?.introducer?.ethAddress || '-'}
                            name={row?.introducer?.name}
                            id={`${idx}_introducer`}
                            isShowUsIcon={row.introducer.country === 'US'}
                          />
                        </CellPrimary>
                        <CellPrimary>
                          <StyledReviewButton onClick={() => setReviewBookNftOpen(row)}>
                            Review
                          </StyledReviewButton>
                        </CellPrimary>
                      </BodyRow>
                    ))
                  : ''}
              </>
            </Table>
          </TableContainer>
          {!pendingTransactions?.items?.length && (
            <Box my={4}>
              <Typography textAlign="center" fontWeight="bold" color={colors.$default}>
                No Pending Transfers
              </Typography>
            </Box>
          )}
          {Boolean(pendingTransactions?.nextPage) && (
            <Box mt={3} display="flex" justifyContent="center">
              <LoadingButtonStyled
                color="info"
                variant="outlined"
                disabled={!pendingTransactions?.nextPage}
                onClick={handleShowMore}
                loading={isLoading}
              >
                Show more
              </LoadingButtonStyled>
            </Box>
          )}
          {reviewBookNftOpen && (
            <ReviewBookNFTModal
              isOpen={Boolean(reviewBookNftOpen)}
              handleClose={() => {
                loadTransactions(offset)
                setReviewBookNftOpen(null)
              }}
              book={reviewBookNftOpen}
            />
          )}
        </>
      )}
    </>
  )
}

const rowTemplate = css`
  grid-template-columns:
    minmax(150px, 1fr) repeat(3, minmax(100px, 1fr)) minmax(100px, 150px) minmax(100px, 1fr)
    102px;
`

const StyledReviewButton = styled(Button)`
  padding: 10px;
  border: 1px solid ${colors.$blue};
  border-radius: 8px;
  color: ${colors.$blue};
  text-transform: none;
  transition: 0.3s;

  &:hover {
    background-color: transparent;
    opacity: 0.6;
  }
`

const LoadingButtonStyled = styled(LoadingButton)`
  text-transform: none;
  border-radius: 6px;
  font-weight: 600;
  color: ${colors.$blue};
  border-color: ${colors.$blue};
`
