import styled from '@emotion/styled'
import { Box, css } from '@mui/material'
import { colors } from 'src/utils/colorVariables'

export const ItemContainer = styled('div')<{ isNotAvailable?: boolean }>`
  padding: 28px;
  border: 1px solid #d4dbe7;
  background: white;
  ${({ isNotAvailable }) =>
    isNotAvailable &&
    css`
      border-color: ${colors.$lightYellow};
      background: rgba(246, 185, 28, 0.05);
    `};
  @media (max-width: 1200px) {
    padding: 12px;
  }
  .wallet {
    color: ${colors.$secondary3};
  }
`

export const DescriptionWrapper = styled(Box)`
  color: ${colors.$secondary};
  font-weight: 500;
  font-size: 14px;
  .walletBox {
    color: ${colors.$secondary3};
  }
`

export const TextWrapper = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
`

export const ValueWrapper = styled(Box)`
  color: ${colors.$secondary};
  font-weight: 500;
  display: flex;
  align-items: baseline;
  .value {
    font-size: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .valueString {
    font-size: 20px;
  }
`
