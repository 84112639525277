import styled from '@emotion/styled'
import { Box, Button as MaterialButton, Typography } from '@mui/material'
import { colors } from '../../../../utils/colorVariables'
import { ButtonPrimary } from '../../../Button'

export const TypographyWrapper = styled(Typography)`
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 14px;
  @media screen and (min-width: 600px) {
    font-size: 16px;
  }

  span {
    color: ${colors.$primary};
    font-weight: 400;
    font-size: 12px;
    @media screen and (min-width: 600px) {
      font-size: 14px;
    }
  }
`

export const LabelSelect = styled(Typography)`
  margin: 24px 0 -3px;
`

export const AmountFieldWrapper = styled(Box)`
  margin: 15px 0;
  display: flex;
  align-items: center;
`

export const IncrDecr = styled(MaterialButton)`
  height: 50px;
  cursor: pointer;
  min-width: 50px;
  border: 1px solid ${colors.$primaryDarkLight};
  border-radius: 3px;
  color: ${colors.$primaryDarkLight};
  padding: 10px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const NftKindSelectWrapper = styled.div`
  text-align: left;
`
export const StyledLabel = styled.label`
  text-align: left;
  & > div {
    width: 100%;
  }
`
export const StyledQuantityWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`
export const StyledButton = styled(ButtonPrimary)`
  text-transform: none;
  font-size: 16px;
`
