import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    address: null
  },
  reducers: {
    // connect2(state, action) {
    // },
    // switchAccount(state, action) {
    // },
  }
})

export default authSlice.reducer
