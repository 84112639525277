import React, { FC, useMemo } from 'react'
import { Box, Button, css } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from '@emotion/styled'
import { useStore } from 'effector-react'

import { IMobileNavigation } from './MobileNavigation.types'
import { AppLogo } from '../../Logo'
import { $isMaintenanceModeActive } from '../../../state/effector/store'
import { useAccessController } from '../../../hooks/useAccessController'
import { UserRoles } from '../../../state/effector/users/user.types'

const MobileNavigation: FC<IMobileNavigation> = ({ isOpen, setIsOpen, children }) => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear()
  }, [])
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const { isAllowed } = useAccessController(UserRoles.User, false, true)
  const showmaintenancestrip = isMaintenanceModeActive && isAllowed

  return (
    <StyledNavigation isopen={isOpen ? 1 : 0} showmaintenancestrip={showmaintenancestrip}>
      <Box display="flex" justifyContent="space-between" p={3} alignItems="center">
        <AppLogo width="180px" size="" logoView="dark" />
        <StyledCloseBtn
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <CloseIcon />
        </StyledCloseBtn>
      </Box>
      {children}
      <StyledPrivacy>
        © {currentYear} Sixth Society at Block Prime Ltd
        <br />
        <a href="/privacy-policy" target="_blank">
          Privacy & Cookies Policy
        </a>
      </StyledPrivacy>
    </StyledNavigation>
  )
}

const StyledCloseBtn = styled(Button)`
  min-width: auto;
`

const StyledPrivacy = styled.div`
  padding: 40px 24px;
  color: #0b1730;
  opacity: 0.5;
  font-size: 12px;
  margin-top: auto;
  a {
    color: inherit;
  }
`

const StyledNavigation = styled(Box)<{ showmaintenancestrip?: boolean; isopen: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 100%;
  transform: translateX(${({ isopen }) => (isopen ? '-100%' : '0')});
  transition: 0.7s;
  transition-timing-function: ease-in-out;
  background-color: #fff;
  z-index: 10;
  ${({ showmaintenancestrip }) =>
    showmaintenancestrip &&
    css`
      padding-top: 38px;
    `}
`

export default MobileNavigation
