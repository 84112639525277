import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useFormik } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton, useMediaQuery } from '@mui/material'
import moment from 'moment'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'

import { ButtonPrimary } from 'src/components/Button'
import { useIsOpen } from 'src/hooks/use-is-open'
import useMetaMask from 'src/hooks/useMetaMask'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { colors } from 'src/utils/colorVariables'
import { CHAIN_INFO } from 'src/constants'

import { Container, Form, Sidebar, StepsContainer, ActionContainer } from '../create-template'
import { stepFields } from '../constants'
import { TemplatePreset } from '../template-preset'
import { validationSchema } from './validation-schema'
import { DetailsPopup } from '../details-popup'
import { AirdropName, ManualDistribution, AirdropFixedBudget } from '../step-components'
import { TemplateType } from '../templates-list'
import { SpecificMember } from './specific-members'

let timer = null as any

export const ExecuteTemplate = () => {
  const { id = '' } = useParams<{
    id: string
  }>()
  const requesting = useRef(false)
  const { chainId } = useMetaMask()
  const [steps, handleSteps] = useState(['name'])
  const [data, handleData] = useState<TemplateType>({} as TemplateType)
  const [passedSteps, handlePassedSteps] = useState(['name'])
  const [calculations, handleCalculations] = useState<Record<string, any>>({})
  const [isLoadingData, handleIsDataLoading] = useState<Record<string, boolean>>({})
  const [draftId, handleDraftId] = useState(null)
  const [event, handleEvent] = useState<Record<string, any> | null>(null)

  const navigate = useNavigate()

  const isLaptop = useMediaQuery('@media(min-width: 800px)')

  const { isOpen: isOpenExecute, close: closeExecute, open: openExecute } = useIsOpen()

  const isLoading = useMemo(() => Object.values(isLoadingData).some((el) => el), [isLoadingData])

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        handleIsDataLoading((state) => ({ ...state, template: true }))
        const res = await apiService.getTemplate(+id)
        handleData({ ...res.data, isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no' })
      } catch (error) {
      } finally {
        handleIsDataLoading((state) => ({ ...state, template: false }))
      }
    }
    if (id) {
      fetchTemplate()
    }
  }, [id])

  useEffect(() => {
    const additionalSteps = [] as string[]
    if (data.rewardType === 'IsFixedAmount') {
      additionalSteps.push('budget')
    }

    if (data.category === 'SpecificMembers') {
      additionalSteps.push('specificMembers')
    }
    handleSteps((state) => [...state, ...additionalSteps])
  }, [data])

  const initialValues = useMemo(
    () => ({
      ...data,
      name: '',
      ...(steps.includes('budget') && {
        budget: '',
        budgetType: 'sum'
      }),

      ...(steps.includes('specificMembers') && {
        specificMembers: [{ name: '', ethAddress: '', country: '', amount: '', percent: '' }]
      })
    }),
    [data, steps]
  )

  const onSubmit = async (values: any) => {
    try {
      handleIsDataLoading((state) => ({ ...state, submit: true }))
      const draft = await apiService.createAirdropDraft({
        template: +id,
        name: values.name,
        network: CHAIN_INFO[chainId].networkType,
        ...(values.budget && {
          budget: values.budget
        }),
        ...(values.specificMembers && {
          members: values.specificMembers.map((member: SpecificMember) => ({
            address: member.ethAddress,
            amount: member.amount
          }))
        })
      })
      handleDraftId(draft.data.id)
      handleEvent({ ...draft.data, isVotingRequired: draft.data.isVotingRequired ? 'yes' : 'no' })
      openExecute()
    } catch (error) {
    } finally {
      handleIsDataLoading((state) => ({ ...state, submit: false }))
    }
  }

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    validateForm,
    touched,
    submitForm,
    setFieldError
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(passedSteps, calculations.budget),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: () => {}
  })

  useEffect(() => {
    const fetch = async () => {
      try {
        requesting.current = true
        handleIsDataLoading((state) => ({ ...state, summary: true }))

        const summary = await apiService.getAirdropSummary({
          templateId: +id,
          networkId: chainId,
          ...(values.budget && {
            budget: +values.budget
          })
        })
        handleCalculations({
          budget: values.rewardType !== 'IsFixedAmount' ? summary.data.budget : values.budget,
          calculationPeriod: summary.data.calculationPeriod,
          ...(data.distribution !== 'Manually' && {
            ...(data.distribution !== 'Equally' && { totalPoints: summary.data.totalPoints }),
            pointAmount:
              summary.data.pointAmount ||
              (+(values.budget || 0) / summary.data.totalPoints)
                .toString()
                .match(/[0-9]*[.]?[0-9]{0,6}/g)?.[0],
            membersCount: summary.data.membersCount
          })
        })
      } catch (error) {
      } finally {
        requesting.current = false
        handleIsDataLoading((state) => ({ ...state, summary: false }))
      }
    }
    if (
      data.id &&
      id &&
      chainId &&
      !requesting.current &&
      values.name &&
      (data.rewardType === 'IsFixedAmount' ? values.budget : true)
    ) {
      clearTimeout(timer)
      timer = setTimeout(fetch, 250)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [data.id, values.name, data.rewardType, values.budget, chainId, id])

  const actionBtnText = useMemo(() => {
    if (passedSteps.length !== steps.length) return 'Next Step'
    return 'Create Airdrop'
  }, [passedSteps, steps])

  const onNext = useCallback(async () => {
    try {
      await submitForm()

      const res = await validateForm(values)

      if (Object.keys(res).length !== 0) return

      const events = await apiService.getAirdropEvents({
        offset: 20,
        duplicate: values.name.replace(/^\s+|\s+$/g, ''),
        region: values.region
      })
      if (events.data.items.length && events.data.items.some((el: TemplateType) => el.id !== +id)) {
        setFieldError(
          'name',
          `Event with name '${values.name.replace(/^\s+|\s+$/g, '')}' already exists`
        )
        return
      }

      if (passedSteps.length === steps.length) {
        onSubmit(values)
      } else {
        handlePassedSteps((state) => [...state, steps[passedSteps.length]])
      }
      setTimeout(() => {
        document.getElementById('endOfForm')?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    } catch (e) {}
  }, [values, passedSteps, validateForm, steps, submitForm])

  const changeBudget = (e: any) => {
    if (isLoading) return
    setFieldValue(e.target.name, e.target.value.match(/[0-9]*[.]?[0-9]{0,6}/g)[0] || '', true)
  }

  const onGoBack = () => {
    navigate('/admin/airdrops')
  }

  const onCloseExecute = async (needDelete = true) => {
    try {
      handleIsDataLoading((state) => ({ ...state, deleteDraft: true }))
      if (draftId && needDelete) {
        await apiService.deleteAirdropDraft(draftId)
      }
      closeExecute()
    } catch (error) {
    } finally {
      handleIsDataLoading((state) => ({ ...state, deleteDraft: false }))
    }
  }

  const disabledNext = useMemo(() => {
    if (calculations.calculationPeriod) {
      return Object.values(calculations).some((value) => !value)
    }

    return false
  }, [calculations])

  if (!data.id) return <LoadingIndicator />

  if (!isLaptop) return <UseLaptop>Use a device with bigger screen width for this page</UseLaptop>

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {isOpenExecute && (
        <DetailsPopup
          handleClose={onCloseExecute}
          calculations={calculations}
          draftId={draftId}
          event={event}
        />
      )}
      <Container>
        <Form>
          <div className="header">
            <IconButton onClick={onGoBack}>
              <ArrowBackIcon sx={{ color: '#111213' }} />
            </IconButton>
          </div>
          <StepsContainer>
            {passedSteps.includes('name') && (
              <AirdropName
                value={values.name}
                onChange={handleChange}
                error={touched.name ? errors.name : ''}
              />
            )}
            {passedSteps.includes('budget') && (
              <AirdropFixedBudget
                value={values.budget || ''}
                onChange={changeBudget}
                error={touched.budget ? errors.budget : ''}
                step={steps.indexOf('budget') + 1}
              />
            )}
            {passedSteps.includes('specificMembers') && (
              <ManualDistribution
                region={values.region}
                value={values.budgetType || 'sum'}
                onChange={(value: string) => setFieldValue('budgetType', value, true)}
                step={steps.indexOf('specificMembers') + 1}
                errors={errors}
                touched={touched}
                specificMembers={values.specificMembers}
                setFieldValue={setFieldValue}
                budget={values.budget || calculations.budget}
                template={values}
              />
            )}
          </StepsContainer>
          <ActionContainer>
            <ButtonPrimary onClick={onNext} disabled={disabledNext}>
              {actionBtnText}
            </ButtonPrimary>
          </ActionContainer>
          <div id="endOfForm" />
        </Form>
        <Sidebar>
          <TemplatePreset
            keys={stepFields[6].split(',')}
            values={values}
            isExecute
            calculations={calculations}
          />
        </Sidebar>
      </Container>
    </>
  )
}

const UseLaptop = styled.div`
  padding: 32px;
  width: 100%;
  height: 200px;
  font-size: 24px;
  color: ${colors.$secondary};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
