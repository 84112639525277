import { createEvent, createStore } from 'effector'
import { ResponseWithPagination } from '../../response/response.types'
import { ISalesTransaction } from './token.types'

export interface SaleTransactions extends ResponseWithPagination<ISalesTransaction[]> {
  totalCommission: number
  totalProfit: number
  totalPurchase: number
  totalDeposit: number
}

export const saveSellTransactionsInStore = createEvent<SaleTransactions | null>()

export const $sellTransactions = createStore<SaleTransactions | null>(null).on(
  saveSellTransactionsInStore,
  (_, res) => {
    return res || null
  }
)
