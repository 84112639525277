import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { colors } from '../../../utils/colorVariables'
import { HrLine } from '../../HRLine'
import TeamModal, { EModalTypes } from './TeamModal/TeamModal'

import { isMobile } from '../../../utils/isMobile'
import { ButtonPrimary } from '../../Button'
import AddIcon from '@mui/icons-material/Add'
import { useAccessController } from 'src/hooks/useAccessController'
import { GetUsersParams, UserRoles } from 'src/state/effector/users/user.types'
import { TeamTable } from './TeamTable'
import { getUsers, EUseGetUsers } from '../../../hooks/useGetUsers'
import SearchBar from '../../SearchBar'
import { TeamRoleSelector } from './TeamRoleSelector'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import isEqual from 'lodash/isEqual'
import Switch from '../../Switch'
import { TablePagination } from '../Table/Pagination'
import EmptyStateBox from '../Members/EmptyStateBox'
import { useStore } from 'effector-react'
import { $teamMembers, $usersTrigger } from '../../../state/effector/users/users.store'
import { isAddressValid } from '../../../utils/truncateAddress'
// import TribersSelect from '../Members/AllTribers/TribersSelect'

const OFFSET_VALUE = 25

export const Team: React.FC = () => {
  const { isAllowed } = useAccessController(UserRoles.SuperAdmin)
  const mobile = isMobile()

  const teamMembers = useStore($teamMembers)
  const trigger = useStore($usersTrigger)

  const [isOpenModal, setIsOpenModal] = useState(false)
  // const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [searchByWalletValue, setSearchByWalletValue] = useState('')
  const [searchByNameValue, setSearchByNameValue] = useState('')
  const [role, setRole] = useState([])
  const [showPastTeamMembers, setShowPastTeamMembers] = useState(false)

  const teamMembersParams: GetUsersParams = useMemo(() => {
    const data: GetUsersParams = {
      page,
      wallet: searchByWalletValue,
      name: searchByNameValue,
      offset: OFFSET_VALUE,
      all: true,
      whitelist: null,
      showTeamMembers: true,
      showHiddenUsers: true,
      showPastTeamMembers
    }
    if (!isEqual(role, [])) data.roles = role.join(',')
    return data
  }, [page, searchByWalletValue, searchByNameValue, role, showPastTeamMembers])

  useEffect(() => {
    const getTeamMembers = getUsers(teamMembersParams, EUseGetUsers.teamMembers)

    getTeamMembers()
  }, [
    page,
    searchByWalletValue,
    searchByNameValue,
    role,
    showPastTeamMembers,
    trigger,
    teamMembersParams
  ])

  const handleOpenModal = () => setIsOpenModal(true)
  const handleCloseModal = useCallback(() => setIsOpenModal(false), [])

  const handleResetPage = () => setPage(1)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.currentTarget.value
    const searchWorldWithTrim = searchWord.trim()

    if (isAddressValid(searchWorldWithTrim)) {
      setSearchByWalletValue(searchWorldWithTrim)
      setSearchByNameValue('')
    } else {
      setSearchByNameValue(searchWord)
      setSearchByWalletValue('')
    }
  }

  const handleChangeSwitch = (value: boolean) => {
    setShowPastTeamMembers(value)
  }
  const handleChangeRole = (event: SelectChangeEvent<UserRoles[]>) => {
    const selectedValue: any = event.target.value
    setRole(selectedValue)
    handleResetPage()
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleClearFilters = () => {
    handleResetPage()
    setSearchByWalletValue('')
    setSearchByNameValue('')
    setRole([])
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '25px',
          marginTop: mobile ? '25px' : 0
        }}
      >
        <Typography
          color={colors.$secondary}
          sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300, marginRight: '20px' }}
          variant="subtitle1"
        >
          Team
        </Typography>

        {isAllowed && (
          <TitleButtonWrapper>
            <ButtonPrimary
              endIcon={<AddIcon sx={{ width: '20px' }} />}
              sx={{ width: '188px', height: '44px' }}
              onClick={handleOpenModal}
            >
              <Typography
                sx={{
                  fontSize: mobile ? '18px' : '16px',
                  textTransform: 'capitalize',
                  fontWeight: 600
                }}
              >
                Add Team
              </Typography>
            </ButtonPrimary>
            <TeamModal isOpen={isOpenModal} type={EModalTypes.Add} handleClose={handleCloseModal} />
          </TitleButtonWrapper>
        )}
      </Box>
      <HrLine />
      <Switch
        checked={showPastTeamMembers}
        onChange={handleChangeSwitch}
        label="Show past Team Members"
        type="primary"
      />
      <Grid container spacing={2} sx={{ padding: '25px 0' }}>
        <Grid item lg={6} md={12} xs={12}>
          <SearchBar
            name="searchByWalletOrName"
            value={searchByWalletValue || searchByNameValue}
            onChange={handleSearchChange}
            placeholder="Search by Wallet or Name"
          />
        </Grid>
        <Grid item lg={4} md={8} xs={12}>
          <TeamRoleSelector value={role} onChange={handleChangeRole} />
        </Grid>
        <Grid item lg={2} md={4} xs={12}>
          <ClearFilterButton variant="contained" onClick={handleClearFilters}>
            Clear Filters
          </ClearFilterButton>
        </Grid>
      </Grid>
      {teamMembers?.items?.length ? (
        <>
          <TeamTable />
          <TablePagination
            response={teamMembers}
            page={page}
            handleChange={handleChangePagination}
          />
        </>
      ) : (
        <EmptyStateBox message="No records found" />
      )}
    </Box>
  )
}

const ClearFilterButton = styled(Button)`
  background-color: black;
  color: white;
  white-space: nowrap;
  height: 100%;
  line-height: 2;
  text-transform: none;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: ${colors.$gray2};
  }
`
const TitleButtonWrapper = styled('div')`
  width: 188px;
`
