export const colors = {
  $black: '#000',
  $white: '#fff',
  $primaryDark: '#292F30',
  $primaryDarkLight: '#111213',
  $default: '#293F6A',
  $primary: '#545E78',
  $primary2: '#293F6A',
  $secondary: '#0B1730',
  $secondary2: '#8B8E95',
  $secondary3: '#747474',
  $secondary4: '#545e78',
  $footer: '#262730',
  $primaryLight: '#8B8E95',
  $purple: '#723BE5',
  $blue: '#0073FD',
  $lightBlue: '#4C9CFB',
  $blueSecondary: '#0065fd',
  $error: '#E55252',
  $lightError: '#F08484',
  $success: '#0FD884',
  $lightSuccess: '#47EDA7',
  $lightSuccess2: '#38eca0',
  $border: '#E5E8EB',
  $borderSecondary: '#D4DBE7',
  $border2: '#d4dbe7',
  $successOrange: '#FFB638',
  $successLight: 'rgba(15, 216, 132, 0.08)',
  $successLightOrange: 'rgba(255, 182, 56, 0.08)',
  $purpleLight: 'rgba(114, 59, 229, 0.08)',
  $tableColor: 'rgba(212, 219, 231, 0.16)',
  $tableColor2: '"#D4dBE7',
  $boxShadow2: '0 4px 16px rgba(0, 0, 0, .08)',
  $boxShadow: '0px 1px 23px rgba(31, 35, 36, 0.04), 0px 12px 44px rgba(47, 61, 65, 0.16)',
  $boxShadow3: '0 2px 4px rgba(0, 0, 0, .05), 0 4px 6px rgba(0, 0, 0, 0.05)',
  $boxShadow4: '0px 6px 24px 0px rgba(47, 61, 65, 0.16), 0px 1px 23px 0px rgba(31, 35, 36, 0.04)',
  $bgLight: '#EBF6F7',
  $disabledBg: 'rgba(104, 110, 122, 1)',
  $disabledBg2: '#D4DBE7',
  $disabledBg3: '#E5E8EB',
  $grayLight: '#C4C4C4',
  $gray2: '#41494B',
  $lightYellow: '#F6B91C',
  $warning: 'rgba(84, 94, 120, 1)',
  $darkBlue: '#0073FD',
  $hoverBlue: 'rgba(2, 136, 209, 0.04)'
}
