import React, { useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/system'
import { Typography, useMediaQuery } from '@mui/material'
import { toast } from 'react-toastify'

import { colors } from 'src/utils/colorVariables'
import { isMobile } from 'src/utils/isMobile'
import { ButtonPrimary, ButtonPrimaryOutlined } from 'src/components/Button'
import LoadingIndicator from 'src/components/LoadingIndicator'
import apiService from 'src/services/api'

import { Templates } from './templates-list'
import { EventsHistory } from './events-history'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/state/effector/users/user.types'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { IEvent } from 'src/types'

let interval = null as any

export const Airdrops = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [templates, handleTemplates] = useState([])
  const [events, handleEvents] = useState<ResponseWithPagination<IEvent[]>>(
    {} as ResponseWithPagination<IEvent[]>
  )
  const [showArchived, handleShowArchived] = useState(false)

  const navigate = useNavigate()
  const isLaptop = useMediaQuery('@media(min-width: 800px)')
  const mobile = isMobile()

  const { isAllowed: isSuperAdmin } = useHaveAccess({ accessRoles: [UserRoles.SuperAdmin] })

  const fetchEvents = useCallback(async () => {
    try {
      const eventsRes = await apiService.getAirdropEvents({
        offset: 10,
        page: events.page
      })
      handleEvents(eventsRes.data)
    } catch (error) {}
  }, [events.page])

  useEffect(() => {
    clearInterval(interval)
    interval = setInterval(fetchEvents, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [fetchEvents])

  const handleChangePagination = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    try {
      setIsLoading(true)
      const eventsRes = await apiService.getAirdropEvents({ offset: 10, page: newPage })
      handleEvents(eventsRes.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true)
        const templatesRes = await apiService.getAirdropTemplate({
          offset: 100,
          isArchived: showArchived
        })
        const eventsRes = await apiService.getAirdropEvents({ offset: 10, page: 1 })
        handleTemplates(templatesRes.data.items)
        handleEvents(eventsRes.data)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
  }, [showArchived])

  const scrollToHistory = () => {
    document.getElementById('template-history')?.scrollIntoView({ behavior: 'smooth' })
  }

  const goToCreate = () => {
    navigate('/admin/airdrops/template')
  }

  const unarchive = async (id: number) => {
    try {
      setIsLoading(true)
      await apiService.unarchiveTemplate(id)
      const templatesRes = await apiService.getAirdropTemplate({
        offset: 20,
        isArchived: showArchived
      })
      handleTemplates(templatesRes.data.items)
      toast.success('Template successfully unarchived.')
    } catch (error) {
      toast.success('Failed to unarchive template. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  // if (!isLaptop) {
  //   return <EventsHistory events={events} handleChangePagination={handleChangePagination} />
  // }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      <Box
        sx={{
          marginTop: mobile ? '25px' : 0
        }}
      >
        <Typography
          color={colors.$secondary}
          sx={{ fontSize: mobile ? 18 : 30, fontWeight: 700, marginRight: '20px' }}
          variant="subtitle1"
        >
          Airdrops
        </Typography>
      </Box>
      <ActionsContainer>
        {isSuperAdmin && isLaptop && (
          <ButtonPrimary
            onClick={goToCreate}
            sx={{ textTransform: 'none', maxWidth: 'fit-content', padding: '6px 16px' }}
          >
            Create a Template
          </ButtonPrimary>
        )}
        <ButtonPrimaryOutlined
          onClick={scrollToHistory}
          sx={{ textTransform: 'none', maxWidth: 'fit-content', padding: '6px 16px' }}
        >
          Check History
        </ButtonPrimaryOutlined>
        <ShowArchivedBtn onClick={() => handleShowArchived((state) => !state)}>
          {showArchived ? 'Hide' : 'Show'} archived templates
        </ShowArchivedBtn>
      </ActionsContainer>
      <Templates templates={templates} unarchive={unarchive} />
      <EventsHistory
        events={events}
        handleChangePagination={handleChangePagination}
        fetchEvents={fetchEvents}
      />
    </Container>
  )
}

export const Container = styled.div`
  max-width: 900px;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 32px 0px;
`

const ShowArchivedBtn = styled.div`
  color: ${colors.$blue};
  text-decoration: underline;
  text-transform: none;
  max-width: fit-content;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`
