import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'src/utils/colorVariables'

interface Props {
  checked: boolean
  disabled?: boolean
  onChange?: (a: boolean) => void
  bgColor?: string
}

const RadioButton: React.FC<Props> = ({ checked, onChange, bgColor, disabled }) => (
  <RadioButtonContainer>
    {checked ? (
      <RadioChecked onClick={() => onChange && !disabled && onChange(!checked)} bgColor={bgColor} />
    ) : (
      <Radio onClick={() => onChange && !disabled && onChange(!checked)} bgColor={bgColor} />
    )}
  </RadioButtonContainer>
)

export default RadioButton

interface RadioProps {
  bgColor?: string
}

const RadioButtonContainer = styled('div')`
  min-width: 19px;
  max-width: 19px;
  margin-right: 8px;
`

const Radio = styled('div')<RadioProps>`
  border: 1px solid #0073fd36;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`

const RadioChecked = styled(Radio)`
  border: 5px solid ${colors.$blue};
`
