import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { Box, Grid } from '@mui/material'
import { toast } from 'react-toastify'
import styled from '@emotion/styled'

import Switch from '../../../Switch'
import {
  $isMaintenanceModeActive,
  setIsMaintenanceModeActive
} from '../../../../state/effector/store'
import CustomModal from '../../../Modal/customModal'
import { ButtonBasePrimary, ButtonBasePrimaryOutlined } from '../../../Button'
import { colors } from '../../../../utils/colorVariables'
import apiService from '../../../../services/api'
import { useAccessController } from '../../../../hooks/useAccessController'
import { UserRoles } from '../../../../state/effector/users/user.types'

interface IProps {
  isFullMode?: boolean
}
export const MaintenanceModeSwitcher: React.FC<IProps> = ({ isFullMode }) => {
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)

  const { isNotAllowed } = useAccessController(UserRoles.Admin)

  const handleModalOpen = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const handleChangeModeStatus = async () => {
    setIsModalLoading(true)

    await apiService
      .switchMaintenanceMode(!isMaintenanceModeActive)
      .then(() => {
        setIsModalOpen(false)
        setIsModalLoading(false)
        setIsMaintenanceModeActive(!isMaintenanceModeActive)
      })
      .catch(() => {
        setIsModalLoading(false)
        toast.error('Something went wrong')
      })
  }

  if (isNotAllowed) return null

  return (
    <>
      <Switch
        type="primary-dark"
        checked={isMaintenanceModeActive}
        onChange={handleModalOpen}
        label={isFullMode ? 'Dapp Maintenance' : undefined}
      />
      <CustomModal
        isOpen={isModalOpen}
        title={`Turn ${isMaintenanceModeActive ? 'off' : 'on'} Maintenance Mode?`}
        handleClose={handleCloseModal}
        isLoading={isModalLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentWrapper>
              Only administrators will have access to the Dapp while Maintenance mode is on.
            </StyledContentWrapper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <StyledButtonWrapper>
                  <ButtonBasePrimaryOutlined onClick={handleCloseModal} disabled={isModalLoading}>
                    Cancel
                  </ButtonBasePrimaryOutlined>
                </StyledButtonWrapper>
              </Grid>
              <Grid item xs={6} md={6}>
                <StyledButtonWrapper>
                  <ButtonBasePrimary disabled={isModalLoading} onClick={handleChangeModeStatus}>
                    Turn {isMaintenanceModeActive ? 'Off' : 'On'}
                  </ButtonBasePrimary>
                </StyledButtonWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  )
}

const StyledButtonWrapper = styled(Box)`
  margin-top: 20px;

  button {
    width: 100%;
    text-transform: none;
    font-size: 16px;
    padding: 10px;
    min-height: 0;
    border-radius: 8px;
  }
`

const StyledContentWrapper = styled(Box)`
  color: ${colors.$primary};
  padding: 0 10%;
  margin-top: 20px;
  font-weight: 300;
  text-align: center;
`
