import { ethers } from 'ethers'
import { useMemo } from 'react'
import erc1155Abi from 'src/lib/erc1155.json'
import erc20Abi from 'src/lib/erc20Abi.json'
import erc721Abi from 'src/lib/erc721Abi.json'
import { ERC20Permit__factory } from 'src/typechain'
// import routerAbi from '../lib/routerAbi.json';
import accessAbi from '../lib/Access.json'
import sixthSocietyNFTAbi from '../lib/SixthSocietyNFT.json'
import sixthSocietyNFTSaleAbi from '../lib/SixthSocietyNFTSale.json'
// import { currencies, SixthSocietySaleAddress, AccessAddress, SixthSocietyNFT } from '../constants/currency';
import { networks } from '../constants/currency'
import { TTokenType } from '../types/common.types'
import useMetaMask from './useMetaMask'

export const getProviderOrSigner = (provider: any, account?: string) => {
  return account ? provider?.getSigner()?.connectUnchecked() : provider
}

export function useTokenContracts() {
  const { provider, chainId, account } = useMetaMask()

  return useMemo(() => {
    const currencies = networks?.[chainId as keyof typeof networks]?.currencies
    return Object.entries(currencies).map(([symbol, currency]) => {
      const contract = ERC20Permit__factory.connect(
        currency.token,
        getProviderOrSigner(provider, account)
      )

      return {
        symbol,
        contract,
        path: currency.path,
        icon: currency.icon,
        chainId,
        gasless: currency.gasless
      }
    })
  }, [provider, chainId, account])
}

export function useAccessContract() {
  const { provider, chainId } = useMetaMask()
  const accessAddress = networks?.[chainId as keyof typeof networks]?.networkAddresses.AccessAddress

  return new ethers.Contract(accessAddress, accessAbi, provider)
}

export function useSixthSocietySale() {
  const { provider, chainId, account } = useMetaMask()

  return useMemo(() => {
    const SixthSocietySaleAddress =
      networks?.[chainId as keyof typeof networks]?.networkAddresses?.SixthSocietySaleAddress

    return new ethers.Contract(
      SixthSocietySaleAddress,
      sixthSocietyNFTSaleAbi,
      getProviderOrSigner(provider, account)
    )
  }, [chainId, provider, account])
}

export function useSixthSocietyNFT() {
  const { provider, chainId, account } = useMetaMask()
  return useMemo(() => {
    return networks?.[chainId as keyof typeof networks]?.networkAddresses.NftTypes.map(
      (nftAddress) =>
        new ethers.Contract(
          nftAddress.SixthSocietyNFT,
          sixthSocietyNFTAbi,
          getProviderOrSigner(provider, account)
        )
    )
  }, [chainId, provider, account])
}

export function useSixthSocietyNFTAddresses() {
  const { chainId } = useMetaMask()
  return useMemo(() => {
    return networks?.[chainId as keyof typeof networks]?.networkAddresses.NftTypes.map(
      (nftAddress) => nftAddress.SixthSocietyNFT
    )
  }, [chainId])
}

export function useNft(address: string, tokenType?: TTokenType) {
  const { provider, account } = useMetaMask()
  return useMemo(() => {
    return new ethers.Contract(
      address,
      tokenType === 'ERC1155' ? erc1155Abi : erc721Abi,
      getProviderOrSigner(provider, account)
    )
  }, [account, provider, tokenType])
}
