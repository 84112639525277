import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useStore } from 'effector-react'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'

import apiService from 'src/services/api'
import { getNftName } from 'src/utils/nft.utils'
import { $user } from 'src/state/effector/users/users.store'
import useMetaMask from 'src/hooks/useMetaMask'
import { UserRoles } from 'src/state/effector/users/user.types'
import successImg from 'src/assets/modal/success_new.svg'
import { networks } from 'src/constants/currency'
import { useIsOpen } from 'src/hooks/use-is-open'
import { useAccessController } from 'src/hooks/useAccessController'
import { colors } from 'src/utils/colorVariables'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'

import { IBookNftRequestData, IReviewBookNFTModal } from './BookNFTModal.types'
import CustomModal from '../../Modal/customModal'
import { getNFTIndexByName, serializedValues } from '../SendNFTModal/helpers/utils'
import { SentNFTConfirmModal } from '../SendNFTModal'
import { ButtonPrimaryOutlined } from '../../Button'
import MemberModal, { getErrorMessage } from '../Members/MemberModal/MemberModal'
import { IValues, SendNFTSuccessResponse } from '../SendNFTModal/helpers/types'
import { SendSuccessComponent } from '../SendNFTModal/SendSuccessComponent'

export const ReviewBookNFTModal: FC<IReviewBookNFTModal> = ({ isOpen, handleClose, book }) => {
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [bookData, setBookData] = useState<IBookNftRequestData | Record<string, never>>({})
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [registerModalOpen, setRegisterModalOpen] = useState(false)
  const [sendError, setSendError] = useState<Array<string>>([])
  const [sendWarning, setSendWarning] = useState<Array<string>>([])
  const [sendSuccess, setSendSuccess] = useState<SendNFTSuccessResponse>({})

  const { isOpen: isConfirmOpen, open: openConfirm, close: closeConfirm } = useIsOpen()

  const { chainId } = useMetaMask()
  const networkType = networks[chainId as keyof typeof networks]?.networkType
  const user = useStore($user)

  const { isAllowed: isAllowedOnlyForEditor } = useAccessController(UserRoles.Editor, true)

  useEffect(() => {
    if (book.id) {
      setIsModalLoading(true)
      apiService
        .getBookedNFTByID(book.id)
        .then((resp) => setBookData(resp?.data || {}))
        .catch(() => toast.error("Error: Can't get request"))
        .finally(() => setIsModalLoading(false))
    }
  }, [book.id])

  const onClose = useCallback(() => {
    handleClose()
    setBookData({})
    setSendSuccess({})
    setSendError([])
  }, [handleClose])

  const cancelBook = useCallback(() => {
    if (book.id) {
      setIsModalLoading(true)
      apiService
        .rejectBookedNFT(book.id)
        .then(({ data }) => {
          toast.success(data?.message)
          onClose()
        })
        .catch(() => toast.error("Error: Can't reject request"))
        .finally(() => setIsModalLoading(false))
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [book.id])
  const approveBook = useCallback(() => {
    if (book.id) {
      setIsModalLoading(true)
      apiService
        .sendBookedNFT(chainId, book.id)
        .then(({ data }) => {
          const message = data.message as SendNFTSuccessResponse
          setSendSuccess({ ...message, submitted: true })
        })
        .catch((response) => {
          const errorText = getErrorMessage(response)
          setSendError(errorText.includes('[') ? JSON.parse(errorText) : [errorText])
        })
        .finally(() => {
          setIsModalLoading(false)
          closeConfirm()
        })
    }
  }, [book.id, chainId])

  const onEditMemberModalClose = async () => {
    await requestValidate()
    if (editModalOpen) setEditModalOpen(false)
    if (registerModalOpen) setRegisterModalOpen(false)
    // setSendError([])
  }

  const requestValidate = async () => {
    try {
      setIsModalLoading(true)
      const res = await apiService.adminValidateBookNft(
        serializedValues(
          {
            amount: bookData.amount,
            ethAddress: bookData.member.ethAddress,
            nftAddress: book.nftAddress,
            networkType
          } as IValues,
          networkType
        )
      )
      setSendWarning(res.data.warnings)
      setSendError([])
    } catch (error: any) {
      const errorText = getErrorMessage(error)
      setSendError(errorText.includes('[') ? JSON.parse(errorText) : [errorText])
    } finally {
      setIsModalLoading(false)
    }
  }

  useEffect(() => {
    if (bookData?.member?.ethAddress) {
      requestValidate()
    }
  }, [bookData?.member?.ethAddress])

  const sendErrorComponent = useMemo(() => {
    return sendError.map((error) => {
      switch (error) {
        case 'This wallet is not registered yet.':
          return (
            <>
              <ErrorText>{error}</ErrorText>{' '}
              <StyledLink onClick={() => setRegisterModalOpen(true)}>
                Click here to register this member
              </StyledLink>
            </>
          )
        case 'This wallet is not whitelisted yet.':
          return (
            <>
              <ErrorText>{error}</ErrorText>{' '}
              <StyledLink onClick={() => setEditModalOpen(true)}>
                Click here to edit this member
              </StyledLink>
            </>
          )
        case 'This wallet doesnt have Introducer.':
          return (
            <>
              <ErrorText>{error}</ErrorText>{' '}
              <StyledLink onClick={() => setEditModalOpen(true)}>
                Click here to edit this member
              </StyledLink>
            </>
          )

        default:
          toast.error(error)
          return <></>
      }
    })
  }, [sendError])

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={onClose}
      title={sendSuccess?.txHash ? 'NFT has been sent successfully!' : 'NFT sending review'}
      isLoading={isModalLoading}
    >
      <SentNFTConfirmModal
        isOpen={isConfirmOpen}
        handleClose={closeConfirm}
        isLoading={isModalLoading}
        onSubmit={approveBook}
        ethAddress={bookData?.member?.ethAddress}
        amount={bookData?.amount}
        nftAddress={bookData?.nftAddress}
      />
      <Box pb={{ md: '20px' }} px={{ md: '20px' }}>
        {!isEmpty(sendSuccess) ? (
          <>
            <Box display="flex" justifyContent="center" pb={4} pt={2}>
              <img src={successImg} alt="" />
            </Box>
            {isEmpty(sendError) && (
              <SendSuccessComponent
                sendSuccess={sendSuccess}
                values={{
                  nftIndex: 0,
                  amount: bookData?.amount,
                  nftAddress: bookData?.nftAddress,
                  ethAddress: bookData?.member?.ethAddress,
                  networkType
                }}
                chainId={chainId}
              />
            )}
          </>
        ) : (
          <>
            <Box color={colors.$default} textAlign="center" mb={3} fontWeight={300}>
              Please review the pending NFT Transfer
            </Box>
            {Boolean(bookData?.requester) && (
              <>
                <Typography color={colors.$default}>Requester:</Typography>
                <BlockWrapper>
                  {Boolean(bookData?.requester?.name) && (
                    <>
                      <Typography fontWeight={700} display="inline">
                        {bookData?.requester?.name}
                      </Typography>{' '}
                      ({bookData?.requester?.role}) <br />
                    </>
                  )}
                  {bookData?.requester?.ethAddress}
                </BlockWrapper>
              </>
            )}

            <Typography color={colors.$default} mt={3}>
              Sending:
            </Typography>
            <BlockWrapper>
              <Typography fontWeight={700} display="inline">
                {bookData?.amount} {getNftName(chainId, bookData.nftAddress)}
              </Typography>{' '}
              to user: <br />
              {Boolean(bookData?.member?.name) && (
                <Typography fontWeight={700} display="flex" alignItems="center">
                  {bookData?.member?.name}
                  &nbsp;
                  <img
                    src={bookData?.member?.country === 'US' ? usFlagIcon : internationalIcon}
                    alt="country"
                    width="16px"
                  />
                  <br />
                </Typography>
              )}
              {bookData?.member?.ethAddress}
              {Boolean(bookData?.commission) && Boolean(bookData?.introducer?.ethAddress) && (
                <Box mt={3}>
                  <Typography fontWeight={700} display="inline">
                    {bookData?.commission?.toLocaleString()} USDC{' '}
                  </Typography>
                  potential reward to the introducer: <br />
                  {Boolean(bookData?.introducer?.name) && (
                    <Typography fontWeight={700} display="flex" alignItems="center">
                      {bookData?.introducer?.name}&nbsp;
                      <img
                        src={
                          bookData?.introducer?.country === 'US' ? usFlagIcon : internationalIcon
                        }
                        alt="country"
                        width="16px"
                      />
                      <br />
                    </Typography>
                  )}
                  {bookData?.introducer?.ethAddress}
                </Box>
              )}
              {sendError.length > 0 && <Box mt={1.5}>{sendErrorComponent}</Box>}
              {sendWarning.length > 0 && (
                <Box mt={1.5}>
                  {sendWarning.map((warning) => (
                    <Typography key={warning + 'resp'} color={colors.$lightYellow} pt={1.2}>
                      {warning}
                    </Typography>
                  ))}
                </Box>
              )}
            </BlockWrapper>
            <StyledButtonWrapper>
              {isAllowedOnlyForEditor || bookData?.requester?.ethAddress === user?.ethAddress ? (
                <ButtonPrimaryOutlined onClick={cancelBook}>Cancel sending</ButtonPrimaryOutlined>
              ) : (
                <Grid container spacing={{ xs: 2, md: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <StyledApproveButton onClick={openConfirm} disabled={!isEmpty(sendError)}>
                      Approve
                    </StyledApproveButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledRejectButton onClick={cancelBook}>Reject</StyledRejectButton>
                  </Grid>
                </Grid>
              )}
            </StyledButtonWrapper>
            {Boolean(editModalOpen) && Boolean(bookData?.member?.ethAddress) && (
              <MemberModal
                type="edit"
                ethAddress={bookData?.member?.ethAddress}
                withoutButtons={true}
                onClose={onEditMemberModalClose}
              />
            )}
            {Boolean(registerModalOpen) && Boolean(bookData?.member?.ethAddress) && (
              <MemberModal
                type="add"
                ethAddress={bookData?.member?.ethAddress}
                withoutButtons={true}
                onClose={onEditMemberModalClose}
              />
            )}
          </>
        )}
      </Box>
    </CustomModal>
  )
}

const BlockWrapper = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  margin-top: 12px;
  background-color: ${colors.$bgLight};
  word-break: break-word;
`

const StyledButtonWrapper = styled(Box)`
  margin-top: 20px;

  button {
    width: 100%;
    text-transform: none;
    font-size: 16px;
    padding: 10px;
    min-height: 0;
    border-radius: 8px;
  }
`

const StyledApproveButton = styled(Button)`
  padding: 16px;
  border: 1px solid ${colors.$success};
  border-radius: 8px;
  color: ${colors.$success};
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
    background-color: transparent;
  }

  &:disabled {
    border: 1px solid ${colors.$borderSecondary};
  }
`
const StyledRejectButton = styled(Button)`
  padding: 16px;
  border: 1px solid ${colors.$error};
  border-radius: 8px;
  color: ${colors.$error};
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
    background-color: transparent;
  }
`

const StyledLink = styled(Typography)`
  color: ${colors.$blue};
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
`

const ErrorText = styled(Typography)`
  color: ${colors.$error};
  display: inline;
`
