import React from 'react'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { GridSize } from '@mui/material/Grid/Grid'

import { colors } from '../../../utils/colorVariables'
import { fixPrice } from '../../../utils/common.utils'
import { numberWithComma } from '../../../utils/number'

interface IOverviewItemProps {
  title?: string
  children: React.ReactNode
}

interface GridSizes {
  lg?: boolean | GridSize
  md?: boolean | GridSize
  sm?: boolean | GridSize
  xl?: boolean | GridSize
  xs?: boolean | GridSize
}

interface IOverviewItemRowProps extends GridSizes {
  children: React.ReactNode
  nextItem?: boolean
  withHrLine?: boolean
}
interface IRowItemProps extends GridSizes {
  title?: string | JSX.Element
  body?: number | string
  currencySymbol?: string
}

export const OverviewItemRow: React.FC<IOverviewItemRowProps> = ({
  children,
  nextItem,
  withHrLine,
  xs,
  lg,
  sm,
  md,
  xl
}) => {
  const gridSizes = { xs, sm, md, lg, xl }

  return (
    <OverviewItemRowStyled
      container
      spacing={2}
      nextItem={nextItem}
      {...gridSizes}
      withHrLine={withHrLine}
    >
      {withHrLine && <Line />}
      {children}
    </OverviewItemRowStyled>
  )
}

export const RowItem: React.FC<IRowItemProps> = ({
  body,
  title,
  currencySymbol,
  xs,
  lg,
  sm,
  md,
  xl
}) => {
  const gridSizes = { xs, sm, md, lg, xl }

  return (
    <RowItemStyled item {...gridSizes}>
      {title && <ItemTitle>{title}</ItemTitle>}
      {(typeof body === 'string' || Number(body) >= 0) && (
        <ItemBody>
          {currencySymbol || ''}
          {numberWithComma(fixPrice(body as number | string, 2))}
        </ItemBody>
      )}
    </RowItemStyled>
  )
}

export const OverviewItem: React.FC<IOverviewItemProps> = ({ children, title }) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {children}
    </Container>
  )
}

const Container = styled('div')`
  padding: 28px;
  gap: 32px;
  border: 1px solid ${colors.$borderSecondary};
  height: 100%;
  width: 100%;
  background-color: white;
`

const Title = styled('div')`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 28px;
  color: ${colors.$secondary};
`

interface IOverviewItemRowStyledProps {
  nextItem?: boolean
  withHrLine?: boolean
}

const OverviewItemRowStyled = styled(Grid)<IOverviewItemRowStyledProps>`
  display: flex;
  padding-top: ${({ nextItem, withHrLine }) => (nextItem ? (withHrLine ? '48px' : '28px') : '0')};
  margin-top: ${({ withHrLine }) => (withHrLine ? '0' : '-16px')};
  position: relative;
  width: 100%;
`

const Line = styled('div')`
  position: absolute;
  opacity: 0.5;
  width: 100%;
  left: 14px;
  right: auto;
  height: 1px;
  top: 34px;
  background-color: ${colors.$border2};
`

const RowItemStyled = styled(Grid)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
`
const ItemTitle = styled('div')`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.$secondary};
  margin-bottom: 12px;
`
const ItemBody = styled('div')`
  font-weight: 500;
  font-size: 34px;
  line-height: 100%;
  color: ${colors.$secondary};
`
