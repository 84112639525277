import React, { useCallback, useState } from 'react'
import { Box, css } from '@mui/system'
import { Button, TableContainer } from '@mui/material'

import { renderNameWithTruncate, Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import { $teamMembers, $user } from '../../../../state/effector/users/users.store'
import { useStore } from 'effector-react'
import { User, UserRoles } from '../../../../state/effector/users/user.types'
import moment from 'moment/moment'

import styled from '@emotion/styled'

import { ROLES } from '../../../../hooks/useAccessController'
import TeamModal, { EModalTypes } from '../TeamModal/TeamModal'
import { colors } from '../../../../utils/colorVariables'
import { AddressesCell } from '../../Table/AddressesCell'

const headerData: string[] = ['Team Member', 'Wallet', 'Role', 'Registration', '']

const TeamTable: React.FC = () => {
  const teamMembers = useStore($teamMembers)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [ethAddress, setEthAddress] = useState<string | null>(null)

  const handleOpenModal = (value: string) => {
    setEthAddress(value)
    setIsModalOpen(true)
  }
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    setEthAddress(null)
  }, [])

  const user = useStore($user)
  const userRole = user?.role || UserRoles.Viewer

  const roles = [UserRoles.Admin, UserRoles.SuperAdmin]

  const isNotAllowedEditBtn = useCallback(
    (accessRole: UserRoles, row: User) => {
      if (userRole === UserRoles.SuperAdmin) {
        return false
      } else if (row.deactivated) {
        return true
      } else if (roles.includes(userRole)) {
        return !(ROLES.indexOf(userRole) > ROLES.indexOf(accessRole))
      } else {
        return true
      }
    },
    [userRole]
  )

  return (
    <Box>
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '700px' }}>
          <HeaderRow rowTemplate={rowTemplate}>
            {headerData.map((header) => (
              <CellPrimary key={header}>{header}</CellPrimary>
            ))}
          </HeaderRow>
          <>
            {teamMembers?.items?.length
              ? teamMembers.items.map((row: User, idx) => (
                  <StyledBodyRow
                    key={row.id}
                    deactivated={row.deactivated}
                    rowTemplate={rowTemplate}
                  >
                    <CellPrimary>{renderNameWithTruncate(row.name as string) || ''}</CellPrimary>
                    <CellPrimary>
                      <AddressesCell address={row.ethAddress} id={`${idx}_address`} />
                    </CellPrimary>
                    <StyledRoleCellPrimary>
                      {row.role === UserRoles.SuperAdmin ? 'Super-Admin' : row.role}
                      <span>{row.deactivated ? ' (deactivated)' : ''}</span>
                    </StyledRoleCellPrimary>
                    <CellPrimary>{moment(row.createdAt).format('DD MMM YYYY')}</CellPrimary>
                    <CellPrimary>
                      <Button
                        color="info"
                        onClick={() => handleOpenModal(row.ethAddress)}
                        disabled={isNotAllowedEditBtn(row.role, row)}
                        sx={{
                          textTransform: 'none',
                          fontWeight: 500,
                          fontSize: '16px',
                          marginTop: '-4px',
                          color: colors.$darkBlue
                        }}
                      >
                        Edit
                      </Button>
                    </CellPrimary>
                  </StyledBodyRow>
                ))
              : '-'}
          </>
        </Table>
      </TableContainer>
      <TeamModal
        isOpen={isModalOpen && !!ethAddress}
        type={EModalTypes.Edit}
        handleClose={handleCloseModal}
        ethAddress={ethAddress || undefined}
      />
    </Box>
  )
}

export default TeamTable

const rowTemplate = css`
  grid-template-columns: repeat(4, minmax(150px, 1fr)) minmax(95px, 1fr);
`

const StyledRoleCellPrimary = styled(CellPrimary)`
  text-transform: capitalize;
  span {
    text-transform: none;
  }
`

interface IStyledTableRowByTeamProps {
  deactivated?: boolean
}

const StyledBodyRow = styled(BodyRow)<IStyledTableRowByTeamProps>`
  //opacity: 0.7;
  td:not(:last-child) {
    //opacity: 0.1;
    color: ${({ deactivated }) => (deactivated ? colors.$primaryLight : 'auto')};
    div {
      color: ${({ deactivated }) => (deactivated ? colors.$primaryLight : 'auto')};
    }
  }
`
