import * as Yup from 'yup'
import { isAddressValid } from '../../../../utils/truncateAddress'

export const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .min(1)
    .test('max test', 'Due to blockchain limits you can choose maximum 100 NFT', (value) => {
      return Number(value) <= 100
    }),
  ethAddress: Yup.string().test(
    'validate address',
    'Invalid wallet format, should be an Ethereum address',
    (value: string | undefined, { createError }) => {
      if (value === '' || value === undefined) {
        return createError({
          message: `Member’s wallet required field`,
          path: 'ethAddress'
        })
      }
      return Boolean(isAddressValid(value || ''))
    }
  ),
  networkType: Yup.string()
})
