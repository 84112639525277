import React from 'react'
import styled from '@emotion/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { colors } from 'src/utils/colorVariables'
import { ButtonPrimary, ButtonPrimaryOutlined } from 'src/components/Button'
import { useIsOpen } from 'src/hooks/use-is-open'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/state/effector/users/user.types'

import { TemplateType } from './index'
import { formatValue } from '../constants'
import { ConfirmModal } from '../confirm-modal'

interface Props {
  template?: TemplateType
  isOneTime?: boolean
  openForm?: () => void
  unarchive?: (id: number) => void
}

const tempateContent = [
  'region',
  'category',
  'rewardType',
  'frequency',
  'distribution',
  'isVotingRequired'
] as Array<keyof TemplateType>

export const TemplateCard = ({
  template,
  isOneTime,
  unarchive = () => {},
  openForm = () => {}
}: Props) => {
  const navigate = useNavigate()
  const isLaptop = useMediaQuery('@media(min-width: 800px)')

  const { isAllowed: isSuperAdmin } = useHaveAccess({ accessRoles: [UserRoles.SuperAdmin] })

  const { isOpen: isConfirmOpen, open: openConfirm, close: closeConfirm } = useIsOpen()

  if (isOneTime) return <OneTime openForm={openForm} />

  if (!template?.name) return null

  const onEdit = () => {
    navigate(`/admin/airdrops/template/${template.id}`)
  }

  const onUnarchive = () => {
    unarchive(template.id)
    closeConfirm()
  }

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmOpen}
        handleClose={closeConfirm}
        title={`Do you want to unarchive this template?`}
        onSubmit={onUnarchive}
        confirmText="Unarchive"
      />
      <Container>
        <div>
          <Title
            style={{
              ...(template.deletedAt && {
                color: colors.$secondary3
              })
            }}
          >
            <div>
              {template.id}.&nbsp;{template.name}
            </div>
          </Title>
          <ContentItems>
            {tempateContent.map((key) => (
              <ContentItem key={key}>
                {formatValue(key as string, template[key] as string | boolean, template)}
              </ContentItem>
            ))}
            {isSuperAdmin && (isLaptop || template.deletedAt) && (
              <EditBtn onClick={template.deletedAt ? openConfirm : onEdit}>
                {template.deletedAt ? 'Unarchive' : 'Edit'}
              </EditBtn>
            )}
          </ContentItems>
        </div>
        {template.deletedAt ? (
          <ArchivedText>Archived</ArchivedText>
        ) : (
          isLaptop && (
            <SubmitBtn onClick={openForm}>
              Submit
              <ArrowForwardIosIcon sx={{ width: '16px' }} />
            </SubmitBtn>
          )
        )}
      </Container>
    </>
  )
}

const OneTime = ({ openForm = () => {} }) => {
  return (
    <OneTimeContainer>
      <div>
        <div>Manual Airdrop</div>
        <div>All fields to be filled manually</div>
      </div>
      <SubmitBtn onClick={openForm}>
        Submit
        <ArrowForwardIosIcon sx={{ width: '16px' }} />
      </SubmitBtn>
    </OneTimeContainer>
  )
}

const ArchivedText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  width: 110px;
  text-align: center;
`

const SubmitBtn = styled(ButtonPrimary)`
  min-height: 36px;
  height: 36px;
  width: 110px;
  min-width: 110px !important;
  text-transform: none;
  border-radius: 18px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 22px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
`

const EditBtn = styled(ButtonPrimaryOutlined)`
  min-height: 23px;
  height: 23px;
  width: 53px;
  text-transform: none;
  border-radius: 18px !important;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 11px;
`

const OneTimeContainer = styled.div`
  padding: 20px 24px;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  > div:first-of-type {
    display: flex;
    gap: 8px;
    flex-direction: column;
    > div:first-of-type {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
    > div:last-of-type {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .MuiIconButton-root {
    padding: 0px !important;
  }
`

const Container = styled.div`
  padding: 20px 24px;
  border: 1px solid#e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  > div:first-of-type {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.$secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .create {
    color: ${colors.$blue};
  }
  .id {
    color: ${colors.$primaryLight};
    margin-right: 8px;
  }
`

const ContentItems = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
`

const ContentItem = styled.div`
  padding: 2px 11px;
  font-weight: 600;
  font-size: 11px;
  line-height: 170%;
  color: white;
  background-color: #261e35;
  max-width: fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 50px;
  .icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`

// const DateAndEdit = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 16px;
//   @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
//     flex-direction: column;
//     align-items: initial;
//   }
//   > div {
//     flex: 1;
//     .label {
//       color: ${colors.$primaryLight};
//       text-transform: uppercase;
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 10px;
//       margin-bottom: 4px;
//     }
//     .date {
//       color: ${colors.$secondary};
//       font-weight: 400;
//       font-size: 12px;
//       line-height: 14px;
//     }
//     .name {
//       color: ${colors.$blue};
//       font-weight: 400;
//       font-size: 12px;
//       line-height: 12px;
//     }
//   }
//   .withEdit {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     gap: 8px;
//   }
// `
