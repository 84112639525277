import React, { useCallback, FC } from 'react'
import { Chip, Stack, styled, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'

import { colors } from '../../../utils/colorVariables'
import { isMobile } from '../../../utils/isMobile'
import { IBalance } from './Balance.types'
import apiService from '../../../services/api'
import { $isMaintenanceModeActive } from '../../../state/effector/store'
import { useAccessController } from '../../../hooks/useAccessController'
import { UserRoles } from '../../../state/effector/users/user.types'

const Balance: FC<IBalance> = ({ allowToMyCollection, myCollection, allowToMyAliases }) => {
  const nav = useNavigate()
  const mobile = isMobile()
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const { isAllowed } = useAccessController(UserRoles.User, false, true)

  const handleClick = useCallback(() => {
    if (allowToMyCollection) {
      nav('/my-collection')
    }
  }, [allowToMyCollection, nav])

  const redirectToMyCommunity = async () => {
    try {
      if (allowToMyCollection) {
        const res = await apiService.getTribeToken()
        setTimeout(() => {
          window.open(
            `https://tribe.sixthsociety.com/api/auth/sso?jwt=${res.data}&redirect_uri=/`,
            '_blank'
          )
        })
      }
    } catch (e) {}
  }

  const showMyCommunity = window.location.origin === 'https://app.sixthsociety.com'

  const showMyCollection = isMaintenanceModeActive ? isAllowed : true

  const showMyAliases =
    !isMaintenanceModeActive &&
    (window.location.origin === 'https://dev.sixthsociety.com' ||
      window.location.hostname === 'localhost')

  return (
    <Stack direction="row" spacing={1} className="nft-collection" style={{ marginRight: '33px' }}>
      {showMyCommunity && (
        <TopbarItem>
          <Typography
            variant={mobile ? 'h6' : 'h3'}
            sx={{
              margin: '0 8px 0 0',
              lineHeight: '16px',
              '&:hover': {
                textDecoration: allowToMyCollection ? 'underline' : 'none'
              },
              fontSize: 16,
              fontWeight: 700
            }}
          >
            <div
              onClick={redirectToMyCommunity}
              style={{
                display: 'inline-block',
                color: allowToMyCollection ? colors.$footer : colors.$grayLight,
                cursor: allowToMyCollection ? 'pointer' : 'default',
                pointerEvents: !allowToMyCollection ? 'none' : 'auto',
                lineHeight: '16px',
                maxHeight: '16px',
                fontSize: 16
              }}
            >
              My Community
            </div>
          </Typography>
        </TopbarItem>
      )}

      {showMyCollection && (
        <TopbarItem>
          <Typography
            variant={mobile ? 'h6' : 'h3'}
            sx={{
              margin: '0 8px 0 0',
              lineHeight: '16px',
              '&:hover': {
                textDecoration: allowToMyCollection ? 'underline' : 'none'
              },
              fontSize: 16,
              fontWeight: 700
            }}
          >
            <Link
              to={'/my-collection'}
              style={{
                display: 'inline-block',
                color: allowToMyCollection ? colors.$footer : colors.$grayLight,
                cursor: allowToMyCollection ? 'pointer' : 'default',
                pointerEvents: !allowToMyCollection ? 'none' : 'auto',
                lineHeight: '16px',
                maxHeight: '16px',
                fontSize: 16
              }}
            >
              {mobile ? '' : 'My Collection'}
            </Link>
          </Typography>
          <ChipWrapper disabled={!allowToMyCollection} onClick={handleClick} label={myCollection} />
        </TopbarItem>
      )}
      {showMyAliases && (
        <TopbarItem>
          <Typography
            variant={mobile ? 'h6' : 'h3'}
            sx={{
              margin: '0 8px 0 0',
              lineHeight: '16px',
              '&:hover': {
                textDecoration: 'underline'
              },
              fontSize: 16,
              fontWeight: 700
            }}
          >
            <Link
              to={'/my-aliases'}
              style={{
                display: 'inline-block',
                color: allowToMyAliases ? colors.$footer : colors.$grayLight,
                cursor: allowToMyAliases ? 'pointer' : 'default',
                pointerEvents: !allowToMyAliases ? 'none' : 'auto',
                lineHeight: '16px',
                maxHeight: '16px',
                fontSize: 16
              }}
            >
              {mobile ? '' : 'My Aliases'}
            </Link>
          </Typography>
        </TopbarItem>
      )}
    </Stack>
  )
}

export default Balance

const TopbarItem = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.$primary};
  font-size: 18px;
  font-weight: 400;
  margin: 0 25px 0 0;
  @media (max-width: 599px) {
    margin: 0;
  }
`

const ChipWrapper = styled(Chip)`
  height: 16px;
  background: ${colors.$successOrange};
  font-size: 13px;
  font-weight: 500;
  color: #0b1730;
  padding: 1px 5px;

  ${(props) => props.theme.breakpoints.down('md')} {
    &:hover {
      background: ${colors.$successOrange};
    }
  }

  .MuiChip-label {
    padding: 0;
  }

  @media (max-width: 599px) {
    font-size: 12px;
    height: 20px;
    margin: 0 14px;
  }
`
