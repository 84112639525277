import {
  saveIsUsersLoading,
  saveUsersInStore,
  savePendingUsersInStore,
  saveTeamMembersInStore
} from '../state/effector/users/users.store'
import apiService from '../services/api'
import { ResponseWithPagination } from '../state/response/response.types'
import { GetUsersParams, User } from '../state/effector/users/user.types'

export enum EUseGetUsers {
  all = 'all',
  pendingUsers = 'pendingUsers',
  teamMembers = 'teamMembers'
}

export const getUsers = (params: GetUsersParams, type: EUseGetUsers) => {
  return async () => {
    const saveUsers =
      (type === EUseGetUsers.all && saveUsersInStore) ||
      (type === EUseGetUsers.pendingUsers && savePendingUsersInStore) ||
      (type === EUseGetUsers.teamMembers && saveTeamMembersInStore)
    if (!saveUsers) return

    saveIsUsersLoading(true)
    try {
      const response = await apiService.getUsers(params)
      const usersData: ResponseWithPagination<User[]> = response?.data
      if (usersData) {
        saveUsers(usersData)
      } else {
        saveUsers(null)
      }
    } catch (error) {
      console.error(error)
      saveUsers(null)
    } finally {
      saveIsUsersLoading(false)
    }
  }
}
