import { EMode, IModifyModeForQuery } from '../types/common.types'

export const isVideoFormat = (fileName: string): boolean => {
  const pattern = /\.([mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)]+)(?:[?#]|$)/i
  return !!fileName.match(pattern)
}

export const modifyModeForQuery = <T>(query: T): IModifyModeForQuery<T> => {
  const urlParams = new URLSearchParams(window.location.search)
  const mode = urlParams.get('mode') || EMode.PROD
  return {
    ...query,
    mode: ['test', 'TEST'].includes(mode) ? EMode.TEST : EMode.PROD
  }
}

export const fixPrice = (number: string | number, sliceBefore = 6, sliceAfter = 10) => {
  let value = number

  if (!Boolean(+value)) {
    value = String(number).replaceAll(',', '')
  }

  if (Number.isInteger(+value)) return +value

  const fixedNum = String(+value)
    .split('.')[1]
    .split('')
    .reduce((acc: string[], val) => {
      if (acc.length >= sliceBefore && !acc.every((n) => n === '0')) return acc
      return [...acc, val]
    }, [] as string[])
    .join('')
  return `${String(+value).split('.')[0].slice(0, sliceAfter)}.${fixedNum}`
}
