export enum EnvironmentTypes {
  prod = 'prod',
  staging = 'staging',
  development = 'development'
}

const PROD_API_URL = 'https://api.sixthsociety.com/v1'
const STAGING_API_URL = 'https://staging.sixthsociety.com:3000/v1'
const DEVELOPMENT_API_URL = 'https://dev.sixthsociety.com:3000/v1'

export const getCurrentEnv = () => {
  const apiUrl: string = process.env.REACT_APP_API_URL || ''

  if (apiUrl.includes(PROD_API_URL)) {
    return EnvironmentTypes.prod
  }

  if (apiUrl.includes(STAGING_API_URL)) {
    return EnvironmentTypes.staging
  }

  if (apiUrl.includes(DEVELOPMENT_API_URL)) {
    return EnvironmentTypes.development
  }
  // TODO: select better solution
  // switch(process.env.REACT_APP_API_URL) {
  //   case PROD_API_URL:
  //     return EnvironmentTypes.prod;
  //   case STAGING_API_URL:
  //     return EnvironmentTypes.staging;
  //   case DEVELOPMENT_API_URL:
  //     return EnvironmentTypes.development;
  // }
}

export const currentEnv = getCurrentEnv()

export const isProdEnv = currentEnv === EnvironmentTypes.prod
