import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import Switch from '../../Switch'

interface IProps {
  checked: boolean
  onChange: (val: boolean) => void
  text?: string
  withIcon?: boolean
}

export const HideUnhideSwitch: React.FC<IProps> = ({
  checked,
  onChange,
  text = 'Show hidden users',
  withIcon = true
}) => (
  <SwitchContainer mt={3}>
    <Switch
      checked={checked}
      onChange={onChange}
      label={
        <Box display="flex" alignItems="center" justifyContent="start">
          <Box mr={withIcon ? 1 : 0}>{text}</Box>
          {withIcon && (
            <Box display="flex" alignItems="center" justifyContent="start">
              {checked ? (
                <VisibilityOffOutlinedIcon fontSize="small" />
              ) : (
                <VisibilityOutlinedIcon fontSize="small" />
              )}
            </Box>
          )}
        </Box>
      }
      type="primary"
    />
  </SwitchContainer>
)

const SwitchContainer = styled(Box)`
  background-color: rgba(212, 219, 231, 0.16);
  padding: 10px;
`
