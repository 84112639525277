import { useStore } from 'effector-react'
import { $user } from 'src/state/effector/users/users.store'
import { UserRoles } from '../state/effector/users/user.types'

export const ROLES: UserRoles[] = [
  UserRoles.User,
  UserRoles.Viewer,
  UserRoles.Editor,
  UserRoles.Admin,
  UserRoles.SuperAdmin
]

// TO DO - replace with 'useHaveAccess' hook
export const useAccessController = (accessRole = UserRoles.User, only = false, onlyNot = false) => {
  let isAllowed
  let isNotAllowed

  const user = useStore($user)

  const isDeactivated = user?.deactivated
  const userRole = isDeactivated ? UserRoles.User : user?.role || UserRoles.User

  if (only && !onlyNot) {
    isAllowed = accessRole === userRole
    isNotAllowed = !(accessRole === userRole)
  } else if (onlyNot && !only) {
    isAllowed = !(accessRole === userRole)
    isNotAllowed = accessRole === userRole
  } else if (only && onlyNot) {
    isAllowed = accessRole === userRole
    isNotAllowed = accessRole === userRole
  } else {
    const checkIsAllowed = ROLES.indexOf(userRole) >= ROLES.indexOf(accessRole)
    isAllowed = checkIsAllowed
    isNotAllowed = !checkIsAllowed
  }

  return { isAllowed, isNotAllowed }
}

interface HaveAccess {
  accessRoles: Array<UserRoles>
  only?: boolean
  onlyNot?: boolean
}

export const useHaveAccess = ({ accessRoles, only = true, onlyNot = false }: HaveAccess) => {
  let isAllowed = false
  let isNotAllowed = true

  const user = useStore($user)

  const isDeactivated = user?.deactivated
  const userRole = isDeactivated ? UserRoles.User : user?.role || UserRoles.User

  if (only) {
    const includes = accessRoles.includes(userRole)
    isAllowed = includes
    isNotAllowed = !includes
  }

  if (onlyNot) {
    const includes = accessRoles.includes(userRole)
    isAllowed = !includes
    isNotAllowed = includes
  }

  return { isAllowed, isNotAllowed }
}
