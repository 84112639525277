import { setPageKey, updateNftList } from '../state/effector/store'
import useMetaMask from './useMetaMask'
import AlchemyService from '../services/AlchemyService'
import { useCallback } from 'react'

export interface IGetNftCollection {
  address?: string
  pageKey?: string | null
}

export const useGetNftCollection = () => {
  const { account, chainId } = useMetaMask()

  return useCallback(
    async (data: IGetNftCollection = {}) => {
      const alchemyService = new AlchemyService(chainId)
      const res = await alchemyService.getNftCollection(data.address || account, data.pageKey || '')
      if (data.pageKey) {
        updateNftList({ append: true, nfts: res.ownedNfts })
      } else {
        updateNftList({ append: false, nfts: res.ownedNfts })
      }

      setPageKey(res.pageKey || null)

      return {
        items: res.ownedNfts,
        pageKey: res.pageKey
      }
    },
    [account, chainId]
  )
}
