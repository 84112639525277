import React, { FC } from 'react'
import { Stack, styled, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useStore } from 'effector-react'

import apiService from '../../../services/api'
import { colors } from '../../../utils/colorVariables'
import { INavItems } from './MobileNavigation.types'
import { Chip } from '../Chip'
import { $isMaintenanceModeActive } from '../../../state/effector/store'
import { useAccessController } from '../../../hooks/useAccessController'
import { UserRoles } from '../../../state/effector/users/user.types'

const NavItems: FC<INavItems> = ({
  allowToMyCollection,
  allowToMyAliases,
  myCollection,
  setIsOpen,
  handleChipClick
}) => {
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const { isAllowed } = useAccessController(UserRoles.User, false, true)

  const redirectToMyCommunity = async () => {
    try {
      setIsOpen(false)
      if (allowToMyCollection) {
        const res = await apiService.getTribeToken()
        setTimeout(() => {
          window.open(
            `https://tribe.sixthsociety.com/api/auth/sso?jwt=${res.data}&redirect_uri=/`,
            '_blank'
          )
        })
      }
    } catch (e) {}
  }

  const showMyCommnity = window.location.origin === 'https://app.sixthsociety.com'

  const showMyCollection = isMaintenanceModeActive ? isAllowed : true

  const showMyAliases =
    !isMaintenanceModeActive && window.location.origin === 'https://dev.sixthsociety.com'
  return (
    <Stack direction="column" spacing={3} className="nft-collection" mt={2}>
      {showMyCommnity && (
        <TopbarItem>
          <div
            style={{
              display: 'inline-block',
              color: allowToMyCollection ? colors.$footer : colors.$grayLight,
              cursor: allowToMyCollection ? 'pointer' : 'default',
              pointerEvents: !allowToMyCollection ? 'none' : 'auto'
            }}
            onClick={redirectToMyCommunity}
          >
            <Typography
              variant="h2"
              sx={{
                '&:hover': {
                  textDecoration: allowToMyCollection ? 'underline' : 'none'
                },
                fontWeight: 400
              }}
            >
              My Community
            </Typography>
          </div>
        </TopbarItem>
      )}
      {showMyCollection && (
        <TopbarItem>
          <NavLink
            to={'/my-collection'}
            style={{
              display: 'inline-block',
              color: allowToMyCollection ? colors.$footer : colors.$grayLight,
              cursor: allowToMyCollection ? 'pointer' : 'default',
              pointerEvents: !allowToMyCollection ? 'none' : 'auto'
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={() => setIsOpen(false)}
          >
            <Typography
              variant="h2"
              sx={{
                '&:hover': {
                  textDecoration: allowToMyCollection ? 'underline' : 'none'
                },
                fontWeight: 400
              }}
            >
              My Collection
            </Typography>
          </NavLink>
          <Chip
            allowToMyCollection={allowToMyCollection}
            handleClick={handleChipClick}
            myCollection={myCollection}
          />
        </TopbarItem>
      )}
      {showMyAliases && (
        <TopbarItem>
          <NavLink
            to={'/my-aliases'}
            style={{
              display: 'inline-block',
              color: allowToMyAliases ? colors.$footer : colors.$grayLight,
              cursor: allowToMyAliases ? 'pointer' : 'default',
              pointerEvents: !allowToMyAliases ? 'none' : 'auto'
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={() => setIsOpen(false)}
          >
            <Typography
              variant="h2"
              sx={{
                '&:hover': {
                  textDecoration: 'underline'
                },
                fontWeight: 400
              }}
            >
              My Aliases
            </Typography>
          </NavLink>
        </TopbarItem>
      )}
    </Stack>
  )
}

const TopbarItem = styled('div')`
  display: flex;
  align-items: center;
  color: ${colors.$primary};
  font-size: 20px;
  font-weight: 400;
  padding: 10px 22px;

  .active {
    position: relative;

    h2 {
      font-weight: 700;
    }

    ::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -22px;
      height: calc(100% + 20px);
      width: 4px;
      background-color: #1e2223;
      border-radius: 2px;
    }
  }
`

export default NavItems
