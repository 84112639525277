import React from 'react'

import { networks } from 'src/constants/currency'
import { NftType } from 'src/types'

import useMetaMask from './useMetaMask'

export const useAvailableNftTypes = (all = false) => {
  const now = new Date()

  const { chainId } = useMetaMask()

  const nftTypes = React.useMemo(() => {
    return networks[`${chainId}` as keyof typeof networks].networkAddresses.NftTypes.filter(
      (x) =>
        all || !x.discontinueDate || !x.startDate || (x.discontinueDate > now && x.startDate < now)
    ).reduce(
      (acc, nft: NftType) => ({ ...acc, [nft.SixthSocietyNFT.toLowerCase()]: nft }),
      {} as Record<string, NftType>
    )
  }, [chainId])

  return nftTypes
}
