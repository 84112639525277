import React from 'react'
import styled from '@emotion/styled'
import { FormControlLabel, Switch as BaseSwitch } from '@mui/material'
import { colors } from '../../utils/colorVariables'

type SwitchTypes = 'primary' | 'success' | 'primary-dark'
interface State {
  checked: boolean
  onChange: (val: boolean) => void
  name?: string
  label?: string | React.ReactElement
  type?: SwitchTypes
}

const Switch: React.FC<State> = ({ checked, onChange, name, label = '', type = 'success' }) => {
  const props = {
    checked: checked,
    onChange: () => onChange(!checked),
    name: name
  }
  return (
    <FormControlLabelWrapper
      name={name}
      type={type}
      checked={checked}
      control={
        (type === 'primary' && <AntSwitchPrimary {...props} />) ||
        (type === 'primary-dark' && <AntSwitchPrimaryDark {...props} />) ||
        (type === 'success' && <AntSwitchSuccess {...props} />) || <React.Fragment />
      }
      label={label}
      sx={{ color: 'black' }}
    />
  )
}

export default Switch

interface IFormControlLabelWrapperStyledProps {
  type?: SwitchTypes
  checked: boolean
}

const FormControlLabelWrapper = styled(FormControlLabel)<IFormControlLabelWrapperStyledProps>`
  color: ${({ type, checked }) =>
    type === 'primary-dark' ? (checked ? 'white' : '#8B8E95') : 'black'};
  span:last-child {
    font-weight: 500;
    font-size: 16px;
  }
`

const width = 22

const AntSwitch = styled(BaseSwitch)(
  () =>
    ({
      width: 34,
      height: 22,
      padding: 0,
      marginLeft: 10,
      marginRight: 10,
      display: 'flex',
      '& .MuiSwitch-root': {
        marginLeft: 0
      },
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 16
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(8px)'
        }
      },
      '& .MuiSwitch-switchBase': {
        padding: 3,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1
          }
        }
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 16 / 2
        // transition: theme.transitions.create(['width'], {
        //   duration: 200,
        // }),
      },
      '& .MuiSwitch-track': {
        borderRadius: width / 2,
        opacity: 1,
        backgroundColor: '#767676',
        boxSizing: 'border-box'
      }
    } as any)
)

const AntSwitchPrimary = styled(AntSwitch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: colors.$blue
      }
    }
  }
}))

const AntSwitchPrimaryDark = styled(AntSwitch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: '#0B1730',
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#3F46D6'
      },
      color: '#0B1730'
    }
  },
  '& .MuiSwitch-track': {
    color: '#0B1730'
  }
}))

const AntSwitchSuccess = styled(AntSwitch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: colors.$success
      }
    }
  }
}))
