import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useStore } from 'effector-react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useSearchParams } from 'react-router-dom'

import { colors } from 'src/utils/colorVariables'
import { isMobile } from 'src/utils/isMobile'

import { AllTribers } from 'src/components/AdminPanel/Members/AllTribers'
import {
  $pendingUsersCount,
  $pendingTribersCount,
  $pendingUsersCountTrigger,
  $pendingTribersCountTrigger,
  $showHiddenUsers,
  saveUsersInStore,
  savePendingUsersInStore,
  savePendingUsersCountInStore,
  savePendingTribersCountInStore,
  resetShowHiddenUsersCount,
  $pendingUsersTrigger
} from '../../../state/effector/users/users.store'
import { PendingWhitelist } from '../../../components/AdminPanel/Members/PendingWhitelist'
import { MemberModal } from '../../../components/AdminPanel/Members/MemberModal'
import apiService from 'src/services/api'
import { TabStyled } from 'src/components/AdminPanel/Tabs/styles'
import { useAccessController } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/state/effector/users/user.types'

enum ETabTypes {
  allTribers = 'allTribers',
  pendingWhitelist = 'pendingWhitelist',
  pendingTribers = 'pendingTribers'
}

const Members = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryTab: ETabTypes = searchParams.get('tab') as ETabTypes

  const { isAllowed: isAllowedEditorUp } = useAccessController(UserRoles.Editor)

  const [tab, setTab] = useState<ETabTypes>((isAllowedEditorUp && queryTab) || ETabTypes.allTribers)

  const mobile = isMobile()
  const pendingUsersCount = useStore($pendingUsersCount)
  const pendingTribersCount = useStore($pendingTribersCount)
  const pendingUsersCountTrigger = useStore($pendingUsersCountTrigger)
  const pendingTribersCountTrigger = useStore($pendingTribersCountTrigger)
  const pendingUsersTrigger = useStore($pendingUsersTrigger)
  const showHiddenUsers = useStore($showHiddenUsers)

  const handleGetPendingTribersUsersCount = async () => {
    await apiService.getPendingUsersCount(showHiddenUsers.pendingTribers).then(({ data }) => {
      savePendingTribersCountInStore(data)
    })
  }

  useEffect(() => {
    handleGetPendingTribersUsersCount()
  }, [showHiddenUsers.pendingTribers, pendingTribersCountTrigger])

  useEffect(() => {
    return () => {
      resetShowHiddenUsersCount()
    }
  }, [])

  const handleGetPendingWhitelistUsersCount = async () => {
    await apiService
      .getPendingWhitelistUsersCount(showHiddenUsers.pendingWhitelist)
      .then(({ data }) => {
        savePendingUsersCountInStore(data)
      })
  }

  useEffect(() => {
    handleGetPendingWhitelistUsersCount()
  }, [showHiddenUsers.pendingWhitelist, pendingUsersCountTrigger, pendingUsersTrigger])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: ETabTypes) => {
    setTab(newValue)
    saveUsersInStore(null)
    savePendingUsersInStore(null)
    setSearchParams({ tab: newValue })
  }

  return (
    <Box sx={{ minHeight: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '25px',
          marginTop: mobile ? '25px' : 0
        }}
      >
        <Typography
          color={colors.$secondary}
          sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300, marginRight: '20px' }}
          variant="subtitle1"
        >
          Members
        </Typography>
        <AddMemberWrapper>
          <MemberModal type="add" />
        </AddMemberWrapper>
      </Box>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            variant="scrollable"
            aria-label="lab API tabs"
            onChange={handleChangeTab}
            sx={{
              height: '25px',
              '.MuiTab-textColorPrimary.Mui-selected': {
                color: '#0B1730',
                '&: hover': {
                  color: '#0B1730'
                }
              },
              '.MuiTab-textColorPrimary': {
                color: '#8B8E95',
                letterSpacing: 'normal',
                transition: '.3s',
                '&: hover': {
                  color: colors.$primary2
                }
              }
            }}
            TabIndicatorProps={{
              sx: {
                height: 4,
                borderRadius: 2
              }
            }}
          >
            <TabStyled
              label={'All Tribers'}
              value={ETabTypes.allTribers}
              sx={{
                marginRight: mobile ? '10px' : '20px',
                fontWeight: tab === ETabTypes.allTribers ? 700 : 400
              }}
            />
            <TabStyled
              label={`Pending Whitelist (${pendingUsersCount})`}
              value={ETabTypes.pendingWhitelist}
              sx={{
                margin: mobile ? '0 10px' : '0 20px',
                fontWeight: tab === ETabTypes.pendingWhitelist ? 700 : 400,
                display: isAllowedEditorUp ? 'auto' : 'none'
              }}
            />
            <TabStyled
              label={`Pending Tribers (${pendingTribersCount})`}
              value={ETabTypes.pendingTribers}
              sx={{
                marginLeft: mobile ? '10px' : '20px',
                fontWeight: tab === ETabTypes.pendingTribers ? 700 : 400
              }}
            />
          </TabList>
        </Box>
        <TabPanel value={ETabTypes.allTribers} sx={{ padding: '0' }}>
          <AllTribers />
        </TabPanel>
        <TabPanel
          value={ETabTypes.pendingWhitelist}
          sx={{ padding: '0', display: isAllowedEditorUp ? 'auto' : 'none' }}
        >
          <PendingWhitelist />
        </TabPanel>
        <TabPanel value={ETabTypes.pendingTribers} sx={{ padding: '0' }}>
          <AllTribers type="pending" />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default Members

const AddMemberWrapper = styled('div')`
  width: 188px;
`
