import { useEffect, useMemo } from 'react'
import { redirect } from 'react-router-dom'

import { EWhitelistStatuses } from 'src/state/response/response.types'
import { $user } from 'src/state/effector/users/users.store'
import { useStore } from 'effector-react'

export const MyAliases = () => {
  const user = useStore($user)

  const allowToMyAliases = useMemo(() => {
    const isApprovedWhitelist = user?.whiteListStatus === EWhitelistStatuses.ACCEPTED
    return isApprovedWhitelist
  }, [user?.whiteListStatus])

  useEffect(() => {
    if (!allowToMyAliases) {
      redirect('/')
    } else {
      if (window.Xype?.mount) {
        window.Xype.mount()
      } else {
        setTimeout(() => window.Xype?.mount && window.Xype.mount(), 500)
      }
    }
  }, [allowToMyAliases])

  return null
}
