import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { CircularProgress, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import { colors } from '../../../utils/colorVariables'
import { isMobile } from '../../../utils/isMobile'
import { TabStyled } from '../Tabs/styles'
import { ECountry } from '../../../state/effector/users/user.types'
import apiService from '../../../services/api'
import { RewardsTable } from './rewards-table'

import { ResponseWithPagination } from '../../../state/response/response.types'
import { ISalesTransaction } from '../../../state/effector/token/token.types'
import { TablePagination } from '../Table/Pagination'
import EmptyStateBox from '../Members/EmptyStateBox'
import { Filters } from './filters'
import { numberFormatter } from '../../../utils/numberFormatter'

const OFFSET_VALUE = 25
let timer = null as any

export const Rewards = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryTab: ECountry = searchParams.get('tab') as ECountry
  const queryEvent: string = searchParams.get('event') as string

  const [tab, setTab] = useState(queryTab || ECountry.NonUS)
  const [page, setPage] = useState(1)
  const [rewards, setRewards] = useState<any>(null)
  const [filters, handleFilters] = useState({
    walletAddress: '',
    eventName: queryEvent || 'all',
    executionType: 'all'
  })

  const mobile = isMobile()

  const getAllRewards = async () => {
    try {
      const validFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(value && value !== 'all' && { [key]: value })
        }),
        {}
      )
      const res = await apiService.getAllRewards({
        page,
        offset: OFFSET_VALUE,
        country: tab,
        ...validFilters
      })
      setRewards(res.data)
    } catch (e) {}
  }

  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      getAllRewards()
    }, 250)
  }, [tab, page, filters])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: ECountry) => {
    setTab(newValue)
    handleFilters((state) => ({ ...state, eventName: 'all' }))
    setPage(1)
    setSearchParams({ tab: newValue })
  }

  const onFiltersChange = (callback: any) => {
    setPage(1)
    handleFilters(callback)
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const onExport = async () => {
    const validFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value && value !== 'all' && { [key]: value })
      }),
      {}
    )
    const res = await apiService.getAllRewards({
      page,
      offset: rewards?.totalItems || OFFSET_VALUE,
      country: tab,
      ...validFilters
    })

    const data: ResponseWithPagination<ISalesTransaction[]> = res?.data

    const rows = [
      [
        'Date of reward',
        'Hash of txn',
        `Member's name`,
        `Member's wallet`,
        `Member's country`,
        'Currency',
        'Reward',
        `Sender's name`,
        `Sender's wallet`,
        'Event name',
        'Execution'
      ]
    ]

    data.items?.forEach((item: any) => {
      const row = [
        moment(item.createdAt).utc().format('YYYY-MM-DD'),
        item.txHash || '-',
        item.user?.name || '-',
        item.user?.ethAddress || '-',
        item.user?.country ? (item.user?.country === 'US' ? 'US' : 'International') : '-',
        item.currency,
        numberFormatter(Number(item.reward.replaceAll(',', '')), 2),
        item.sourceName || '-',
        item.sourceOfMoney || '-',
        item.eventName,
        item.executionType || '-'
      ]

      rows.push(row)
    })

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `rewards-${tab === 'US' ? 'us' : 'international'}-${moment().format('DD-MM-YYYY')}.csv`
    )
    document.body.appendChild(link)

    link.click()
  }

  const renderTabContent = () => (
    <>
      {rewards?.items?.length ? (
        <>
          <Box>
            <RewardsTable items={rewards?.items || []} />
          </Box>
          <TablePagination
            response={rewards}
            handleChange={handleChangePagination}
            page={page}
            onExport={onExport}
          />
        </>
      ) : (
        <EmptyStateBox message="No records found" />
      )}
    </>
  )

  return (
    <Box sx={{ minHeight: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '25px',
          marginTop: mobile ? '25px' : 0
        }}
      >
        <Typography
          color={colors.$secondary}
          sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300, marginRight: '20px' }}
          variant="subtitle1"
        >
          Rewards
        </Typography>
      </Box>
      {rewards ? (
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'LightGray' }}>
            <TabList
              aria-label="lab API tabs"
              onChange={handleChangeTab}
              sx={{
                height: '25px',
                '.MuiTab-textColorPrimary.Mui-selected': {
                  color: '#0B1730',
                  '&: hover': {
                    color: '#0B1730'
                  }
                },
                '.MuiTab-textColorPrimary': {
                  color: '#8B8E95',
                  letterSpacing: 'normal',
                  transition: '.3s',
                  '&: hover': {
                    color: colors.$primary2
                  }
                }
              }}
              TabIndicatorProps={{
                sx: {
                  height: 4,
                  borderRadius: 2
                }
              }}
            >
              <TabStyled
                label="International Rewards"
                value={ECountry.NonUS}
                sx={{
                  marginRight: mobile ? '10px' : '20px',
                  fontWeight: tab === ECountry.NonUS ? 700 : 400
                }}
              />
              <TabStyled
                label="US Rewards"
                value={ECountry.US}
                sx={{
                  marginLeft: mobile ? '10px' : '20px',
                  fontWeight: tab === ECountry.US ? 700 : 400
                }}
              />
            </TabList>
          </Box>
          <Box mt={4} />
          <Filters filters={filters} handleFilters={onFiltersChange} />
          <TabPanel value={ECountry.NonUS} sx={{ padding: '0' }}>
            {renderTabContent()}
          </TabPanel>
          <TabPanel value={ECountry.US} sx={{ padding: '0' }}>
            {renderTabContent()}
          </TabPanel>
        </TabContext>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
