import React from 'react'

import { networks } from 'src/constants/currency'

import useMetaMask from './useMetaMask'
import { NftType } from 'src/types'

export const useFundingNft = () => {
  const { chainId } = useMetaMask()

  const funding = React.useMemo(() => {
    return networks[`${chainId}` as keyof typeof networks].networkAddresses.NftTypes.find(
      (nft) => nft.symbol === 'SXT'
    ) as NftType
  }, [chainId])

  return funding
}
