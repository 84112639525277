import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'effector-react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import GridViewIcon from '@mui/icons-material/GridView'

import { isMobile, isTablet } from 'src/utils/isMobile'
import {
  $isMaintenanceModeActive,
  $isShowNavigation,
  setIsShowNavigation
} from 'src/state/effector/store'

import {
  NavigationWrapper,
  MenuWrapper,
  NavListWrapper,
  NavLinkWrapper,
  Footer,
  NavItemText,
  SwitchContainerSm,
  SwitchContainer,
  LinkOverrides
} from './styles'

import PeopleOutlinedIcon from '../../../assets/people_outlined_icon.svg'
import SalesIcon from '../../../assets/sales_basket.svg'
import OverviewIcon from '../../../assets/overview_icon.svg'
import SecurityIcon from '../../../assets/security_icon.svg'
import AssignmentIcon from '../../../assets/assignment_icon.svg'
import RewardsIcon from '../../../assets/rewards.png'
import AirdropsIcon from '../../../assets/airdrop.svg'

import { UserRoles } from 'src/state/effector/users/user.types'
import { $user } from 'src/state/effector/users/users.store'
import { MaintenanceModeSwitcher } from '../MaintenanceMode/MaintenanceModeSwitcher'

interface NavList {
  name: string
  path: string
  icon: JSX.Element
  isNotAllowed?: (role: UserRoles) => boolean
}

const navList: NavList[] = [
  {
    name: 'Dashboard',
    path: '/admin/dashboard',
    icon: <GridViewIcon />
  },
  {
    name: 'Members',
    path: '/admin/members',
    icon: <img src={PeopleOutlinedIcon} />
  },
  {
    name: 'Overview',
    path: '/admin/overview',
    icon: <img src={OverviewIcon} />,
    isNotAllowed: (role: UserRoles) => role === UserRoles.Editor
  },
  {
    name: 'Sales',
    path: '/admin/sales',
    icon: <img src={SalesIcon} />,
    isNotAllowed: (role: UserRoles) => role === UserRoles.Editor
  },
  {
    name: 'Rewards',
    path: '/admin/rewards',
    icon: <img src={RewardsIcon} />,
    isNotAllowed: (role: UserRoles) => role === UserRoles.Editor
  },
  {
    name: 'Airdrops',
    path: '/admin/airdrops',
    icon: <img src={AirdropsIcon} />,
    isNotAllowed: (role: UserRoles) => [UserRoles.Viewer, UserRoles.Editor].includes(role)
  },
  {
    name: 'Team',
    path: '/admin/team',
    icon: <img src={SecurityIcon} />,
    isNotAllowed: (role: UserRoles) => role === UserRoles.Viewer
  },
  {
    name: 'Logs',
    path: '/admin/logs',
    icon: <img src={AssignmentIcon} />
  }
]

const Navigation: React.FC = () => {
  const [isShowFullMenu, setIsShowFullMenu] = useState(true)

  const isShowNavigation = useStore($isShowNavigation)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  const mobile = isMobile()
  const tablet = isTablet()

  const user = useStore($user)
  const userRole = user?.role || UserRoles.Viewer

  const handleMenu = () =>
    mobile ? setIsShowNavigation(!isShowFullMenu) : setIsShowFullMenu((prev) => !prev)

  const isShowFooter = isShowFullMenu || mobile

  const currentYear = useMemo(() => {
    return new Date().getFullYear()
  }, [])

  useEffect(() => {
    if (tablet) setIsShowFullMenu(false)
    else setIsShowFullMenu(true)
  }, [tablet])

  useEffect(() => {
    setIsShowNavigation(false)
  }, [mobile])

  return (
    <NavigationWrapper
      isFullMode={isShowFullMenu}
      mobile={mobile}
      isShow={isShowNavigation}
      isMaintenanceModeActive={isMaintenanceModeActive}
    >
      <MenuWrapper onClick={handleMenu} isFullMode={isShowFullMenu} to="#">
        <MenuIcon fontSize="small" />
        <NavItemText isFullMode={isShowFullMenu}>
          <Box display="flex" alignItems="center">
            sixthsociety
          </Box>
        </NavItemText>
      </MenuWrapper>

      <NavListWrapper isFullMode={isShowFullMenu}>
        {navList.length
          ? navList.map((item, idx) =>
              item.isNotAllowed && item.isNotAllowed(userRole) ? null : (
                <NavLinkWrapper
                  key={`${item.name}-${idx}`}
                  to={item.path}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  isFullMode={isShowFullMenu}
                  onClick={() => mobile && setIsShowNavigation(false)}
                >
                  <div className="icon">{item.icon}</div>
                  <NavItemText isFullMode={isShowFullMenu}>{item.name}</NavItemText>
                </NavLinkWrapper>
              )
            )
          : null}
      </NavListWrapper>
      {!isShowFullMenu && (
        <SwitchContainerSm>
          <MaintenanceModeSwitcher />
        </SwitchContainerSm>
      )}

      {isShowFooter ? (
        <Footer>
          <div>
            <SwitchContainer>
              <MaintenanceModeSwitcher isFullMode={isShowFullMenu} />
            </SwitchContainer>
            <div>
              <div>© {currentYear} Sixth Society at Block</div>
              <div>Prime Ltd.</div>
            </div>
            <div>
              <LinkOverrides to={'/privacy-policy'} target="_blank">
                <div>{`Privacy & Cookies Policy`}</div>
              </LinkOverrides>
            </div>
          </div>
        </Footer>
      ) : null}
    </NavigationWrapper>
  )
}

export default Navigation
