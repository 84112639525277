import { Link, NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { colors } from 'src/utils/colorVariables'

interface StylesProps {
  isFullMode: boolean
  mobile?: boolean
  isShow?: boolean
  isMaintenanceModeActive?: boolean
}

const navigationWidth = '272px'
const navigationWidthSm = '72px'

export const NavigationWrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop)
})<StylesProps>`
  display: flex;
  transition-duration: 0.5s;
  height: calc(
    100vh - ${({ isMaintenanceModeActive }) => (isMaintenanceModeActive ? '37px' : '0px')}
  );

  width: 100%;
  max-width: ${({ mobile }) => (mobile ? '100%' : navigationWidth)};
  min-width: ${navigationWidth};
  flex-direction: column;
  background-color: ${colors.$primaryDarkLight};
  padding-top: ${({ mobile }) => (mobile ? '30px' : '46px')};
  position: ${({ mobile }) => (mobile ? 'fixed' : 'default')};
  z-index: 1300;
  overflow: auto;
  @media screen and (max-width: 599px) {
    transform: ${({ isShow }) => (!isShow ? 'translateX(-100%)' : 'translateX(0)')};
  }

  ${({ isFullMode }) =>
    !isFullMode &&
    `
    min-width: ${navigationWidthSm};
    width: ${navigationWidthSm};
    
    span {
      // width: 0%;
      // transition-timing-function: ease-in-out;
    }
  `}
`

export const NavItemText = styled.span<StylesProps>`
  width: 100%;
  transition: font-weight 0s, width 0.5s;
  transition-timing-function: ease-in-out;
  overflow: hidden;

  ${({ isFullMode }) =>
    !isFullMode &&
    `
     width: 0%;
    transition-timing-function: ease-in-out;
  `}
`

export const NavListWrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop)
})<StylesProps>`
  margin-top: 75px;
  width: 100%;
  display: flex;
  justify-content: ${({ isFullMode }) => (isFullMode ? 'flex-start' : 'center')};
  align-items: ${({ isFullMode }) => (isFullMode ? 'flex-start' : 'center')};
  flex-direction: column;

  a {
    font-weight: 400;
    transition: font-weight 0s, translate 0.5s, padding-left 0.5s;
  }

  .active {
    opacity: 1;
    border-left: 3px solid white;
    font-weight: 700;

    svg,
    img {
      margin-left: -3px;
    }
  }
`

export const NavItem = styled(NavLink, {
  shouldForwardProp: (prop) => isPropValid(prop)
})<StylesProps>`
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  height: 22px;
  transition-duration: 0.5s;

  padding-left: ${({ isFullMode }) => (isFullMode ? '40px' : '24px')};
  padding-right: ${({ isFullMode }) => (isFullMode ? '20px' : '0')};

  display: flex;
  align-items: center;

  opacity: 0.5;
  color: white;

  &:hover {
    opacity: 1;
  }
  span {
    margin-left: 10px;
  }
`

export const MenuWrapper = styled(NavItem)`
  opacity: 1;
`

export const NavLinkWrapper = styled(NavItem)`
  margin-bottom: 40px;
  .icon {
    width: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
  }
`

export const Footer = styled('div')`
  height: 100%;
  color: ${colors.$primaryLight};
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-weight: 300;
  font-size: 12px;
  padding-left: 40px;
  margin-bottom: 40px;
  line-height: 20px;
`

export const LinkOverrides = styled(Link)`
  background: transparent;
  color: ${colors.$primaryLight};
  border: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`

export const SwitchContainer = styled.div`
  padding-bottom: 24px;
  margin: 0 -40px;
  margin-bottom: 24px;
  padding-left: 40px;
  padding-right: 29px;
  opacity: 1 !important;
  border-bottom: 1px solid ${colors.$footer};
`
export const SwitchContainerSm = styled.div`
  padding-left: 19px;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
