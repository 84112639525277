import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { WhitelistTypes } from '../../../../state/response/response.types'
import { colors } from '../../../../utils/colorVariables'
import RadioButton from '../../../RadioButton'

interface SelectOption {
  label: 'Show Approved' | 'Show Pending' | 'Show Rejected' | 'Show All'
  value: WhitelistTypes | 'ALL'
}

const selectOptions: SelectOption[] = [
  {
    label: 'Show Approved',
    value: 'ACCEPTED'
  },
  {
    label: 'Show Pending',
    value: 'PENDING'
  },
  {
    label: 'Show Rejected',
    value: 'REJECTED'
  },
  {
    label: 'Show All',
    value: 'ALL'
  }
]

interface Props {
  value?: string
  onChange: (event: SelectChangeEvent) => void
}

const TribersSelect: React.FC<Props> = ({ value, onChange }) => {
  const getSelectedOption = () => {
    const item = selectOptions.find((itemSelect) => itemSelect.value === value)
    if (item) return item.label
    return <div style={{ color: colors.$grayLight }}>Show Approved</div>
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        labelId="item_type_label"
        value={value || ''}
        size="medium"
        onChange={onChange}
        displayEmpty
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 0
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          },
          '.MuiFormLabel-root div': {
            color: colors.$primaryLight + ' !important'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
        renderValue={() => (
          <InputLabel
            disableAnimation
            shrink={false}
            focused={value !== undefined}
            id="item_type_label"
            sx={{ cursor: 'pointer', fontSize: 16 }}
          >
            {getSelectedOption()}
          </InputLabel>
        )}
      >
        {selectOptions.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            <RadioButton checked={optionValue === value} />
          </MenuItemWrapper>
        ))}
      </Select>
    </FormControl>
  )
}

export default TribersSelect

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: ${colors.$black};
  }
`

// const StyledSelect = styled(Select)`
//   padding-top: 14px;
//   padding-bottom: 13px;
// `;
