import React from 'react'
import styled from '@emotion/styled'

import MenuIcon from '@mui/icons-material/Menu'

import AddressDropdown from 'src/components/Header/AddressDropdown'
import { isMobile } from 'src/utils/isMobile'
import { setIsShowNavigation } from 'src/state/effector/store'

const Header: React.FC = () => {
  const mobile = isMobile()

  const handleShow = () => setIsShowNavigation(true)

  return (
    <HeaderWrapper mobile={mobile}>
      {mobile ? <MenuIcon fontSize="small" onClick={handleShow} /> : null}
      <AddressDropdown />
    </HeaderWrapper>
  )
}

export default Header

interface StylesProps {
  mobile: boolean
}

const HeaderWrapper = styled('div')<StylesProps>`
  display: flex;
  justify-content: ${({ mobile }) => (mobile ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: ${({ mobile }) => (mobile ? '0 16px' : '25px 44px')};
  height: 88px;
  border-bottom: ${({ mobile }) => (mobile ? '0.5px solid #c9c7c7' : '1px solid white')};
`
