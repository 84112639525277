import React from 'react'
import styled from '@emotion/styled'

import { colors } from '../../utils/colorVariables'
import ErrorIcon from 'src/assets/error_icon.svg'

interface Props {
  message?: string
  isError?: boolean
  noIcon?: boolean
  // id: string;
}

const ErrorMessage: React.FC<Props> = ({ message, isError, noIcon }) =>
  isError && message ? (
    <ErrorMessageWrapper>
      {noIcon ? null : <img src={ErrorIcon} alt="Error Icon" />}
      {message || null}
    </ErrorMessageWrapper>
  ) : (
    <></>
  )

export default ErrorMessage

const ErrorMessageWrapper = styled('div')`
  margin-top: 10px;
  color: ${colors.$error};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: 400;
  font-size: 12px;

  img {
    margin-right: 5px;
  }
`
