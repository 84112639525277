import React, { useEffect, useState } from 'react'
import { css, TableContainer, TextField, Tooltip } from '@mui/material'
import moment from 'moment/moment'
import styled from '@emotion/styled'
import { Box } from '@mui/system'

import { colors } from 'src/utils/colorVariables'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/LoadingIndicator'
import pendingIcon from 'src/assets/pending.png'
import errorIcon from 'src/assets/error-filled.png'
import successIcon from 'src/assets/success-filled.png'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import { TablePagination } from '../../Table/Pagination'
import { DetailsPopup } from '../details-popup'
import { TextFieldPrimary } from 'src/components/TextField'
import { toast } from 'react-toastify'
import { IEvent } from 'src/types'
import { ResponseWithPagination } from 'src/state/response/response.types'

const headerData: string[] = ['Date', 'Name', 'Region', 'Budget', 'Status', '']

const statusIcon = {
  Done: <img src={successIcon} alt="successIcon" />,
  InProgress: <img src={pendingIcon} alt="pendingIcon" />,
  Failed: <img src={errorIcon} alt="errorIcon" />
} as Record<string, JSX.Element>

const statusLabel = {
  InProgress: 'Going',
  Failed: 'Failed',
  Done: 'Done'
} as Record<string, string>

interface Props {
  events: ResponseWithPagination<IEvent[]>
  handleChangePagination: (event: React.ChangeEvent<unknown>, newPage: number) => void
  fetchEvents: () => Promise<any>
}

export const EventsHistory = ({ events, handleChangePagination, fetchEvents }: Props) => {
  const [event, handleEvent] = useState<any>(null)
  const [notes, setNotes] = useState<Record<number, string>>({})
  const [calculations, handleCalculations] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!events.items || Object.keys(notes).length) return
    const initialNotes = {} as Record<number, string>
    events.items.forEach((event) => {
      initialNotes[event.id] = event.note || ''
    })
    setNotes(initialNotes)
  }, [events.items, notes])

  const countryData = {
    International: {
      icon: (
        <img
          src={internationalIcon}
          alt="internationalIcon"
          style={{ marginRight: '10px', height: '18px' }}
        />
      ),
      label: 'Int'
    },
    US: {
      icon: (
        <img src={usFlagIcon} alt="usFlagIcon" style={{ marginRight: '10px', height: '18px' }} />
      ),
      label: 'US'
    },
    all: { icon: null, label: 'All' }
  } as Record<string, { icon: JSX.Element | null; label: string }>

  const onCloseDetails = () => {
    handleEvent(null)
  }

  const openDetails = async (data: any) => {
    try {
      setIsLoading(true)
      const res = await apiService.getAirdropEvent(data.id)
      handleEvent({
        ...res.data,
        isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no'
      })
      handleCalculations({
        budget: res.data.budget,
        calculationPeriod: { start: res.data.startDate, end: res.data.endDate },
        membersCount: res.data.members.length,
        totalPoints: res.data.totalPoints,
        pointAmount: res.data.pointAmount
      })
    } catch (error) {
      console.log('log => error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const saveNotes = async (id: number, initial: string | null) => {
    try {
      if ((notes[id] || '') === (initial || '')) return
      setIsLoading(true)
      await apiService.saveEventNote(id, notes[id])
      await fetchEvents()
      toast.success('Successfully saved notes')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {event && (
        <DetailsPopup
          onlyView
          handleClose={onCloseDetails}
          calculations={calculations}
          draftId={null}
          event={event}
        />
      )}
      <Header id="template-history">History</Header>
      {events?.items?.length > 0 ? (
        <>
          <TableContainer>
            <Table
              aria-label="customized table"
              sx={{ minWidth: '720px', border: '1px solid #E8E8E8' }}
            >
              <HeaderRow rowTemplate={rowTemplate}>
                {headerData.map((header) => (
                  <CellPrimary key={header}>{header}</CellPrimary>
                ))}
              </HeaderRow>
              <>
                {events.items.map((row) => (
                  <StyledBodyRow key={row.id} rowTemplate={rowTemplate}>
                    <CellPrimary>
                      <TowRowsCell>
                        <Tooltip
                          title={
                            <>
                              {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                              <br />
                              {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                            </>
                          }
                          placement="top"
                          arrow
                        >
                          <div>{moment(row.createdAt).utc().format('D MMM')}</div>
                        </Tooltip>
                        <Tooltip title={row.user.name} placement="top" arrow>
                          <div>by {row.user.name}</div>
                        </Tooltip>
                      </TowRowsCell>
                    </CellPrimary>
                    <CellPrimary>
                      <TowRowsCell>
                        <Tooltip title={row.name} placement="top" arrow>
                          <div className="airdrop-name">{row.name} </div>
                        </Tooltip>

                        <div>
                          {row.templateId ? `Template #${row.templateId}` : 'One Time Airdrop'}
                        </div>
                      </TowRowsCell>
                    </CellPrimary>
                    <CellPrimary>
                      <Box display="flex" alignItems="center">
                        {countryData[row.region].icon}
                        {countryData[row.region].label}
                      </Box>
                    </CellPrimary>
                    <CellPrimary>
                      {(+row.budget).toLocaleString('en-US', { maximumFractionDigits: 6 })} USDC
                    </CellPrimary>

                    <CellPrimary>
                      <Box display="flex" alignItems="center">
                        {statusIcon[row.status]}&nbsp;{statusLabel[row.status]}
                      </Box>
                    </CellPrimary>
                    <CellPrimary>
                      <TextFieldPrimary
                        sx={{ marginTop: '-5px' }}
                        inputProps={{ style: { paddingRight: '14px' } }}
                        value={notes[row.id] || ''}
                        // disabled={isLoading}
                        onKeyDown={(e) => {
                          if (isLoading) return
                          if (e.key === 'Enter') {
                            e.stopPropagation()
                            e.preventDefault()
                            saveNotes(row.id, row.note)
                          }
                        }}
                        onBlur={(e) => {
                          if (isLoading) return
                          e.stopPropagation()
                          e.preventDefault()
                          saveNotes(row.id, row.note)
                        }}
                        onChange={(e) => {
                          if (isLoading) return
                          let note = e.target.value
                          if (note.length > 20) {
                            note = e.target.value.slice(0, 20)
                          }
                          setNotes((state) => ({ ...state, [row.id]: note }))
                        }}
                        size="small"
                        multiline
                        placeholder="Note"
                      />
                    </CellPrimary>
                    <CellPrimary onClick={() => openDetails(row)}>
                      <Details>Details</Details>
                    </CellPrimary>
                  </StyledBodyRow>
                ))}
              </>
            </Table>
          </TableContainer>
          <TablePagination
            response={events}
            handleChange={handleChangePagination}
            page={events.page}
          />
        </>
      ) : (
        <NoRecords>No records </NoRecords>
      )}
    </>
  )
}

const StyledBodyRow = styled(BodyRow)`
  background-color: white !important;
`
const rowTemplate = css`
  * {
    color: ${colors.$secondary};
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px;
  }
  grid-template-columns:
    repeat(2, minmax(130px, 1fr)) 80px minmax(130px, 1fr) 100px minmax(130px, 1fr)
    80px;
`

const NoRecords = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 138%;
  color: ${colors.$secondary};
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.$borderSecondary};
`

const TowRowsCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  > div:last-of-type {
    color: ${colors.$primaryLight};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .airdrop-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Details = styled.div`
  color: ${colors.$blue} !important;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
`
const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.$secondary};
  margin-bottom: 24px;
  padding-top: 32px;
`
