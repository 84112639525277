import React from 'react'
import { Dropdown } from '../../Dropdown'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import apiService from '../../../services/api'
import { User } from '../../../state/effector/users/user.types'
import { toast } from 'react-toastify'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { colors } from '../../../utils/colorVariables'

interface Props {
  data: User
  callback?: () => void
  disabled?: boolean
}

export const HideUnhideFunctionality: React.FC<Props> = ({ data, callback, disabled }) => {
  const undoAction = async () => {
    await apiService.setHiddenUnhiddenUser({ userId: data.id, hidden: false }).then(() => {
      toast.success(<BoltToastMessage>User has been unhidden</BoltToastMessage>)
      if (callback) callback()
    })
  }

  const onClick = async () => {
    const content = (
      <BoltToastMessage>
        <div>User has been {data.hidden ? 'unhidden' : 'hidden'}</div>
        {!data.hidden && <UndoButton onClick={undoAction}>Undo action</UndoButton>}
      </BoltToastMessage>
    )

    await apiService.setHiddenUnhiddenUser({ userId: data.id, hidden: !data.hidden }).then(() => {
      toast.success(content)
      if (callback) callback()
    })
  }

  return (
    <Dropdown
      disabled={disabled}
      menuContent={
        <StyledBox
          onClick={onClick}
          display="flex"
          alignItems="center"
          justifyContent="start"
          px={3}
        >
          {data.hidden ? (
            <VisibilityOutlinedIcon fontSize="small" />
          ) : (
            <VisibilityOffOutlinedIcon fontSize="small" />
          )}
          <Box mr={1} />
          {data.hidden ? <div>Unhide</div> : <div>Hide</div>}
        </StyledBox>
      }
    >
      <IconWrapper>
        <MoreVertIcon fontSize="small" />
      </IconWrapper>
    </Dropdown>
  )
}

const BoltToastMessage = styled('div')`
  font-weight: 600;
`

const UndoButton = styled('div')`
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
`

const StyledBox = styled(Box)`
  cursor: pointer;
  &:hover {
    border-radius: 3px;
  }
`
const IconWrapper = styled('div')`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.$border};
  }

  svg {
    fill: ${colors.$blue};
  }
`
