import { isProdEnv } from '../utils/environments'

// Addresses for development and staging environments
const TREASURY_ADDRESS_DEV = '0xa1299aD63e9A4e7c1eBeE4ccce64c6b1051356fF'
const TREASURY_ADDRESS_US_DEV = '0xb2Bd8Fc4Aa591A7D4C27C8dB601ec2e05FBDF16E'

// Addresses only for prod environment
const TREASURY_ADDRESS_PROD = '0x3eB3a57c0590c99C18F2b0BcF9f91f282406fa85'
const TREASURY_ADDRESS_US_PROD = '0xb55504ed1812238F66fb7a43D1B350C0b1a8Ca39'

export const TREASURY_ADDRESS = isProdEnv ? TREASURY_ADDRESS_PROD : TREASURY_ADDRESS_DEV
export const TREASURY_ADDRESS_US = isProdEnv ? TREASURY_ADDRESS_US_PROD : TREASURY_ADDRESS_US_DEV
