import { Chip, styled } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import { AvailabilityInfoProps } from 'src/types/common.types'
import { colors } from 'src/utils/colorVariables'

interface Props {
  selectedNftIdx?: string
  nft?: AvailabilityInfoProps
  availableAmount?: string | number
  showAvailable?: boolean
}

const AvailableNftChip = ({ selectedNftIdx, showAvailable, availableAmount, nft }: Props) => {
  const [info, setInfo] = useState<AvailabilityInfoProps>()

  useEffect(() => {
    if (nft?.maxAmount) {
      setInfo({ ...nft, availableAmount })
    }
  }, [nft, selectedNftIdx, availableAmount])

  const label = showAvailable
    ? `Available: ${availableAmount}`
    : `${info?.sales} (Max ${info?.maxAmount})`
  const sx = useMemo(() => {
    if (showAvailable) {
      return availableAmount === 0
        ? { backgroundColor: colors.$grayLight }
        : { backgroundColor: colors.$successOrange }
    }
    return Number(info?.sales) >= Number(info?.maxAmount)
      ? { backgroundColor: colors.$grayLight }
      : { backgroundColor: colors.$successOrange }
  }, [availableAmount, info, showAvailable])

  return <ChipWrapper sx={sx} label={label} />
}

export default AvailableNftChip

const ChipWrapper = styled(Chip)`
  position: absolute;
  right: 40px;
  top: calc(50% - 10px);
  height: 20px;
  color: ${colors.$white};

  @media (max-width: 599px) {
    position: static;
  }
`
