import styled from '@emotion/styled'
import { TableContainer, Typography, Tooltip } from '@mui/material'
import { useStore } from 'effector-react'
import moment from 'moment'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { $logs } from 'src/state/effector/history/logs.store'
import { ILog } from 'src/state/effector/history/logs.types'
import { colors } from 'src/utils/colorVariables'
import { isMobile } from 'src/utils/isMobile'
import { truncateAddress } from 'src/utils/truncateAddress'
import {
  AddressWrapper,
  CopiedMessageUpdated,
  CopyIconWrapper,
  MemberWrapper,
  renderNameWithTruncate,
  Table,
  HeaderRow,
  BodyRow,
  CellPrimary
} from '../Table'
import { Box, css } from '@mui/system'
import { upperFirstLatter } from '../../../utils/string'

interface Copied {
  [key: string]: boolean
}

const headerData: string[] = ['Date', 'Team member', 'Role', 'Action performed']

export const LogsTable: React.FC = () => {
  const [copied, setCopied] = useState<Copied>({})

  const logs = useStore($logs)
  const mobile = isMobile()

  const onCopy = (address: string) => {
    setCopied((prev) => ({ ...prev, [address]: true }))
    setTimeout(() => setCopied((prev) => ({ ...prev, [address]: false })), 1000)
  }

  const getTruncatedAddress = (address: string) => truncateAddress(address, 2)

  const renderAddressesRow = (address: string, name: string, id: string) => (
    <span>
      <AddressWrapper>
        <div className="text-to-copy">{getTruncatedAddress(address)}</div>
        <CopyIconWrapper>
          <CopyToClipboard onCopy={() => onCopy(id)} text={address}>
            <StyledContentCopyIcon
              sx={{ color: '#A4B4D2', cursor: 'pointer', fontSize: mobile ? '18px' : '22px' }}
            />
          </CopyToClipboard>
          {copied[id] ? <CopiedMessageUpdated>Copied!</CopiedMessageUpdated> : ''}
        </CopyIconWrapper>
      </AddressWrapper>

      {name ? <MemberWrapper>{renderNameWithTruncate(name)}</MemberWrapper> : null}
    </span>
  )

  const renderActionRow = (action: string, idx: string) => {
    // Regex for finding ethAddress
    const regex = /(\b0x[a-fA-F0-9]{40}\b)/g
    const actionWithoutNull = action.replace(' null ', ' ')
    const ethAddresses = actionWithoutNull.match(regex)
    const textParts = actionWithoutNull.split(regex).filter((item) => !item.match(regex))

    return textParts.map((text, id) => {
      const copyLink = ethAddresses?.at(id) ? (
        <Typography display="inline">
          <Box display="inline-block" justifyContent="flex-start" alignItems="center">
            <StyledCopyBox mx="2px">
              {renderAddressesRow(ethAddresses.at(id) as string, '', idx + id.toString())}
            </StyledCopyBox>
          </Box>
        </Typography>
      ) : (
        <></>
      )

      return (
        <>
          {text} {copyLink}
        </>
      )
    })
  }

  return (
    <TableContainer>
      <Table aria-label="customized table" sx={{ minWidth: '800px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {logs?.items
            ? logs?.items.map((row: ILog, idx) => (
                <BodyRow key={row.id} rowTemplate={rowTemplate}>
                  <CellPrimary>
                    <Tooltip
                      title={
                        <>
                          {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                          <br />
                          {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <div>{moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)</div>
                    </Tooltip>
                  </CellPrimary>
                  <CellPrimary>
                    {renderAddressesRow(row.teamMember.ethAddress, row.teamMember.name, `${idx}-1`)}
                  </CellPrimary>
                  <CellPrimary>{upperFirstLatter(row?.teamMember?.role || '')}</CellPrimary>
                  <CellPrimary>{renderActionRow(row.action, `${idx}-2`)}</CellPrimary>
                </BodyRow>
              ))
            : null}
        </>
      </Table>
    </TableContainer>
  )
}

const rowTemplate = css`
  grid-template-columns: minmax(230px, 245px) minmax(150px, 200px) 125px 1fr;
`

const StyledContentCopyIcon = styled(ContentCopyIcon)`
  transition: 0.3s;

  &:hover {
    color: ${colors.$lightBlue};
  }
`
const StyledCopyBox = styled(Box)`
  .text-to-copy {
    margin-right: 4px !important;
  }
`
