import { useCallback } from 'react'

import useMetaMask from './useMetaMask'
import { networks } from 'src/constants/currency'
import { toast } from 'react-toastify'
import { useNft } from './useContracts'

export const useGetHaveLegacyNft = () => {
  const { chainId } = useMetaMask()

  const legacyAddress =
    networks[chainId as keyof typeof networks]?.networkAddresses.PrevLegacyNftAddress
  const nftContract = useNft(legacyAddress, 'ERC721')

  const getHaveLegacyNft = useCallback(
    async (ethAddress?: string) => {
      try {
        const balance = await nftContract.balanceOf(ethAddress)

        return Boolean(Number(balance))
      } catch (error) {
        console.error(error)
        toast.error('Failed to get your previous legacy NFT.')
        return false
      }
    },
    [nftContract]
  )

  return getHaveLegacyNft
}
