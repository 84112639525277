import React from 'react'
import { Box, Typography, Link, CircularProgress } from '@mui/material'
import useMetaMask from '../../hooks/useMetaMask'
import styled from '@emotion/styled'
import { colors } from '../../utils/colorVariables'
import { ButtonPrimary } from '../Button'
import metamask from '../../assets/metamask.svg'
import ethereum from '../../assets/ethereum.svg'
import pen from '../../assets/pen.svg'
import { isMobile } from '../../utils/isMobile'
import { useFetchToken } from '../../hooks/useFetchToken'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const MetamaskAuth = () => {
  const { connect } = useMetaMask()
  const mobile = isMobile()

  return (
    <BoxWrapper
      className="box-wrapper"
      sx={{
        maxWidth: 690,
        width: '100%',
        height: 295
      }}
    >
      <Typography
        variant={mobile ? 'h4' : 'subtitle1'}
        sx={{ color: colors.$footer, textAlign: 'center' }}
      >
        You need an Ethereum wallet to use Sixth Society
      </Typography>
      <Typography
        sx={{
          margin: '0 0 15px',
          color: colors.$primary,
          fontSize: mobile ? 10 : 12
        }}
        variant="h6"
      >
        Connect your wallet or create a new one
      </Typography>
      <ButtonPrimary
        sx={{ fontSize: 12, height: mobile ? 30 : 48 }}
        onClick={() => connect()}
        endIcon={<img src={metamask} alt="metamask" />}
      >
        Connect Metamask
      </ButtonPrimary>
    </BoxWrapper>
  )
}

export const SwitchNetwork = () => {
  const { switchNetwork } = useMetaMask()
  const mobile = isMobile()

  return (
    <BoxWrapper className="box-wrapper" sx={{ maxWidth: 925, width: '100%', height: 426 }}>
      <img src={ethereum} alt="ethereum" style={{ margin: '0 0 15px' }} />
      <Typography variant={mobile ? 'h4' : 'subtitle1'} sx={{ color: colors.$footer }}>
        You are on the wrong network
      </Typography>
      <Typography
        sx={{
          margin: '15px 0 30px',
          color: colors.$primary,
          fontWeight: 300,
          fontSize: mobile ? 10 : 12,
          textAlign: 'center'
        }}
        variant="h4"
      >
        Switch your wallet to Ethereum Mainnet or click the button below
      </Typography>
      <ButtonPrimary sx={{ maxWidth: 581, witdh: '100%' }} onClick={() => switchNetwork(1)}>
        ok
      </ButtonPrimary>
      {/* <ButtonPrimary
        sx={{ margin: "10px 0", maxWidth: 581, witdh: "100%" }}
        onClick={() => switchNetwork(42)}
      >
        switch to kovan testnet
      </ButtonPrimary> */}
    </BoxWrapper>
  )
}

export const InstallMetamask = () => {
  const mobile = isMobile()

  return (
    <BoxWrapper className="box-wrapper" sx={{ maxWidth: 690, width: '100%', height: 295 }}>
      <Typography
        variant={mobile ? 'h4' : 'subtitle1'}
        sx={{ color: colors.$footer, textAlign: 'center' }}
      >
        You need an Ethereum wallet to use Sixth Society
      </Typography>
      <Typography
        sx={{
          margin: '0 0 15px',
          color: colors.$primary,
          fontSize: mobile ? 10 : 12
        }}
        variant="h6"
      >
        Connect your wallet or create a new one
      </Typography>
      <Link
        href="https://metamask.io/"
        sx={{ width: '100%', textDecoration: 'none' }}
        target="_blank"
      >
        <ButtonPrimary
          sx={{ fontSize: 12, height: mobile ? 30 : 48 }}
          endIcon={<img src={metamask} alt="metamask" />}
        >
          Install Metamask
        </ButtonPrimary>
      </Link>
    </BoxWrapper>
  )
}

export const PendingSignature = () => {
  const mobile = isMobile()

  return (
    <BoxWrapper className="box-wrapper" sx={{ maxWidth: 610, width: '100%', height: 295 }}>
      <LoaderSignatureWrapper>
        <CircularProgress size="50px" sx={{ margin: '0 0 15px 0', color: colors.$successOrange }} />
      </LoaderSignatureWrapper>
      <Typography variant={mobile ? 'h4' : 'subtitle1'} sx={{ color: colors.$footer }}>
        Waiting for signature
      </Typography>

      <Typography
        sx={{
          margin: '0 0 15px',
          textAlign: 'center',
          color: colors.$primary,
          fontSize: mobile ? 10 : 12
        }}
        variant="h6"
      >
        Please open your Metamask wallet to sign in
      </Typography>

      {/* <Typography sx={{ margin: '0 0 15px', color: colors.$primary }} variant="h6">Please connect to Ethereum Mainnet in order to use the Sixth Society web-app.</Typography>
            <ButtonPrimary onClick={() => connect()} endIcon={<img src={metamask} alt="metamask" />}>Connect Metamask</ButtonPrimary> */}
    </BoxWrapper>
  )
}

export const RequestToSign = () => {
  // const { account, connect } = useMetaMask();
  const { fetchAuth } = useFetchToken()
  const mobile = isMobile()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const ACCEPTER_REDIRECT_PARAMS = ['/admin']

  const redirectTo = searchParams.get('redirectTo')

  const handleClickSign = async () => {
    await fetchAuth()

    if (redirectTo && ACCEPTER_REDIRECT_PARAMS.includes(redirectTo)) {
      // return navigate(redirectTo)
    }
  }

  return (
    <BoxWrapper className="box-wrapper" sx={{ maxWidth: 610, width: '100%', height: 295 }}>
      <Typography variant={mobile ? 'h4' : 'subtitle1'} sx={{ color: colors.$footer }}>
        Welcome to Sixth Society
      </Typography>
      <Typography
        sx={{
          margin: '0 0 15px',
          textAlign: 'center',
          color: colors.$primary,
          fontSize: mobile ? 10 : 12
        }}
        variant="h6"
      >
        By clicking Sign in and Enter and using Sixth Society, you agree to our Terms and Service
        and Privacy Policy.
      </Typography>
      <ButtonPrimary
        sx={{ fontSize: 12, height: mobile ? 30 : 48, width: 150 }}
        onClick={handleClickSign}
      >
        Sign In and Enter
      </ButtonPrimary>
    </BoxWrapper>
  )
}

const LoaderSignatureWrapper = styled('div')`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background: url(${pen}) no-repeat 50% 50%;
    background-size: 19px 19px;
    width: 50px;
    height: 50px;
  }
`

const BoxWrapper = styled(Box)`
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  box-shadow: ${colors.$boxShadow};

  @media (max-width: 599px) {
    margin: 15px 0;
  }
`
