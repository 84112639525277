import React from 'react'
import { FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { colors } from '../../../../utils/colorVariables'
import { ECountry, EIntroducerAddressTypes } from 'src/state/effector/users/user.types'

interface SelectOption {
  label: 'Mother Tribe (International)' | 'Mother Tribe (US)' | 'Specific Introducer'
  value: EIntroducerAddressTypes
}

const selectOptions: SelectOption[] = [
  {
    label: 'Mother Tribe (US)',
    value: EIntroducerAddressTypes.US
  },
  {
    label: 'Mother Tribe (International)',
    value: EIntroducerAddressTypes.NonUS
  },
  {
    label: 'Specific Introducer',
    value: EIntroducerAddressTypes.Specific
  }
]

interface Props {
  value: EIntroducerAddressTypes
  country: ECountry | null
  disabled?: boolean
  onChange: (event: SelectChangeEvent<EIntroducerAddressTypes>) => void
}

const TribersSelect: React.FC<Props> = ({ value, country, disabled, onChange }) => {
  const isDisable = (option: EIntroducerAddressTypes) => {
    switch (country) {
      case ECountry.NonUS:
        return option === EIntroducerAddressTypes.US

      case ECountry.US:
        return option === EIntroducerAddressTypes.NonUS

      default:
        return false
    }
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        labelId="item_type_label"
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        disabled={disabled}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          },
          '.Mui-disabled': {
            backgroundColor: colors.$disabledBg3
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {selectOptions.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue} disabled={isDisable(optionValue)}>
            {label}
          </MenuItemWrapper>
        ))}
      </Select>
    </FormControl>
  )
}

export default TribersSelect

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: ${colors.$black};
  }
`
