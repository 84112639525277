import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Menu } from '@mui/material'
import styled from '@emotion/styled'
import { colors } from 'src/utils/colorVariables'

interface Props {
  children: React.ReactElement
  menuContent: React.ReactElement
  closeMenuTrigger?: number
  disabled?: boolean
}

export const Dropdown: React.FC<Props> = ({ children, menuContent, disabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StyledDropdownBox position="relative" disabled={disabled}>
      <StyledChildrenBox onClick={handleClick} disabled={disabled}>
        {children}
      </StyledChildrenBox>
      <Menu
        id="dropdownMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 100
        }}
      >
        <StyledMenuContentBox onClick={handleClose}>{menuContent}</StyledMenuContentBox>
      </Menu>
    </StyledDropdownBox>
  )
}

interface IStyledProps {
  disabled?: boolean
}

const StyledDropdownBox = styled(Box)<IStyledProps>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

const StyledChildrenBox = styled('div')<IStyledProps>`
  svg {
    fill: ${({ disabled }) => (disabled ? `${colors.$disabledBg2} !important` : 'auto')};
  }
`

const StyledMenuContentBox = styled(Box)`
  padding: 0 4px;
`
