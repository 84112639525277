import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
  Link as MaterialLink
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { AppLogo } from '../Logo'
import { ButtonTransparent } from '../Button'
import styled from '@emotion/styled'
import MuiTheme from '../../theme'
import useMetaMask from '../../hooks/useMetaMask'
import { colors } from '../../utils/colorVariables'
import walletIcon from '../../assets/wallet-icon-gradient.svg'

import { isMobile } from '../../utils/isMobile'
import Balance from './Balance/Balance'
import AddressDropdown from './AddressDropdown'
import { Link, useNavigate } from 'react-router-dom'
import MobileNavigation from './MobileNavigation/MobileNavigation'
import { useSixthSocietyNFT } from '../../hooks/useContracts'
import { useStore } from 'effector-react'
import {
  $auth,
  $availableNft,
  $isBuddy,
  $isMaintenanceModeActive,
  saveNftCollection,
  $nftList,
  $activeNfts
} from '../../state/effector/store'
import NavItems from './MobileNavigation/NavItems'
import Chip from './Chip/Chip'
import { $user } from 'src/state/effector/users/users.store'
import { EWhitelistStatuses } from 'src/state/response/response.types'
import { useAccessController } from '../../hooks/useAccessController'
import { UserRoles } from '../../state/effector/users/user.types'
import { SUPPORTED_CHAIN, allowedAccounts } from '../../constants'

const Header = () => {
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false)

  const auth = JSON.parse((window as any).localStorage.getItem('auth'))
  const { account, chainId, connect } = useMetaMask()
  const { ethereum } = window as any

  const [myCollection, setMyCollection] = useState('')
  const sixthSocietyNFT = useSixthSocietyNFT()
  const store = useStore($availableNft)
  const $isBuddyStore = useStore($isBuddy)
  const mobile = isMobile()
  const authStore = useStore($auth)
  const user = useStore($user)
  const nftList = useStore($nftList)
  const activeNfts = useStore($activeNfts)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const nav = useNavigate()
  const isMetamaskInstalled = typeof ethereum !== 'undefined'

  const { isAllowed: isAllowedUserUp } = useAccessController(UserRoles.User, false, true)

  const { tribeToken } = authStore[account] || {}
  const isAuth = authStore[account]

  const showMyCollection = isMaintenanceModeActive ? isAllowedUserUp : true

  const getBalance = useCallback(async () => {
    try {
      if (!sixthSocietyNFT?.length) return

      let balance = 0

      for (const contract of sixthSocietyNFT) {
        const innerBalance = await contract.balanceOf(account)
        balance += Number(innerBalance)
      }

      saveNftCollection(Number(balance))
      setMyCollection(String(balance))
    } catch (error) {
      console.error('getBalance', error)
    }
  }, [account, sixthSocietyNFT])

  useEffect(() => {
    if (account) {
      getBalance()
    }
  }, [account, store, sixthSocietyNFT?.length])

  const allowToMyCollection: boolean = useMemo(() => {
    const isApprovedWhitelist = user?.whiteListStatus === EWhitelistStatuses.ACCEPTED

    return (
      !!($isBuddyStore && myCollection !== '0' && isApprovedWhitelist && activeNfts) ||
      allowedAccounts.includes(account?.toLowerCase())
    )
  }, [$isBuddyStore, myCollection, user?.whiteListStatus, activeNfts, account])

  const allowToMyAliases = useMemo(() => {
    const isApprovedWhitelist = user?.whiteListStatus === EWhitelistStatuses.ACCEPTED
    return isApprovedWhitelist
  }, [user?.whiteListStatus])

  const handleChipClick = useCallback(() => {
    if (allowToMyCollection) {
      nav('/my-collection')
      setIsOpenMobileNav(false)
    }
  }, [allowToMyCollection, nav])

  useEffect(() => {
    if (isOpenMobileNav) setIsOpenMobileNav(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile])

  const handleMyCollection =
    account && auth?.[account] && chainId === SUPPORTED_CHAIN ? (
      <Balance
        allowToMyCollection={allowToMyCollection}
        allowToMyAliases={allowToMyAliases}
        myCollection={myCollection}
      />
    ) : null

  const handleAddressButton = account ? (
    <AddressDropdown />
  ) : (
    <ButtonTransparentOverride
      onClick={() => connect()}
      sx={{
        color: colors.$footer,
        fontWeight: 400,
        textTransform: 'initial',
        fontSize: mobile ? 12 : 16,
        background: '#F8FAFC'
      }}
      width="auto"
      variant="outlined"
      border="false"
      boxshadow="true"
      startIcon={
        <img
          style={{
            width: mobile ? 15 : 20,
            height: mobile ? 15 : 20,
            margin: '0 5px 0 0'
          }}
          src={walletIcon}
          alt="metamask"
        />
      }
    >
      {!isMetamaskInstalled ? (
        <MaterialLink
          href="https://metamask.io/"
          sx={{ width: '100%', textDecoration: 'none' }}
          target="_blank"
        >
          Install Metamask
        </MaterialLink>
      ) : (
        'Connect wallet'
      )}
    </ButtonTransparentOverride>
  )

  return (
    <AppBarWrapper
      sx={{
        borderBottom: `2px solid ${MuiTheme.palette.grey[200]}`,
        zIndex: 2
      }}
    >
      <Container sx={{ justifyContent: 'space-between' }}>
        <Toolbar className={account ? 'header-toolbar' : 'header-toolbar-no-acc'} disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex' } }}
          >
            <Link to="/" style={{ paddingTop: mobile ? '8px' : '5px' }}>
              <AppLogo
                width={mobile && account ? '30px' : !mobile ? '180px' : '30px'}
                size={mobile ? 'small' : ''}
                logoView={mobile ? '' : 'dark'}
              />
            </Link>
          </Typography>
          <HeaderInfoWrapper className="header-info-wrapper">
            {isAuth && chainId === SUPPORTED_CHAIN && (
              <CollectionWrapper>{handleMyCollection}</CollectionWrapper>
            )}
            {isAuth && showMyCollection && chainId === SUPPORTED_CHAIN && (
              <StyledChipWrapper>
                <Chip
                  allowToMyCollection={allowToMyCollection}
                  handleClick={handleChipClick}
                  myCollection={myCollection}
                />
              </StyledChipWrapper>
            )}
            {handleAddressButton}
            {Boolean(user) && chainId === SUPPORTED_CHAIN && (
              <StyledNavButton onClick={() => setIsOpenMobileNav(true)}>
                <MenuIcon />
              </StyledNavButton>
            )}
          </HeaderInfoWrapper>
        </Toolbar>
      </Container>
      {chainId === SUPPORTED_CHAIN && (
        <MobileNavigation
          isOpen={isOpenMobileNav}
          setIsOpen={setIsOpenMobileNav}
          tribeToken={tribeToken}
          myCollection={myCollection}
        >
          <NavItems
            allowToMyCollection={allowToMyCollection}
            allowToMyAliases={allowToMyAliases}
            tribeToken={tribeToken}
            myCollection={myCollection}
            setIsOpen={setIsOpenMobileNav}
            handleChipClick={handleChipClick}
          />
        </MobileNavigation>
      )}
    </AppBarWrapper>
  )
}

export default Header

const CollectionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  @media (max-width: 780px) {
    display: none;
  }
`

const StyledNavButton = styled(Button)`
  display: none;
  @media (max-width: 780px) {
    display: flex;
    align-items: center;
    min-width: 45px;
  }
`

const StyledChipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  @media (min-width: 780px) {
    display: none !important;
  }
`

const ButtonTransparentOverride = styled(ButtonTransparent)`
  @media (max-width: 599px) {
    width: auto;
    padding: 5px 15px;
    letter-spacing: 0.5px;

    & .MuiButton-startIcon {
      margin-right: 2px;
    }
  }
`

const AppBarWrapper = styled(AppBar)`
  background-color: transparent;
  box-shadow: none;
  position: static;

  @media (max-width: 599px) {
    & .header-toolbar {
      flex-direction: row;
      align-items: stretch;
      & .header-info-wrapper {
        justify-content: space-between;
        align-items: center;

        & h3 {
          margin-left: 0;
        }
      }
    }

    & .header-toolbar-no-acc {
      flex-direction: unset;
    }
  }
`

const HeaderInfoWrapper = styled('div')`
  display: flex;
`
