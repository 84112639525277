import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, TableContainer, Tooltip, Typography } from '@mui/material'
import { css } from '@emotion/react'
import moment from 'moment'
import styled from '@emotion/styled'
import LoadingButton from '@mui/lab/LoadingButton'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import { AddressesCell } from '../../Table/AddressesCell'
import { colors } from '../../../../utils/colorVariables'
import { isMobile } from '../../../../utils/isMobile'
import apiService from '../../../../services/api'
import { ResponseWithPagination } from '../../../../state/response/response.types'
import { IBookNftRequestData } from '../../BookNFTModal/BookNFTModal.types'

import { ReviewUpgradeNFTModal } from './review-upgrade-nft-modal'
import { getNftName } from 'src/utils/nft.utils'
import useMetaMask from 'src/hooks/useMetaMask'

const headerData: string[] = ['Date', 'Member', 'Upgrade Request', 'Upgrade Price', '']

const OFFSET_VALUE = 10

export const PendingNftUpgrades = () => {
  const { chainId } = useMetaMask()
  const [pendingTransactions, setPendingTransactions] = useState<ResponseWithPagination<
    IBookNftRequestData[]
  > | null>(null)

  const [requestData, handleRequestData] = useState(null)
  const [offset, setOffset] = useState(OFFSET_VALUE)
  const [isLoading, setIsLoading] = useState(false)
  const mobile = isMobile()

  const handleShowMore = () => {
    if (!pendingTransactions?.nextPage) return
    setOffset((prev) => prev + OFFSET_VALUE)
  }

  const loadTransactions = (offsetValue: number) => {
    setIsLoading(true)
    apiService
      .getNftUpgradeRequests({
        offset: offsetValue,
        page: 1,
        status: 'Pending'
      })
      .then(({ data }) => {
        setPendingTransactions(data)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadTransactions(offset)
  }, [offset])

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" py={5}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            color={colors.$secondary}
            sx={{ fontSize: mobile ? 18 : 20, fontWeight: 500 }}
            variant="subtitle1"
            pb={3}
            mt={4}
          >
            NFT Upgrade Request ({pendingTransactions?.totalItems || 0})
          </Typography>
          <TableContainer>
            <Table aria-label="customized table" sx={{ minWidth: '750px' }}>
              <HeaderRow rowTemplate={rowTemplate}>
                {headerData.map((header) => (
                  <CellPrimary key={header}>{header}</CellPrimary>
                ))}
              </HeaderRow>
              <>
                {pendingTransactions?.items
                  ? pendingTransactions?.items.map((row: any, idx) => (
                      <BodyRow key={row?.id} rowTemplate={rowTemplate}>
                        <CellPrimary>
                          <Tooltip
                            title={
                              <>
                                {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                                <br />
                                {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                              </>
                            }
                            placement="top"
                            arrow
                          >
                            <div>
                              {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)
                            </div>
                          </Tooltip>
                        </CellPrimary>
                        <CellPrimary>
                          <AddressesCell
                            address={row?.user?.ethAddress || '-'}
                            name={row?.user?.name}
                            id={`${idx}_buyer`}
                            isShowUsIcon={row.user.country === 'US'}
                          />
                        </CellPrimary>

                        <CellPrimary>
                          {getNftName(chainId, row.nftAddress)} (#{row?.nftId || 0})
                        </CellPrimary>
                        <CellPrimary>${Number(row.price).toLocaleString('en-US')}</CellPrimary>
                        <CellPrimary>
                          <StyledReviewButton onClick={() => handleRequestData(row)}>
                            Review
                          </StyledReviewButton>
                        </CellPrimary>
                      </BodyRow>
                    ))
                  : ''}
              </>
            </Table>
          </TableContainer>
          {!pendingTransactions?.items?.length && (
            <Box my={4}>
              <Typography textAlign="center" fontWeight="bold" color={colors.$default}>
                No Pending Transfers
              </Typography>
            </Box>
          )}
          {Boolean(pendingTransactions?.nextPage) && (
            <Box mt={3} display="flex" justifyContent="center">
              <LoadingButtonStyled
                color="info"
                variant="outlined"
                disabled={!pendingTransactions?.nextPage}
                onClick={handleShowMore}
                loading={isLoading}
              >
                Show more
              </LoadingButtonStyled>
            </Box>
          )}
          <ReviewUpgradeNFTModal
            isOpen={Boolean(requestData)}
            handleClose={() => {
              handleRequestData(null)
              loadTransactions(offset)
            }}
            handleRequestData={handleRequestData}
            requestData={requestData}
          />
        </>
      )}
    </>
  )
}

const rowTemplate = css`
  grid-template-columns: repeat(4, minmax(150px, 1fr)) 102px;
`

const StyledReviewButton = styled(Button)`
  padding: 10px;
  border: 1px solid ${colors.$blue};
  border-radius: 8px;
  color: ${colors.$blue};
  text-transform: none;
  transition: 0.3s;

  &:hover {
    background-color: transparent;
    opacity: 0.6;
  }
`

const LoadingButtonStyled = styled(LoadingButton)`
  text-transform: none;
  border-radius: 6px;
  font-weight: 600;
  color: ${colors.$blue};
  border-color: ${colors.$blue};
`
