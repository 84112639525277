import React, { FC } from 'react'
import { IErrorNotRegistered, IErrorNotWhitelisted } from './helpers/types'
import ErrorMessage from '../../ErrorMessage'
import { Box, Typography } from '@mui/material'
import { colors } from '../../../utils/colorVariables'
import styled from '@emotion/styled'

export const ErrorNotWhitelisted: FC<IErrorNotWhitelisted> = ({ message, handleWhitelist }) => {
  return (
    <>
      <StyledErrorMessage>
        <ErrorMessage message={message} isError={Boolean(message)} />
      </StyledErrorMessage>
      <Box mt="10px" pl="25px" mb={5} color={colors.$secondary2}>
        <StyledLink onClick={handleWhitelist} display="inline">
          Whitelist this Triber
        </StyledLink>{' '}
        if has passed KYC or inform a manager.
      </Box>
    </>
  )
}

export const ErrorNotRegistered: FC<IErrorNotRegistered> = ({ message, handleRegister }) => {
  return (
    <>
      <StyledErrorMessage>
        <ErrorMessage message={message} isError={Boolean(message)} />
      </StyledErrorMessage>
      <Box mt="10px" pl="25px" mb={5}>
        <StyledLink onClick={handleRegister} display="inline">
          Click here to add this Member
        </StyledLink>
      </Box>
    </>
  )
}

export const ErrorNoIntroducer: FC<IErrorNotWhitelisted> = ({ message, handleWhitelist }) => {
  return (
    <>
      <StyledErrorMessage>
        <ErrorMessage message={message} isError={Boolean(message)} />
      </StyledErrorMessage>
      <Box mt="10px" pl="25px" mb={5}>
        <StyledLink onClick={handleWhitelist} display="inline">
          Edit Triber
        </StyledLink>
      </Box>
    </>
  )
}

const StyledLink = styled(Typography)`
  color: ${colors.$blue};
  cursor: pointer;
  text-decoration: underline;
`
const StyledErrorMessage = styled(Box)`
  & > div {
    font-size: 16px;
    font-weight: 400;
  }
`
