import React from 'react'
import styled from '@emotion/styled'
import { InputAdornment } from '@mui/material'

import { colors } from 'src/utils/colorVariables'
import { TextFieldPrimary } from 'src/components/TextField'
import RadioButton from 'src/components/RadioButton'
import ErrorMessage from 'src/components/ErrorMessage'

import {
  regionOptions,
  categoryOptions,
  frequencyOptions,
  distributionOptions,
  votingOptions
} from './constants'

import { SpecificMembers, SpecificMember } from './execute-template/specific-members'

interface Props {
  error?: any
  onChange: (...params: any) => void
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean) => void
  value: string
  isOneTime?: boolean
  step?: number
}

export const TemplateName = ({ value, error, onChange, setFieldTouched = () => {} }: Props) => {
  return (
    <Step>
      <div className="title">1. How do you want to name this template?</div>
      <div className="info">
        This name is only used for list of templates. You’ll be asked for specific Event Name each
        time you use this template.
      </div>
      <TextFieldPrimary
        fullWidth
        name="name"
        value={value}
        placeholder="Type name of the template"
        onChange={onChange}
        onFocus={() => setFieldTouched('name', true, true)}
      />
      {Boolean(error) && <ErrorMessage message={error} isError />}
    </Step>
  )
}

export const AirdropName = ({ value, error, onChange, setFieldTouched = () => {} }: Props) => {
  return (
    <Step>
      <div className="title">1. How do you want to name this specific airdrop?</div>
      <div className="info">
        This name will be displayed in the airdrop history for admins and users.
      </div>
      <TextFieldPrimary
        fullWidth
        name="name"
        value={value}
        placeholder="Type name of airdrop"
        onChange={onChange}
        onFocus={() => setFieldTouched('name', true, true)}
      />
      {Boolean(error) && <ErrorMessage message={error} isError />}
    </Step>
  )
}

export const AirdropFixedBudget = ({ value, error, onChange, step }: Props) => {
  return (
    <Step>
      <div className="title">{step}. Provide the budget</div>
      <TextFieldPrimary
        fullWidth
        name="budget"
        value={value}
        placeholder="Type amount of budget"
        onChange={onChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">USDC</InputAdornment>
        }}
        sx={{ maxWidth: '320px' }}
      />
      {Boolean(error) && <ErrorMessage message={error} isError />}
    </Step>
  )
}

export const AirdropRegion = ({ value: selectedOption, error, onChange }: Props) => {
  return (
    <Step>
      <div className="title">2. What region do you want to airdrop?</div>
      <RadioContainer>
        {regionOptions.map(({ label, value }) => (
          <div className="row" key={value} onClick={() => onChange(value)}>
            <RadioButton checked={selectedOption === value} />
            <div>{label}</div>
          </div>
        ))}
      </RadioContainer>
      {Boolean(error) && <ErrorMessage message={error} isError />}
    </Step>
  )
}

export const MembersType = ({ value: selectedOption, error, onChange }: Props) => {
  return (
    <Step>
      <div className="title">3. What category of members?</div>
      <div className="info">Choose the category of members that will be eligible for airdrop.</div>
      <RadioContainer>
        {categoryOptions.map(({ label, value }) => (
          <div className="row" key={value} onClick={() => onChange(value)}>
            <RadioButton checked={selectedOption === value} />
            <div>{label}</div>
          </div>
        ))}
      </RadioContainer>
      {Boolean(error) && <ErrorMessage message={error} isError />}
    </Step>
  )
}

interface AirdropBudgetProps extends Props {
  changeBudget: (...params: any) => void
  amountValue: string
  percentValue: string
  amountError?: any
  percentError?: any
}

export const AirdropBudget = ({
  value: selectedOption,
  error,
  onChange,
  isOneTime,
  changeBudget,
  amountValue,
  percentValue,
  amountError,
  percentError
}: AirdropBudgetProps) => {
  return (
    <Step>
      <div className="title">
        4. Do you want to reward a percentage % of sales or a fixed amount?
      </div>
      <div className="info">
        If you choose “A fixed amount”, you will pick the amount at the time of the specific
        airdrop.
      </div>
      <RadioContainer>
        <div className="row" onClick={() => onChange('IsPercentage')}>
          <RadioButton checked={selectedOption === 'IsPercentage'} />
          <div className="main">A percentage % of sales</div>
        </div>
        {selectedOption === 'IsPercentage' && (
          <div>
            <TextFieldPrimary
              style={{ maxWidth: '50%' }}
              name="percentage"
              value={percentValue}
              placeholder="Type percent"
              onChange={changeBudget}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
            {percentError && <ErrorMessage message={percentError} isError />}
          </div>
        )}
        <div className="row" onClick={() => onChange('IsFixedAmount')}>
          <RadioButton checked={selectedOption === 'IsFixedAmount'} />
          <div className="main">A fixed amount</div>
        </div>
        {isOneTime && selectedOption === 'IsFixedAmount' && (
          <div>
            <TextFieldPrimary
              style={{ maxWidth: '50%' }}
              name="amountSum"
              value={amountValue}
              placeholder="Type amount"
              onChange={changeBudget}
              InputProps={{
                endAdornment: <InputAdornment position="end">USDC</InputAdornment>
              }}
            />
            {amountError && <ErrorMessage message={amountError} isError />}
          </div>
        )}
      </RadioContainer>
      {error && <ErrorMessage message={error} isError />}
    </Step>
  )
}

export const CalculationPeriod = ({ value: selectedOption, error, onChange }: Props) => {
  return (
    <Step>
      <div className="title">5. What’s the calculation period for this airdrop?</div>
      <div className="info" style={{ marginBottom: '16px' }}>
        The chosen period will be used to calculate the budget of the airdrop, the voting period and
        NFT ownership date when applicable.
      </div>
      <div className="example">
        For example, if you choose “Quarterly” and airdrop on April 7th, the reward will be
        calculated as the percentage % of sales between January 1st and March 31st, also votes
        during this period and NFT holdings at the end of the period (23:59 March 31st).
      </div>
      <RadioContainer>
        {frequencyOptions.map(({ label, value }) => (
          <div className="row" key={value} onClick={() => onChange(value)}>
            <RadioButton checked={selectedOption === value} />
            <div>{label}</div>
          </div>
        ))}
      </RadioContainer>
      {error && <ErrorMessage message={error} isError />}
    </Step>
  )
}

interface DistributionRuleProps extends Props {
  category: string
}

export const DistributionRule = ({
  value: selectedOption,
  error,
  onChange,
  category
}: DistributionRuleProps) => {
  const isProd = window.location.origin === 'https://app.sixthsociety.com'
  const hideOptions = ['TokenNumber', 'TokenTribeSize']

  return (
    <Step>
      <div className="title" style={{ marginBottom: '32px' }}>
        6. Choose a distribution rule for this Airdrop
      </div>

      <RadioContainer>
        {distributionOptions.map(({ label, value }) => {
          const isOptionDisabled =
            category === 'SpecificMembers' ? value !== 'Manually' : value === 'Manually'
          if (isProd && hideOptions.includes(value)) return null
          return (
            <div
              className={`row ${isOptionDisabled ? 'disabled' : ''}`}
              key={value}
              onClick={() => !isOptionDisabled && onChange(value)}
            >
              <RadioButton checked={selectedOption === value} />
              <div>{label}</div>
            </div>
          )
        })}
      </RadioContainer>
      {error && <ErrorMessage message={error} isError />}
    </Step>
  )
}

export const Voting = ({ value: selectedOption, error, onChange }: Props) => {
  return (
    <Step>
      <div className="title"> 7. Is voting required to get airdrop?</div>
      <RadioContainer>
        {votingOptions.map(({ label, value }) => (
          <div className="row" key={value} onClick={() => onChange(value)}>
            <RadioButton checked={selectedOption === value} />
            <div>{label}</div>
          </div>
        ))}
      </RadioContainer>
      {Boolean(error) && <ErrorMessage message={error} isError />}
    </Step>
  )
}

interface ManualDistributionProps extends Props {
  setFieldValue: (...params: any) => void
  specificMembers?: SpecificMember[]
  step: number
  budget?: string
  errors?: any
  touched?: any
  region: string
  template: Record<string, any>
}

export const ManualDistribution = ({
  value,
  onChange,
  step,
  specificMembers,
  errors,
  touched,
  budget,
  setFieldValue,
  region,
  template
}: ManualDistributionProps) => {
  return (
    <Step>
      <div className="title">{step}. How do you want to split the airdrop?</div>
      <div className="info">
        For example, with “Percentage” you can assign to Adam 20% of the total. With “Sum”, you can
        assign to Adam 12,328 USDC (a precise sum)
      </div>
      <RadioContainer>
        <div className="row" onClick={() => onChange('percent')}>
          <RadioButton checked={value === 'percent'} />
          <div className="main">By percentage</div>
        </div>

        <div className="row" onClick={() => onChange('sum')}>
          <RadioButton checked={value === 'sum'} />
          <div className="main">By sum</div>
        </div>
      </RadioContainer>
      <SpecificMembers
        data={specificMembers || []}
        setFieldValue={setFieldValue}
        budget={budget || '0'}
        error={errors.specificMembers}
        touched={touched.specificMembers}
        budgetType={value}
        region={region}
        template={template}
      />
      {!Array.isArray(errors?.specificMembers) &&
        touched.specificMembers &&
        errors.specificMembers && <ErrorMessage message={errors?.specificMembers} isError />}
    </Step>
  )
}

export const Step = styled.div`
  color: ${colors.$secondary};
  .title {
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 16px;
  }
  .info {
    color: ${colors.$primary2};
    margin-bottom: 32px;
    font-weight: 300;
    font-size: 16px;
    line-height: 138%;
  }
  .example {
    color: ${colors.$primary2};
    background-color: ${colors.$border};
    padding: 16px 24px;
    font-weight: 300;
    font-size: 14px;
    line-height: 138%;
    margin-bottom: 32px;
  }
`
export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .row {
    display: flex;
    align-items: center;
    gap: 11px;
    cursor: pointer;
    .main {
      color: ${colors.$secondary};
      font-weight: 600;
      font-size: 16px;
      span {
        color: ${colors.$primaryLight};
      }
    }
    .second {
      color: ${colors.$primaryLight};
      font-weight: 400;
      font-size: 13px;
    }
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
