import React, { FC } from 'react'
import styled from '@emotion/styled'
import SendIcon from '@mui/icons-material/Send'
import { ButtonPrimaryOutlined } from 'src/components/Button'
import { Typography } from '@mui/material'
import { isMobile } from 'src/utils/isMobile'
import { useAccessController } from '../../../hooks/useAccessController'
import { UserRoles } from '../../../state/effector/users/user.types'

interface IProps {
  handleOpenModal: (param: boolean) => any
}

const SendNFT: FC<IProps> = ({ handleOpenModal }) => {
  const mobile = isMobile()
  const { isAllowed: isAllowedEditorUp } = useAccessController(UserRoles.Editor)

  return (
    <ButtonPrimaryOutlinedStyled
      endIcon={<SendIcon sx={{ width: '20px' }} />}
      onClick={() => handleOpenModal(true)}
      disabled={!isAllowedEditorUp}
    >
      <Typography
        sx={{
          fontSize: mobile ? '18px' : '16px',
          textTransform: 'capitalize',
          fontWeight: 600
        }}
      >
        Send NFT
      </Typography>
    </ButtonPrimaryOutlinedStyled>
  )
}

export default SendNFT

const ButtonPrimaryOutlinedStyled = styled(ButtonPrimaryOutlined)`
  width: 100%;
`
