import { useCallback } from 'react'
import {
  saveBuddy,
  saveBuddyInStore,
  saveBuddyWhiteListStatus,
  saveIsBuddy,
  saveIsBuddyLoading
} from '../state/effector/store'
import apiService from '../services/api'

export const useGetBuddy = () => {
  return useCallback(async () => {
    saveIsBuddyLoading(true)
    try {
      const response = await apiService.getBuddy()
      const buddyInfo = response?.data
      if (buddyInfo?.ethAddress) {
        saveBuddyInStore(buddyInfo?.ethAddress)
        saveBuddyWhiteListStatus(buddyInfo!.whiteListStatus)
        saveBuddy(buddyInfo)
      } else {
        saveBuddyInStore('')
        saveBuddy(null)
      }

      if (buddyInfo?.isBuddy) {
        saveIsBuddy(true)
      } else {
        saveIsBuddy(false)
      }
    } catch (error) {
      console.log(error)
      saveIsBuddy(false)
      saveBuddyInStore('')
      saveBuddy(null)
    } finally {
      saveIsBuddyLoading(false)
    }
  }, [])
}
