import { createEvent, createStore } from 'effector'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { ILog } from './logs.types'

export const saveIsLogsLoading = createEvent<boolean>()
export const saveLogsInStore = createEvent<ResponseWithPagination<ILog[]> | null>()

export const $logs = createStore<ResponseWithPagination<ILog[]> | null>(null).on(
  saveLogsInStore,
  (_, logs) => {
    return logs || null
  }
)

export const $isLoadingLogs = createStore<boolean>(false).on(
  saveIsLogsLoading,
  (_, isLoading) => isLoading
)
