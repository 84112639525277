import React from 'react'
import styled from '@emotion/styled'

import EmptyStateIcon from 'src/assets/empty_state_icon.svg'
import { LoaderContainer } from '../../LoadingIndicator'

interface Props {
  message: string
  isLoading?: boolean
}

const EmptyStateBox: React.FC<Props> = ({ message, isLoading }) =>
  isLoading ? (
    <LoaderContainer height="200px" />
  ) : (
    <Wrapper>
      <img src={EmptyStateIcon} alt="empty-state-icon" />
      <WrapperMessage>{message}</WrapperMessage>
    </Wrapper>
  )

export default EmptyStateBox

const Wrapper = styled('div')`
  position: relative;
  height: 181px;
  width: 100%;
  box-sizing: border-box;
  gap: 12px;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: silver; stroke-width: 2; stroke-dasharray: 8'/></svg>");
  border-radius: 8px;
  align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-weight: 500;
  font-size: 16px;
  line-height: 138%;

  /* Text/Secondary */
  color: #293f6a;
`

const WrapperMessage = styled('div')`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 138%;
`
