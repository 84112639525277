export interface ResponseWithPagination<T> {
  items: T
  itemCount: number
  totalItems: number
  offset: number
  page: number
  totalPages: number
  nextPage?: number
  prevPage?: number
}

export type WhitelistTypes = 'PENDING' | 'ACCEPTED' | 'REJECTED'

export enum EWhitelistStatuses {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}
