import React, { useCallback, useEffect } from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useStore } from 'effector-react'

import useMetaMask from '../hooks/useMetaMask'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  MetamaskAuth,
  InstallMetamask,
  PendingSignature,
  RequestToSign
} from '../components/MetamaskComponents'
import { $auth, $triggerSignature, setAuth, $activeNfts, $nftList } from '../state/effector/store'
import { $user } from '../state/effector/users/users.store'
import { allowedAccounts } from 'src/constants'

const ProtectedRoute = ({ children }: any) => {
  const { account, isLoading, disconnect } = useMetaMask()
  const location = useLocation()
  const navigate = useNavigate()

  const { ethereum } = window as any
  const isMetamaskInstalled = typeof ethereum !== 'undefined'
  const auth = useStore($auth)
  const user = useStore($user)
  const triggerSignature = useStore($triggerSignature)
  const activeNfts = useStore($activeNfts)
  const nftList = useStore($nftList)

  const handleLogout = useCallback(() => {
    localStorage.clear()
    disconnect()
    setAuth({})
    navigate('/')
  }, [])

  useEffect(() => {
    if (
      auth?.[account]?.logoutTime &&
      moment(new Date(auth?.[account]?.logoutTime)).isBefore(moment())
    ) {
      handleLogout()
    }
  }, [account, auth, triggerSignature, handleLogout])

  if (!isMetamaskInstalled) {
    return <InstallMetamask />
  }

  if (!account) {
    return <MetamaskAuth />
  }

  if (triggerSignature) {
    return <PendingSignature />
  }

  if (!auth?.[account]) {
    return <RequestToSign />
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (
    user &&
    location.pathname !== '/' &&
    (user.whiteListStatus !== 'ACCEPTED' ||
      (!allowedAccounts.includes(account.toLowerCase()) && nftList.length && !activeNfts))
  ) {
    return <Navigate to="/" />
  }

  return children
}

export default ProtectedRoute
