import { UserRoles } from '../users/user.types'

export enum ELogActionTypes {
  addUser = 'addUser',
  changeRole = 'changeRole',
  changeWhitelist = 'changeWhitelist',
  sendNft = 'sendNft',
  changeCountry = 'changeCountry',
  upgradeNft = 'upgradeNft',
  createTemplate = 'createTemplate',
  editTemplate = 'editTemplate',
  archiveTemplate = 'archiveTemplate',
  unarchiveTemplate = 'unarchiveTemplate',
  submitAirdrop = 'submitAirdrop',
  updateMaintenance = 'updateMaintenance',
  airdropNote = 'airdropNote'
}

export enum ELogActionTypesWithAll {
  addUser = 'addUser',
  changeRole = 'changeRole',
  changeWhitelist = 'changeWhitelist',
  sendNft = 'sendNft',
  all = 'all'
}

export interface GetLogsParams {
  page: number
  offset: number
  teamWallet?: string
  actionWallet?: string
  actionTypes?: string | []
}

export interface ITeamMember {
  principal: string
  id: number
  name?: any
  role?: UserRoles
  tenant: string
  language: string
  ethAddress: string
  photoId?: any
  buddyId: number
  isBuddy: boolean
  sales: number
  photo?: any
}

export interface ILog {
  id: number
  teamMemberId: number
  actionType: string
  action: string
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  teamMember: ITeamMember
}
