import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { User } from 'src/state/effector/users/user.types'

import { TemplateCard } from './template-card'

export type TemplateType = {
  id: number
  createdBy: number
  updatedBy: number | null
  name: string
  region: string
  category: string
  rewardType: string
  percentage: string
  frequency: string
  distribution: string
  isVotingRequired: string | boolean
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  creator: User
  updater: User | null
  [key: string]: any
}

interface Props {
  templates: TemplateType[]
  unarchive: (id: number) => void
}

export const Templates = ({ templates, unarchive }: Props) => {
  const isLaptop = useMediaQuery('@media(min-width: 800px)')

  const navigate = useNavigate()
  const openOneTime = () => {
    navigate(`/admin/airdrops/one-time`)
  }
  const goToExecute = (id: number) => {
    navigate(`/admin/airdrops/execute/${id}`)
  }

  return (
    <Container>
      {isLaptop && <TemplateCard isOneTime openForm={openOneTime} />}
      {templates.map((template) => (
        <TemplateCard
          key={template.id}
          template={template}
          openForm={() => goToExecute(template.id)}
          unarchive={unarchive}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
