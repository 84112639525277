import React from 'react'
import styled from '@emotion/styled'
import { useStore } from 'effector-react'
import { $isMaintenanceModeActive } from '../../../../state/effector/store'

export const MaintenanceModeOnBlock: React.FC = () => {
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  return isMaintenanceModeActive ? (
    <StyledBlock>Note: the Dapp is hidden, maintenance mode is on.</StyledBlock>
  ) : null
}

const StyledBlock = styled.div`
  color: black;
  top: 0;
  background-color: #f6b91c;
  text-align: center;
  height: 37px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  position: fixed;
  z-index: 1301;
`
