import React from 'react'
import { Checkbox, FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { colors } from '../../../utils/colorVariables'
import isEqual from 'lodash/isEqual'
import { UserRoles } from '../../../state/effector/users/user.types'

export interface SelectOption {
  label: string
  value: UserRoles
}

export const selectOptions: SelectOption[] = [
  {
    label: 'Super-Admin',
    value: UserRoles.SuperAdmin
  },
  {
    label: 'Admin',
    value: UserRoles.Admin
  },
  {
    label: 'Editor',
    value: UserRoles.Editor
  },
  {
    label: 'Viewer',
    value: UserRoles.Viewer
  }
]

interface Props {
  value: UserRoles[]
  onChange: (event: SelectChangeEvent<UserRoles[]>) => void
}

export const TeamRoleSelector: React.FC<Props> = ({ value, onChange }) => {
  const getSelectedOptionLabel = (element: UserRoles) => {
    const selectedOption = selectOptions.find((opt: SelectOption) => opt.value === element)
    if (selectedOption) return selectedOption.label
    else return ''
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        multiple
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        renderValue={(selected: UserRoles[]) => {
          return !isEqual(selected, []) ? (
            selected.map((element) => getSelectedOptionLabel(element)).join(', ')
          ) : (
            <EmptyState>Search Roles</EmptyState>
          )
        }}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {selectOptions.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            <CheckboxStyled checked={value?.includes(optionValue)} size="small" />
          </MenuItemWrapper>
        ))}
      </Select>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.$black};
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
const EmptyState = styled('div')`
  color: ${colors.$secondary2};
`
