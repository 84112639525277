// import { t } from '@lingui/macro'
import axios, { AxiosResponse } from 'axios'

import { refreshAuthToken } from './api'
import { StorageService } from './storage.service'
import { changeUserDeactivatedStatus, saveUserInStore } from '../state/effector/users/users.store'
import { setIsMaintenanceModeActive, setIsNotAllowedServer } from '../state/effector/store'

interface APIResponse<T = undefined> {
  data: T
  message: string
  isBE?: boolean
}

export const responseSuccessInterceptor = (response: AxiosResponse<APIResponse>) => {
  const data = response.data?.data ?? response.data

  return {
    ...response,
    message: response.data?.message,
    data
  }
}

// export const responseRejectInterceptor = async (err: any) => {
//     const originalRequest = err.config

//     if (err.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true
//         const localStorageAuth = window.localStorage.getItem('auth')
//         if (!localStorageAuth) return Promise.reject(err)
//         let authObject = null
//         const walletAddr = localStorage.getItem('walletAddr') as string

//         try {
//             authObject = JSON.parse(localStorageAuth)

//             if (!walletAddr && !authObject[walletAddr].refreshToken) return await Promise.reject(err)
//         } catch (e) {
//             console.log(e)
//             return Promise.reject(err)
//         }

//         const tokens = await refreshAuthToken(authObject[walletAddr].refreshToken)
//           .then((res) => res.data)
//           .catch((e) => {
//               if ((e.response as AxiosResponse).status === 401) {
//                   StorageService.clearStorage()
//                   window.location.reload()
//               }
//           })

//         if (tokens) {
//             StorageService.setAuthTokens(walletAddr, tokens)
//             _axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokens.accessToken
//             return _axios(originalRequest)
//         }
//     }

//     return Promise.reject(err)
// }

export const getError = (error: any) => {
  let message = 'Unknown error'
  let code = 'Unknown code'
  if (error.response !== undefined) {
    code = error.response.data.code ?? error.response.code
    message = error.response.data.message ?? error.response.message ?? HTTP_ERRORS[code]
  } else {
    message = error.message
    code = error.code
  }

  return { message, code }
}

export const responseRejectInterceptor = async (err: any) => {
  const originalRequest = err.config

  const skipErrors = ['Request aborted', 'Mising Authorization']

  if (!skipErrors.includes(err.message) && (!err.response || !err.response?.headers)) {
    setIsNotAllowedServer(true)
    StorageService.clearStorage()
    console.error(err)
  }

  if (err.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    const localStorageAuth = window.localStorage.getItem('auth')
    if (!localStorageAuth) return Promise.reject(err)
    let authObject = null
    const walletAddr = localStorage.getItem('walletAddr') as string

    try {
      authObject = JSON.parse(localStorageAuth)

      if (!walletAddr && !authObject[walletAddr].refreshToken) return await Promise.reject(err)
    } catch (e) {
      console.log(e)
      return Promise.reject(err)
    }

    const tokens = await refreshAuthToken(authObject[walletAddr].refreshToken)
      .then((res) => res.data)
      .catch((e) => {
        if ((e.response as AxiosResponse).status === 401) {
          StorageService.clearStorage()
          // window.location.reload()
        }
      })

    if (tokens) {
      StorageService.setAuthTokens(walletAddr, tokens)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokens.accessToken
      return axios(originalRequest)
    }
  }

  // if (err.response?.status === 403) {
  //   if (window.location.pathname !== '/' && err.response.config.url !== 'sendNftValidate') {
  //     window.location.href = window.location.origin
  //   }
  //   if (err.response?.data?.code === 'IN_MAINTENANCE') {
  //     setIsMaintenanceModeActive(true)
  //     saveUserInStore(null)

  //     return
  //   }
  //   const deactivatedUserErrorMessage = 'Deactivated Users cannot user this methods'
  //   const error = getError(err)

  //   if (error.message === deactivatedUserErrorMessage) {
  //     changeUserDeactivatedStatus(true)
  //   }
  // }

  return Promise.reject(err)
}

export const HTTP_ERRORS: { [id: string]: string } = {
  NOT_FOUND: `Entity not found`
}
