import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import { colors } from 'src/utils/colorVariables'

import { formatValue } from './constants'
import { TemplateType } from './templates-list'

interface Props {
  keys: string[]
  values: Record<string, any>
  isExecute?: boolean
  calculations?: Record<string, any>
  isOneTime?: boolean
}

const formattedLabel = {
  region: 'Airdrop Region',
  category: 'Category of Members',
  rewardType: 'Airdrop Budget',
  frequency: 'Calculation Period',
  distribution: 'Distribution Rule',
  isVotingRequired: 'Voting required'
} as Record<string, string>

const formattedCalculationLabel = {
  budget: 'Airdrop Budget',
  calculationPeriod: 'Calculation Period',
  pointAmount: 'Reward for 1 Point',

  totalPoints: 'Total Points',
  membersCount: 'Members to be Rewarded'
} as Record<string, string>

const valueToBeSpecified = ['SpecificMembers', 'Manually', 'IsFixedAmount']

const hideKeys = ['percentage', 'amountSum', 'budgetType', 'specificMembers']

export const TemplatePreset = ({
  keys,
  values,
  isExecute,
  calculations = {},
  isOneTime
}: Props) => {
  const formatCalculationValue = (key: string, value: any) => {
    if (key === 'calculationPeriod') {
      return `${moment(value.start).utc().format('DD MMM YYYY')} - ${moment(value.end)
        .utc()
        .format('DD MMM YYYY')}`
    }
    if (['budget', 'pointAmount'].includes(key)) {
      return `${
        value === '' ? '-' : (+value).toLocaleString('en-US', { maximumFractionDigits: 6 }) ?? '-'
      } USDC`
    }
    return (+value).toLocaleString('en-US', { maximumFractionDigits: 6 })
  }

  return (
    <Container>
      <div className="header">{isOneTime ? 'Summary' : 'Template preset'}</div>
      {keys.map((key) => {
        if (hideKeys.includes(key)) return null

        const needToBeSpecified = !isExecute && valueToBeSpecified.includes(values[key])
        return (
          <div key={key} className="element-row">
            <div className="label">{formattedLabel[key] || key}</div>
            <div
              className={`value ${needToBeSpecified ? 'needToBeSpecified' : ''}`}
              style={{ textTransform: key === 'name' ? 'none' : 'capitalize' }}
            >
              {formatValue(key, values[key], values as TemplateType)}
            </div>
            {needToBeSpecified && (
              <div className="needToBeSpecifiedContainer">To be specified during execution</div>
            )}
          </div>
        )
      })}
      {Boolean(Object.keys(calculations).length) && (
        <>
          <div className="header" style={{ marginTop: '48px' }}>
            Calculations
          </div>
          {Object.entries(calculations)?.map(([key, value]) => {
            return (
              <div key={key} className="element-row">
                <div className="label">
                  {values.distribution === 'Equally' && key === 'pointAmount'
                    ? 'Reward for 1 Member'
                    : formattedCalculationLabel[key] || key}
                </div>
                <div className="value" style={{ textTransform: 'capitalize' }}>
                  {formatCalculationValue(key, value)}
                </div>
              </div>
            )
          })}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .header {
    font-weight: 700;
    color: ${colors.$primaryLight};
    margin-bottom: 8px;
  }
  .element-row {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .label {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      color: ${colors.$secondary2};
    }
    .value {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: ${colors.$blue};
      word-break: break-word;
    }
    .needToBeSpecified {
      color: ${colors.$lightYellow};
    }
    .needToBeSpecifiedContainer {
      color: ${colors.$primary};
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }
`
