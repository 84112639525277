import React, { ChangeEvent, FC, useEffect } from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import {
  AmountFieldWrapper,
  IncrDecr,
  LabelSelect,
  NftKindSelectWrapper,
  StyledButton,
  StyledLabel,
  StyledQuantityWrapper,
  TypographyWrapper
} from './helpers/styles'
import { colors } from '../../../utils/colorVariables'
import { TextFieldPrimary } from '../../TextField'
import ErrorMessage from '../../ErrorMessage'
import { isReceiverIsTreasuryAddressError } from './helpers/constants'
import NftKindSelect from '../../NftKindSelect'
import isEmpty from 'lodash/isEmpty'
import { SendSuccessComponent } from './SendSuccessComponent'
import { FormikErrors } from 'formik'
import { IValues, SendNFTSuccessResponse } from './helpers/types'
import { isMobile } from '../../../utils/isMobile'
import { IResources } from '../../../types/common.types'
import { getAvailableResource } from './helpers/utils'
import { useAvailableNftTypes } from 'src/hooks/useAvailableNftTypes'
import { $selectedNftAddress, setSelectedNftAddress } from 'src/state/effector/store'
import { useStore } from 'effector-react'
import { useFundingNft } from 'src/hooks/useFundingNft'

interface ITransferNFTForm {
  initialValues: IValues
  onSendSubmit: (values: IValues) => void
  isReceiverIsTreasuryAddress: boolean
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  handleBlur: (event: any) => void
  values: IValues
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<IValues>
  resources: IResources
  onAmountBlur: (value: string) => void
  sendSuccess: SendNFTSuccessResponse
  isValid: boolean
  chainId: any
  sendErrorComponent: (JSX.Element | undefined)[]
  sendError: Array<string>
  isModalLoading: boolean
  submitBtnLabel: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<IValues>> | Promise<void>
}

export const TransferNFTForm: FC<ITransferNFTForm> = ({
  isReceiverIsTreasuryAddress,
  handleSubmit,
  handleBlur,
  values,
  handleChange,
  errors,
  resources,
  onAmountBlur,
  sendSuccess,
  isValid,
  chainId,
  sendErrorComponent,
  sendError,
  isModalLoading,
  submitBtnLabel,
  setFieldValue
}) => {
  const selectedNftAddress = useStore($selectedNftAddress)

  const fundingNft = useFundingNft()
  const mobile = isMobile()
  const isFunding = values.nftAddress.toLowerCase() == fundingNft.SixthSocietyNFT.toLowerCase()

  const NFTlimit =
    getAvailableResource(isFunding ? 'foundingOwners' : values.nftAddress, resources, isFunding) ||
    0

  useEffect(() => {
    if (selectedNftAddress) {
      changeQuantityHandler('1')
      setFieldValue('nftAddress', selectedNftAddress.toLowerCase())
    }
  }, [selectedNftAddress])

  const changeQuantityHandler = (quantity: string) => {
    const numberValue = Number(quantity)
    if (numberValue <= NFTlimit && numberValue <= 100)
      if (quantity === '') {
        setFieldValue('amount', '')
      } else if (numberValue < 1) {
        setFieldValue('amount', '1')
      } else {
        setFieldValue('amount', quantity)
      }
  }

  const decrementHandler = () => {
    if (values.amount > 1) setFieldValue('amount', values.amount - 1)
  }

  const incrementHandler = () => {
    if (Number(values.amount) < NFTlimit && Number(values.amount) < 100)
      setFieldValue('amount', Number(values.amount) + 1)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl variant="standard" fullWidth onFocus={handleBlur} onBlur={handleBlur}>
        <StyledLabel>
          <TypographyWrapper color={colors.$black} variant="body1">
            To member
          </TypographyWrapper>
          <TextFieldPrimary
            name="ethAddress"
            value={values.ethAddress}
            placeholder="Paste wallet address"
            onChange={handleChange}
            invalid={Boolean(errors?.ethAddress)}
          />
        </StyledLabel>

        <ErrorMessage message={errors?.ethAddress} isError={Boolean(errors?.ethAddress)} />
      </FormControl>
      {isReceiverIsTreasuryAddress && (
        <ErrorMessage message={isReceiverIsTreasuryAddressError} isError={true} />
      )}
      <NftKindSelectWrapper>
        <StyledLabel>
          <LabelSelect color={colors.$black} variant="body1">
            Pick the NFT type
          </LabelSelect>
          <Box mb={mobile ? 1.5 : 0} />
          <NftKindSelect showAvailable={resources} isAdmin={true} ethAddress={values.ethAddress} />
        </StyledLabel>
      </NftKindSelectWrapper>
      <StyledQuantityWrapper>
        <FormControl fullWidth>
          <LabelSelect color={colors.$black} variant="body1">
            Pick the quantity
          </LabelSelect>
          <AmountFieldWrapper>
            <IncrDecr onClick={decrementHandler} className="plus-option">
              -
            </IncrDecr>
            <TextField
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: mobile ? '100%' : '66px',
                margin: '0 15px'
              }}
              onChange={(e) => changeQuantityHandler(e.target.value)}
              onBlur={(e) => onAmountBlur(e.target.value)}
              type="text"
              InputProps={{
                inputProps: { min: 1, style: { textAlign: 'center', padding: '14.5px 14px' } }
              }}
              id="outlined-number"
              value={values.amount}
            />
            <IncrDecr onClick={incrementHandler} className="minus-option">
              +
            </IncrDecr>
          </AmountFieldWrapper>
        </FormControl>
      </StyledQuantityWrapper>

      <Box>
        {sendErrorComponent}
        {/* Removed verification to prevent hide send info */}
        {/* {isEmpty(sendError) && ( */}
        <SendSuccessComponent sendSuccess={sendSuccess} values={values} chainId={chainId} />
        {/* )} */}
      </Box>
      <Box mt={5}>
        <StyledButton
          type="submit"
          disabled={!isValid || !isEmpty(sendError) || isModalLoading || !values?.amount}
        >
          {submitBtnLabel}
        </StyledButton>
      </Box>
    </form>
  )
}
