import web3 from 'web3'

export const truncateAddress = (address: string, numbersToShow = 3) => {
  if (!address) return 'No Account'
  const match = address.match(
    new RegExp(`^(0x[a-zA-Z0-9]{${numbersToShow}})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$`)
  )
  if (!match) return address
  return `${match[1]}...${match[2]}`
}

export const isAddressValid = (address: string) =>
  address.length ? web3.utils.isAddress(address) : null
