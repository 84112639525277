import { object, string, array } from 'yup'

export const validationSchema = (steps: number, isOneTime: boolean) =>
  object().shape({
    ...(steps > 0 && {
      name: string()
        .required('This field is required')
        .min(3, `${isOneTime ? 'Airdrop' : 'Template'} name is too short. Min 3 characters`)
        .max(50, `${isOneTime ? 'Airdrop' : 'Template'} name is too long. Max 50 characters`)
    }),
    ...(steps > 1 && { region: string().required('This field is required') }),
    ...(steps > 2 && { category: string().required('This field is required') }),
    ...(steps > 3 && {
      percentage: string()
        .test('required', 'This field is required', (value, { parent }) => {
          if (parent.rewardType !== 'IsPercentage') {
            return true
          }
          return Boolean(value)
        })
        .test('min', 'Value must be greater than 0 and less than 100.', (value, { parent }) => {
          if (parent.rewardType !== 'IsPercentage') {
            return true
          }
          return +(value || 0) > 0
        })
        .test('max', 'Value must be greater than 0 and less than 100.', (value, { parent }) => {
          if (parent.rewardType !== 'IsPercentage') {
            return true
          }
          return +(value || 0) < 100
        })
        .nullable(),
      amountSum: string()
        .test('required', 'This field is required', (value, { parent }) => {
          if (parent.rewardType !== 'IsFixedAmount' || !isOneTime) {
            return true
          }

          return Boolean(value)
        })
        .test('min', 'Value must be greater than 0', (value, { parent }) => {
          if (parent.rewardType !== 'IsFixedAmount' || !isOneTime) {
            return true
          }
          return +(value || 0) > 0
        })
        .nullable(),
      rewardType: string().required('This field is required')
    }),
    ...(steps > 4 && { frequency: string().required('This field is required') }),
    ...(steps > 5 && { distribution: string().required('This field is required') }),
    ...(steps > 6 && { isVotingRequired: string().required('This field is required') }),
    ...(steps > 7 && {
      specificMembers: array()
        .of(
          object().shape({
            name: string().required('This field is required'),
            ethAddress: string().required('Invalid wallet format, should be an Ethereum address'),
            amount: string()
              .required('This field is required')
              .test('min', 'Value must be greater than 0', (value) => {
                return +(value || 0) > 0
              })
          })
        )
        .min(1, 'Must be at least 1 member')
        .test('insufficientBudget', 'Insufficient budget', (value: any, { parent }) => {
          const budget = parent.budget || parent.amountSum
          const addedAmount = value.reduce(
            (acc: number, next: { amount: string }) => acc + (+next.amount || 0),
            0
          )
          return +budget - +addedAmount.toFixed(6) >= 0
        })
        .test('remainedBudget', 'Use all budget', (value: any, { parent }) => {
          const budget = parent.budget || parent.amountSum
          const addedAmount = value.reduce(
            (acc: number, next: { amount: string }) => acc + (+next.amount || 0),
            0
          )
          return +budget - +addedAmount.toFixed(6) === 0
        })
    })
  })
