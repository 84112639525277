import React from 'react'

import { css, TableContainer, Tooltip } from '@mui/material'
import moment from 'moment/moment'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../Table'
import { AddressesCell } from '../Table/AddressesCell'
import { ENetworkTypes, IUser } from 'src/types/common.types'
import { numberFormatter } from '../../../utils/numberFormatter'

interface RewardItem {
  country: string
  createdAt: string
  currency: string
  deletedAt: string | null
  eventName: string
  executionType: string
  id: number
  network: ENetworkTypes
  reward: string
  sourceName: string
  sourceOfMoney: string
  transactionType: string
  txHash: string
  updatedAt: string
  user: IUser
}

const headerData: string[] = ['Date', 'Member', 'Reward', 'Sender', 'Event name', 'Execution']

export const RewardsTable = ({ items }: { items: RewardItem[] }) => {
  const getFormattedLink = (network: ENetworkTypes, txHash: string) =>
    (network === ENetworkTypes.SEPOLIA && `https://sepolia.etherscan.io/tx/${txHash}`) ||
    (network === ENetworkTypes.ETH && `https://etherscan.io/tx/${txHash}`) ||
    '#'

  return (
    <TableContainer>
      <Table aria-label="customized table" sx={{ minWidth: '800px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {items.map((row, idx) => (
            <BodyRow key={row.id} rowTemplate={rowTemplate}>
              <CellPrimary>
                <Tooltip
                  title={
                    <>
                      {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                      <br />
                      {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <a
                    href={getFormattedLink(row.network, row.txHash)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                  </a>
                </Tooltip>
              </CellPrimary>
              <CellPrimary>
                <AddressesCell
                  address={row.user.ethAddress || '-'}
                  name={row.user.name}
                  id={`${idx}_member`}
                  isShowUsIcon={row.user.country === 'US'}
                />
              </CellPrimary>
              <CellPrimary>
                {Number(row.reward.replaceAll(',', '')).toLocaleString('en-US', {
                  maximumFractionDigits: 6
                })}{' '}
                {row.currency}
              </CellPrimary>
              <CellPrimary>
                <AddressesCell
                  address={row.sourceOfMoney || '-'}
                  name={row.sourceName}
                  id={`${idx}_sender`}
                  isShowUsIcon={row.user.country === 'US'}
                />
              </CellPrimary>
              <CellPrimary>{row.eventName}</CellPrimary>
              <CellPrimary>{row.executionType}</CellPrimary>
            </BodyRow>
          ))}
        </>
      </Table>
    </TableContainer>
  )
}

const rowTemplate = css`
  grid-template-columns:
    minmax(115px, 1fr) minmax(150px, 1fr) minmax(125px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)
    minmax(105px, 1fr);

  a {
    text-decoration: underline;
  }
`
