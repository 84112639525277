import { styled } from '@mui/system'
import { Tab } from '@mui/material'

export const TabStyled = styled(Tab)`
  font-weight: 700;
  text-transform: none;
  height: 25px;
  border-bottom: 5px solid transparent;
  padding: 10px 0;
  font-size: 16px;
`
