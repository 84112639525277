import React, { useEffect, useLayoutEffect, Suspense } from 'react'

import { Box, css } from '@mui/material'
import styled from '@emotion/styled'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'

import useMetaMask from '../../hooks/useMetaMask'
import { useGetNftCollection } from '../../hooks/useGetNftCollection'
import { useGetUserNftSalesMeta } from '../../hooks/useGetUserNftSalesMeta'
import {
  clearNftList,
  $isMaintenanceModeActive,
  $isCollectionLoading
} from '../../state/effector/store'
import LoadingIndicator from '../LoadingIndicator'
import EditNameModal from '../Modal/EditNameModal'
import { MaintenanceModeOnBlock } from '../AdminPanel/MaintenanceMode/MaintenanceModeOnBlock'
import { useAccessController } from '../../hooks/useAccessController'
import { UserRoles } from '../../state/effector/users/user.types'

const AppLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { chainId, isAuth, account } = useMetaMask()
  const getNftCollection = useGetNftCollection()
  const { isAllowed: isUser } = useAccessController(UserRoles.User, true)
  const { isAllowed: isViewer } = useAccessController(UserRoles.Viewer, true)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  const getIsAllowBuyNFTs = useGetUserNftSalesMeta()

  useEffect(() => {
    if (account) {
      getIsAllowBuyNFTs(account, chainId)
    }
  }, [account, chainId])

  //Effects
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (chainId && account && !$isCollectionLoading.getState()) {
      getNftCollection()
    }
  }, [chainId, account, getNftCollection])

  useEffect(() => {
    if (window.ethereum && window.ethereum.on && chainId) {
      window.ethereum.on('accountsChanged', (acc: string[]) => {
        navigate('/')
        getNftCollection({
          address: acc[0]
        })
      })
      window.ethereum.on('chainChanged', () => {
        navigate('/')
        clearNftList()
      })
    }
  }, [chainId, getNftCollection])

  const showMaintenanceStrip = isAuth && isMaintenanceModeActive && !isUser && !isViewer

  return (
    <>
      {/* <AppBackground /> */}
      <Suspense fallback={<LoadingIndicator />}>
        <EditNameModal />
        {showMaintenanceStrip && <MaintenanceModeOnBlock />}
        <BoxWrapper className="test" showMaintenanceStrip={showMaintenanceStrip}>
          {/* <Container disableGutters> */}
          <Outlet />
          {/* </Container> */}
        </BoxWrapper>
      </Suspense>
    </>
  )
}

const BoxWrapper = styled(Box)<{ showMaintenanceStrip: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${({ showMaintenanceStrip }) =>
    showMaintenanceStrip &&
    css`
      margin-top: 37px;
    `}
`

export default AppLayout
