import React, { useEffect, useMemo, useState } from 'react'
import { Box, TableContainer, Tooltip, css } from '@mui/material'
import moment from 'moment'
import styled from '@emotion/styled'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import { WhitelistTypes, EWhitelistStatuses } from 'src/state/response/response.types'
import { colors } from '../../../../utils/colorVariables'
import { truncateAddress } from '../../../../utils/truncateAddress'
import { useStore } from 'effector-react'
import { $users, triggerUsers } from '../../../../state/effector/users/users.store'
import { MemberModal } from '../MemberModal'
import { User, UserRoles } from 'src/state/effector/users/user.types'
import { Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import { AddressesCell } from '../../Table/AddressesCell'
import SendIcon from '@mui/icons-material/Send'
import { SendNFTModal } from '../../SendNFTModal'
import { BookNFTModal } from '../../BookNFTModal'
import { useAccessController } from '../../../../hooks/useAccessController'
import { HideUnhideFunctionality } from '../../Table/HideUnhideFunctionality'
import { IResources } from '../../../../types/common.types'
import useMetaMask from '../../../../hooks/useMetaMask'
import apiService from '../../../../services/api'
import { TREASURY_ADDRESS_US, TREASURY_ADDRESS } from '../../../../constants/treasuryAddresses'

interface IProps {
  type?: 'all' | 'pending'
}

const AllTribersTable: React.FC<IProps> = ({ type }) => {
  const [sendNftAddress, setSendNftAddress] = useState<string | false>(false)
  const { isAllowed: isEditor } = useAccessController(UserRoles.Editor, true)
  const { isAllowed: isEditorUp } = useAccessController(UserRoles.Editor)
  const { chainId } = useMetaMask()

  const [resources, setResources] = useState<IResources>({})

  useEffect(() => {
    if (chainId) {
      apiService.getResources(chainId).then((response) => {
        setResources(response)
      })
    }
  }, [chainId])

  const headerData: string[] = useMemo(
    () => [
      type === 'all' ? 'Member from' : 'Registration',
      'Member',
      'Introducer',
      'Whitelist',
      'Send NFT',
      '',
      ''
    ],
    [type]
  )

  const users = useStore($users)

  const getTruncatedAddress = (address: string) => truncateAddress(address, 2)

  const isDisabledSend = (ethAddress: string, whiteListStatus: string | undefined) =>
    whiteListStatus !== EWhitelistStatuses.ACCEPTED ||
    !isEditorUp ||
    ethAddress.toLowerCase() === TREASURY_ADDRESS_US.toLowerCase() ||
    ethAddress.toLowerCase() === TREASURY_ADDRESS.toLowerCase()

  const renderAddressesRow = (row: User, id: string, isIntroducerColumn = false) => {
    const address = isIntroducerColumn ? row?.buddy?.ethAddress || '' : row.ethAddress
    const name = isIntroducerColumn ? row?.buddy?.name || '' : row.name
    const truncatedAddress = getTruncatedAddress(address)

    const isShowIcon = isIntroducerColumn ? row.buddy?.country === 'US' : row.country === 'US'

    return (
      <AddressesCell
        address={address}
        name={name}
        id={id}
        isShowUsIcon={isShowIcon}
        isNoAccount={truncatedAddress === 'No Account'}
        isNoAccountMsg={'dApp not completed'}
      />
    )
  }

  return (
    <TableContainer>
      <Table aria-label="customized table" sx={{ minWidth: '900px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {users?.items
            ? users.items.map((row: User, idx) => (
                <BodyRow key={`${row.id}-${idx}`} rowTemplate={rowTemplate}>
                  <CellPrimary>
                    {type === 'all' ? (
                      row.memberFrom ? (
                        <Tooltip
                          title={
                            <>
                              {moment(row.memberFrom).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                              <br />
                              {moment(row.memberFrom).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                            </>
                          }
                          placement="top"
                          arrow
                        >
                          <div>
                            {moment(row.memberFrom).utc().format('DD MMM YYYY HH:mm')} (GMT)
                          </div>
                        </Tooltip>
                      ) : (
                        <div>Pending triber</div>
                      )
                    ) : (
                      <Tooltip
                        title={
                          <>
                            {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                            <br />
                            {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                          </>
                        }
                        placement="top"
                        arrow
                      >
                        <div>{moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)</div>
                      </Tooltip>
                    )}
                    {row.hidden && (
                      <HideIconWrapper mt={1}>
                        <VisibilityOffOutlinedIcon fontSize="small" />
                      </HideIconWrapper>
                    )}
                  </CellPrimary>
                  <CellPrimary>{renderAddressesRow(row, `${idx}-1`, false)}</CellPrimary>
                  <CellPrimary>{renderAddressesRow(row, `${idx}-2`, true)}</CellPrimary>
                  <CellPrimary>
                    {(row.whiteListStatus === EWhitelistStatuses.ACCEPTED && (
                      <WhiteListStatusBox status={EWhitelistStatuses.ACCEPTED}>
                        Approved
                      </WhiteListStatusBox>
                    )) ||
                      (row.whiteListStatus === EWhitelistStatuses.PENDING && (
                        <WhiteListStatusBox status={EWhitelistStatuses.PENDING}>
                          Pending
                        </WhiteListStatusBox>
                      )) ||
                      (row.whiteListStatus === EWhitelistStatuses.REJECTED && (
                        <WhiteListStatusBox status={EWhitelistStatuses.REJECTED}>
                          Rejected
                        </WhiteListStatusBox>
                      )) ||
                      'Not registered'}
                  </CellPrimary>
                  <CellPrimary>
                    <Box pl={3}>
                      <SendIcon
                        sx={{
                          cursor: isDisabledSend(row.ethAddress, row.whiteListStatus)
                            ? 'default'
                            : 'pointer',
                          marginBottom: '-6px',
                          color: isDisabledSend(row.ethAddress, row.whiteListStatus)
                            ? colors.$disabledBg2
                            : colors.$blue
                        }}
                        onClick={() =>
                          !isDisabledSend(row.ethAddress, row.whiteListStatus) &&
                          setSendNftAddress(row.ethAddress)
                        }
                      />
                    </Box>
                  </CellPrimary>
                  <CellPrimary>
                    <MemberModal
                      type="edit"
                      ethAddress={row.ethAddress}
                      disabled={
                        row.ethAddress.toLowerCase() === TREASURY_ADDRESS_US.toLowerCase() ||
                        row.ethAddress.toLowerCase() === TREASURY_ADDRESS.toLowerCase()
                      }
                    />
                  </CellPrimary>
                  <CellPrimary>
                    <StyledWrapper>
                      <HideUnhideFunctionality
                        data={row}
                        callback={() => triggerUsers()}
                        disabled={!isEditorUp}
                      />
                    </StyledWrapper>
                  </CellPrimary>
                </BodyRow>
              ))
            : null}
        </>
      </Table>
      {isEditor ? (
        <BookNFTModal
          isOpen={Boolean(sendNftAddress && isEditor)}
          setIsOpen={() => setSendNftAddress(false)}
          defaultWallet={sendNftAddress ? sendNftAddress : undefined}
          resources={resources}
        />
      ) : (
        <SendNFTModal
          isOpen={Boolean(sendNftAddress && !isEditor)}
          setIsOpen={() => setSendNftAddress(false)}
          defaultWallet={sendNftAddress ? sendNftAddress : undefined}
          resources={resources}
        />
      )}
    </TableContainer>
  )
}

export default AllTribersTable

interface WhiteListStatusBoxProps {
  status: WhitelistTypes
}

const rowTemplate = css`
  grid-template-columns:
    minmax(230px, 1fr) repeat(2, minmax(160px, 1fr)) 125px minmax(75px, 100px) minmax(75px, 100px)
    75px;
`

const WhiteListStatusBox = styled(Box)<WhiteListStatusBoxProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ status }) =>
    (status === EWhitelistStatuses.ACCEPTED && colors.$black) ||
    (status === EWhitelistStatuses.PENDING && '#F6B91C') ||
    (status === EWhitelistStatuses.REJECTED && '#E55252')};
`

const HideIconWrapper = styled(Box)`
  margin-top: 13px;
  margin-bottom: -10px;
  height: 20px;
  svg {
    fill: #293f6a;
  }
`
const StyledWrapper = styled(Box)`
  width: 30px;
  position: relative;
  top: -5px;
`
