import React from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { colors } from '../../utils/colorVariables'

export const MaintenanceModePage: React.FC = () => (
  <Wrapper>
    <div>
      <Title>We are working on a scheduled upgrade</Title>
      <Content>
        <span>Please check back soon.</span> For urgent messages, feel free to use the chat in the
        bottom-right corner or send and email to support@sixthsociety.com
      </Content>
    </div>
  </Wrapper>
)

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 25%;
`

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 32px;
`

const Content = styled('div')`
  font-size: 20px;
  line-height: 32px;
  color: ${colors.$default};
  font-weight: 300;

  span {
    font-weight: 600;
  }
`
