import { jsPDF } from 'jspdf'
import moment from 'moment'

import logoDark from 'src/assets/logo_dark.png'
import { SaleForReceipt } from 'src/types'
import { colors } from 'src/utils/colorVariables'

import { InterFont, InterFontBold } from './inter-font'
import { ECountry } from 'src/state/effector/users/user.types'

const lineHeight = 1.5

// 1px = 0.75pt

// A4 size = 595 × 842 pts

interface Props {
  sale: SaleForReceipt
  address: string
  nftData: { name: string; amount: string }
  infos: string[]
}

export const generatePDF = async ({ sale, address, nftData, infos }: Props) => {
  const xOffset = 24
  let yOffset = 24
  const doc = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4', putOnlyUsedFonts: true })

  doc.addFileToVFS('InterFont.ttf', InterFont)
  doc.addFileToVFS('InterFontBold.ttf', InterFontBold)
  doc.addFont('InterFont.ttf', 'Inter', 'normal')
  doc.addFont('InterFontBold.ttf', 'Inter', 'normal', 'bold')

  doc.setFont('Inter', 'bold')

  // HEADER
  // 22.5 = 30px
  doc.addImage(logoDark, 'png', 595 - xOffset - 135, yOffset, 135, 22.5, 'NONE', 'NONE')
  doc.setFontSize(22.5)
  doc.text('Receipt', xOffset, yOffset, { baseline: 'top' })
  yOffset += 22.5 * lineHeight

  // 12 = 16px
  doc.setFontSize(12)
  doc.text(
    `No. ${sale.country === ECountry.NonUS ? 'WO' : 'US'}${sale.receiptId}`,
    xOffset,
    yOffset,
    { baseline: 'top' }
  )
  yOffset += 12 * lineHeight

  // gap:32px
  yOffset += 24

  doc.setFontSize(12)
  doc.text('Date:', xOffset, yOffset, { baseline: 'top' })
  yOffset += 12 * lineHeight

  // 13.5 = 18px
  doc.setFont('Inter', 'normal')
  doc.setFontSize(13.5)
  doc.text(moment(sale.createdAt).format('DD MMM YYYY HH:mm'), xOffset, yOffset, {
    baseline: 'top'
  })
  yOffset += 13.5 * lineHeight

  // gap:16px
  yOffset += 12

  doc.setFont('Inter', 'bold')
  doc.setFontSize(12)
  doc.text('Bill To:', xOffset, yOffset, { baseline: 'top' })
  yOffset += 12 * lineHeight

  if (sale.user.name) {
    doc.setFont('Inter', 'normal')
    doc.setFontSize(13.5)
    doc.text(sale.user.name, xOffset, yOffset, { baseline: 'top' })
    yOffset += 13.5 * lineHeight
  }

  address.split('\n').map((text) => {
    doc.text(text, xOffset, yOffset, { baseline: 'top' })
    yOffset += 13.5 * lineHeight
  })

  // gap:16px
  yOffset += 12

  doc.setFont('Inter', 'bold')
  doc.setFontSize(12)
  doc.text('Transaction:', xOffset, yOffset, { baseline: 'top' })
  yOffset += 12 * lineHeight

  doc.setFont('Inter', 'normal')
  doc.setFontSize(13.5)
  doc.text(sale.txHash, xOffset, yOffset, { baseline: 'top' })
  yOffset += 13.5 * lineHeight

  // gap:32px
  yOffset += 24

  doc.setDrawColor(colors.$grayLight)
  doc.setLineWidth(1)
  doc.line(xOffset, yOffset, 595 - 24, yOffset)
  yOffset += 1

  // gap:16px
  yOffset += 12

  doc.setFont('Inter', 'bold')
  doc.setFontSize(12)
  doc.text('Description', xOffset, yOffset, { baseline: 'top' })
  doc.text('Amount', 595 - xOffset - doc.getTextWidth('Amount'), yOffset, { baseline: 'top' })
  yOffset += 12 * lineHeight

  // gap:16px
  yOffset += 12

  doc.setFont('Inter', 'normal')
  doc.setFontSize(12)

  doc.text(nftData.name, xOffset, yOffset, { baseline: 'top' })
  doc.text(nftData.amount, 595 - xOffset - doc.getTextWidth(nftData.amount), yOffset, {
    baseline: 'top'
  })
  yOffset += 12 * lineHeight

  // gap:16px
  yOffset += 12

  doc.setDrawColor(colors.$grayLight)
  doc.setLineWidth(1)
  doc.line(xOffset, yOffset, 595 - 24, yOffset)
  yOffset += 1

  // gap:16px
  yOffset += 12

  const investment = `$ ${Number(sale.investment).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`

  doc.setFont('Inter', 'bold')
  doc.setFontSize(12)
  doc.text('Payment Due', xOffset, yOffset, { baseline: 'top' })
  doc.text(investment, 595 - xOffset - doc.getTextWidth(investment), yOffset, {
    baseline: 'top'
  })
  yOffset += 12 * lineHeight

  // gap:16px
  yOffset += 12

  doc.text('Payment Received', xOffset, yOffset, { baseline: 'top' })
  doc.text(investment, 595 - xOffset - doc.getTextWidth(investment), yOffset, {
    baseline: 'top'
  })
  yOffset += 12 * lineHeight

  // gap:16px
  yOffset += 12
  const balanceDue = `$ ${Number(0).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`
  doc.text('Balance Due (USD)', xOffset, yOffset, { baseline: 'top' })
  doc.text(balanceDue, 595 - xOffset - doc.getTextWidth(balanceDue), yOffset, {
    baseline: 'top'
  })
  yOffset += 12 * lineHeight

  // gap:64px
  yOffset += 48

  doc.setFont('Inter', 'normal')
  doc.setFontSize(12)
  infos.map((txt) => {
    doc.text(txt, xOffset, yOffset, { baseline: 'top' })
    yOffset += 12 * lineHeight
  })

  // gap:32px
  yOffset += 24

  doc.text('NEED HELP? CONTACT US 24/7: SUPPORT@SIXTHSOCIETY.COM', 595 / 2, 842 - 36, {
    baseline: 'top',
    align: 'center'
  })

  doc.save(`receipt-${sale.country === ECountry.NonUS ? 'WO' : 'US'}${sale.receiptId}.pdf`)
}
