import styled from '@emotion/styled'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'

import { ButtonPrimary } from 'src/components/Button'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { AppLogo } from 'src/components/Logo'
import { MEDIA_WIDTHS } from 'src/constants'
import { colors } from 'src/utils/colorVariables'
import { SaleForReceipt } from 'src/types'
import apiService from 'src/services/api'
import { ECountry } from 'src/state/effector/users/user.types'
import downloadIcon from 'src/assets/download.png'

import { generatePDF } from './generate-pdf'

const oldAddresses = {
  nonUS: ['Block Prime Ltd', '27 Old Gloucester Street', 'London', 'WC1N 3AX', 'United Kingdom'],
  us: ['Sixth Society US Inc.', '360 NW 27th Street', 'Miami', 'FL 33127', 'United States']
}

const dateForNewAddresses = moment('2024-06-26')

const newAddresses = {
  nonUS: [
    'Sixth Society OÜ',
    'Telliskivi tn 57',
    'Põhja-Tallinna linnaosa 10412 Tallinn',
    'Estonia'
  ],
  us: ['SIXTH SOCIETY US INC', '360 NW 27th Street', 'Miami, FL 33127', 'United States']
}

interface Props {
  id?: number
  fallBack?: () => void
}

export const DownloadReceiptPage = ({ id: idFromProps, fallBack }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [address, setAddress] = useState(``)

  const [sale, setSale] = useState<SaleForReceipt | null>(null)

  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    if (idFromProps || id) {
      const fetchSale = async () => {
        try {
          const res = await apiService.getSellTransaction(idFromProps || Number(id))
          if (res.data) {
            setSale(res.data)
          } else {
            toast.error('Failed to get transaction for receipt')
            if (!idFromProps) {
              navigate('/')
            } else if (fallBack) {
              fallBack()
            }
          }
        } catch (error) {
          toast.error('Failed to get transaction for receipt')
          if (!idFromProps) {
            navigate('/')
          } else if (fallBack) {
            fallBack()
          }
        }
      }
      fetchSale()
    }
  }, [id, idFromProps])

  useEffect(() => {
    if (sale) {
      setAddress(
        sale.user.address
          ? sale.user.address
              .split('\n')
              .filter((txt) => txt)
              .join('\n')
          : ''
      )
    }
  }, [sale])

  const infos = useMemo(() => {
    if (sale) {
      const addressInfo = moment(sale.createdAt).isAfter(dateForNewAddresses)
        ? newAddresses
        : oldAddresses
      return sale.country === ECountry.NonUS ? addressInfo.nonUS : addressInfo.us
    }

    return []
  }, [sale])

  const nftData = useMemo((): { name: string; amount: string } => {
    if (sale?.amount) {
      const price = (+sale.investment / +sale.amount).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })

      return {
        name: `${sale?.amount} x ${
          sale.nftType === 'Second' ? 'Annual' : 'Founding'
        } Member ($${price})`,
        amount: `$ ${(+sale.investment).toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}`
      }
    }

    return { name: '-', amount: '-' }
  }, [sale?.amount])

  const onDownload = async () => {
    try {
      if (!sale) return
      setIsLoading(true)
      await generatePDF({ sale, address, nftData, infos })
      toast.success('Receipt was downloaded successfully')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  if (!sale) return <LoadingIndicator />

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      <ActionBtn onClick={onDownload}>
        <img src={downloadIcon} width="18px" />
        Download
      </ActionBtn>

      <PDF>
        <Header>
          <Title>
            <div>Receipt</div>
            <div>
              No. {sale.country === ECountry.NonUS ? 'WO' : 'US'}
              {sale.receiptId}
            </div>
          </Title>
          <AppLogo width="180px" logoView="dark" />
        </Header>

        <ReceiptInfo>
          <div>
            <b>Date:</b> <br /> {moment(sale.createdAt).format('DD MMM YYYY HH:mm')}
          </div>
          <div>
            <b>Bill To:</b>
            <br />
            {sale.user.name}
            <br />
            {(address || '').split('\n').map((text) => (
              <div key={text}>{text}</div>
            ))}
          </div>
          <div>
            <b>Transaction:</b>
            <br />
            {sale.txHash}
          </div>
        </ReceiptInfo>
        <Purchased>
          <div className="hr" />
          <div>
            <div>Description</div>
            <div>Amount</div>
          </div>

          <div>
            <span>{nftData.name}</span>
            <span>{nftData.amount}</span>
          </div>

          <div className="hr" />
          <div>
            <div>Payment Due</div>
            <div>
              $&nbsp;
              {Number(sale.investment).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </div>
          </div>
          <div>
            <div>Payment Received</div>
            <div>
              $&nbsp;
              {Number(sale.investment).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </div>
          </div>
          <div>
            <div>Balance Due (USD)</div>
            <div>
              $&nbsp;
              {Number(0).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </div>
          </div>
        </Purchased>

        <Infos>
          {infos.map((txt: string) => (
            <div key={txt}>{txt}</div>
          ))}
        </Infos>
        <Tip>NEED HELP? CONTACT US 24/7: SUPPORT@SIXTHSOCIETY.COM</Tip>
      </PDF>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 32px 0px;
`

const PDF = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border: 1px solid ${colors.$borderSecondary};
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    padding: 16px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: baseline;
`

const ReceiptInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    word-break: break-word;
    font-size: 18px;
  }
  b {
    font-size: 16px;
  }
  .MuiInputBase-root {
    padding: 4px 8px;
    margin-left: -8px;
  }
`

const ActionBtn = styled(ButtonPrimary)`
  /* margin-left: auto; */
  max-width: 150px;
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-right: 16px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;

  > div:first-of-type {
    font-size: 30px;
  }
`

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 32px 0px;

  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    grid-template-columns: 1fr;
  }
`

const Purchased = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: bold;
  span {
    font-weight: 400;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
  .hr {
    width: 100%;
    height: 1px;
    background-color: ${colors.$grayLight};
  }
  > div:first-of-type {
    font-weight: bold;
  }
`

const Total = styled.div`
  margin-top: -24px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
`

const Note = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  input {
    padding: 8px;
  }
`

const Tip = styled.div`
  font-size: 14px;
  text-align: center;
`
