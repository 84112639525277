import React from 'react'
import { Box, Tooltip } from '@mui/material'

import warningIcon from 'src/assets/warning.png'

import { ItemContainer, DescriptionWrapper, ValueWrapper } from './styles'
import { AddressesCell } from '../../Table/AddressesCell'

interface Props {
  title: string
  value: string
  isNotAvailable?: boolean
  valueString: string
  icon?: JSX.Element
  address?: string
}

export const AvailableContainer: React.FC<Props> = ({
  title,
  value,
  isNotAvailable,
  valueString,
  icon,
  address
}) => {
  return (
    <ItemContainer isNotAvailable={isNotAvailable}>
      <Box display="flex" mb={1} alignItems="center" gap="4px">
        {icon}
        <DescriptionWrapper mr={1}>{title}</DescriptionWrapper>
        {isNotAvailable && (
          <Tooltip title="Contact a manager to top-up" placement="top" arrow>
            <img src={warningIcon} alt="warningIcon" />
          </Tooltip>
        )}
      </Box>
      {address && (
        <Box marginLeft={icon ? '20px' : 0}>
          <AddressesCell id={address} address={address} addressClassName="wallet" />
        </Box>
      )}
      <Tooltip title={value} placement="top-start" arrow>
        <ValueWrapper>
          <div className="value">{value}</div>
          {valueString && (
            <>
              &nbsp;
              <span className="valueString">{valueString}</span>
            </>
          )}
        </ValueWrapper>
      </Tooltip>
    </ItemContainer>
  )
}
