import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { isMobile } from 'src/utils/isMobile'
import { colors } from 'src/utils/colorVariables'
import { PendingWhitelist, QuickActions } from 'src/components/AdminPanel/Dashboard'
import { Resources } from 'src/components/AdminPanel/Dashboard/Resources'
import { SendNFTModal } from '../../../components/AdminPanel/SendNFTModal'
import { HrLine } from '../../../components/HRLine'
import { useAccessController } from '../../../hooks/useAccessController'
import { UserRoles } from '../../../state/effector/users/user.types'
import { BookNFTModal } from '../../../components/AdminPanel/BookNFTModal'
import apiService from '../../../services/api'
import { IResources } from '../../../types/common.types'
import useMetaMask from '../../../hooks/useMetaMask'
import { PendingTransfers } from '../../../components/AdminPanel/Dashboard/PendingTransfers'
import { PendingNftUpgrades } from '../../../components/AdminPanel/Dashboard/pending-nft-upgrades'

const DashboardPage = () => {
  const mobile = isMobile()
  const [isSendNftOpen, setIsSendNftOpen] = useState(false)
  const [isBookNftOpen, setIsBookNftOpen] = useState(false)
  const { isAllowed: isEditor } = useAccessController(UserRoles.Editor, true)
  const { isAllowed: allowedEditorUp } = useAccessController(UserRoles.Editor)
  const { isAllowed: allowedAdminUp } = useAccessController(UserRoles.Admin)
  const { chainId } = useMetaMask()

  const [resources, setResources] = useState<IResources>({})

  useEffect(() => {
    if (chainId) {
      apiService.getResources(chainId).then((response) => {
        setResources(response)
      })
    }
  }, [chainId])

  return (
    <Box sx={{ minHeight: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '25px',
          marginTop: mobile ? '25px' : 0
        }}
      >
        <Typography
          color={colors.$secondary}
          sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300 }}
          variant="subtitle1"
        >
          Dashboard
        </Typography>
      </Box>
      <Box>
        <QuickActions
          setIsSendNftOpen={(data) => (isEditor ? setIsBookNftOpen(data) : setIsSendNftOpen(data))}
        />
      </Box>
      <HrLine />
      {allowedEditorUp && (
        <Box>
          <PendingWhitelist />
        </Box>
      )}
      {allowedEditorUp && (
        <Box>
          <PendingTransfers />
        </Box>
      )}
      {allowedAdminUp && (
        <Box>
          <PendingNftUpgrades />
        </Box>
      )}
      <Box mt={3}>
        <Resources resources={resources} />
      </Box>
      {isEditor ? (
        <BookNFTModal isOpen={isBookNftOpen} setIsOpen={setIsBookNftOpen} resources={resources} />
      ) : (
        <SendNFTModal isOpen={isSendNftOpen} setIsOpen={setIsSendNftOpen} resources={resources} />
      )}
    </Box>
  )
}

export default DashboardPage
