import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { Box } from '@mui/system'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { FormControl, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { validationSchema } from './validation'
import CustomModal from 'src/components/Modal/customModal'
import { AddTeamMember, UserRoles } from '../../../../state/effector/users/user.types'
import { colors } from '../../../../utils/colorVariables'
import { TextFieldPrimary } from '../../../TextField'
import ErrorMessage from '../../../ErrorMessage'
import { isMobile } from '../../../../utils/isMobile'
import RoleSelector from './RoleSelector'
import { ButtonDangerOutlined, ButtonPrimary, ButtonSuccessOutlined } from '../../../Button'
import { isAddressValid } from '../../../../utils/truncateAddress'
import apiService from '../../../../services/api'
import { triggerUsers } from '../../../../state/effector/users/users.store'
import { useAccessController } from 'src/hooks/useAccessController'

export enum EModalTypes {
  Add = 'Add',
  Edit = 'Edit'
}

interface IProps {
  isOpen: boolean
  type: EModalTypes
  ethAddress?: string
  handleClose: () => void
}

interface IState {
  name: string
  ethAddress: string
  role: UserRoles
  deactivated: boolean
}

type StateFields = 'name' | 'ethAddress' | 'role'
interface IWalletErrors {
  ethAddress?: string
}

export const getErrorMessage = (error: AxiosError<Error>) =>
  error?.response?.data?.message || 'Something went wrong'

// Add or Edit team modal
const TeamModal: React.FC<IProps> = ({
  isOpen,
  type = EModalTypes.Add,
  ethAddress,
  handleClose
}) => {
  const isAdd = type === EModalTypes.Add
  const isEdit = type === EModalTypes.Edit
  const mobile = isMobile()

  const { isNotAllowed } = useAccessController(UserRoles.SuperAdmin, true)

  const initialValues: IState = useMemo(
    () => ({
      name: '',
      ethAddress: '',
      role: UserRoles.Viewer,
      deactivated: false
    }),
    []
  )

  const [values, setValues] = useState<IState>(initialValues)
  const [walletErrors, setWalletErrors] = useState<IWalletErrors>({})
  const [isDeactivateMember, setIsDeactivateMember] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isValidationLoading, setIsValidationLoading] = useState(false)

  const validateAddress = async (name: string, value: string) => {
    let errorMessage = ''
    const params = {
      ethAddress: value,
      deactivated: true,
      role: formik.values.role
    }

    if (!value.length) return null

    if (value.length && !isAddressValid(value)) {
      errorMessage = 'Invalid wallet format, should be an Ethereum address'
      setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
      return
    }
    await apiService
      .addTeamMemberValidate(params)
      .then(() => {
        errorMessage = ''
      })
      .catch((err) => {
        errorMessage = getErrorMessage(err)
      })
    setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
  }
  const changeAddress = async (name: string, value: string, noError = false) => {
    await formik.setFieldValue(name, value)
    await formik.setFieldTouched(name, true)

    if (noError) return
    setIsValidationLoading(true)
    await validateAddress(name, value)
    setIsValidationLoading(false)
  }
  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    changeAddress(name, value)
  }

  const handleChangeRole = async (value: UserRoles) => {
    const name = 'role'
    await formik.setFieldValue(name, value)
    await formik.setFieldTouched(name, true)
  }
  const onSubmit = async (submitValues: IState) => {
    setIsLoading(true)

    const submitData: AddTeamMember = {
      name: submitValues.name,
      ethAddress: submitValues.ethAddress,
      role: submitValues.role,
      deactivated: submitValues.deactivated
    }

    if (isAdd) {
      await apiService
        .addTeamMember(submitData)
        .then(() => {
          handleCloseModal()
          toast.success('Added successfully')
          triggerUsers()
        })
        .catch(({ response }) => {
          console.log('response', response)
          if (response?.data?.message) {
            toast.error('Error: ' + getErrorMessage(response))
          } else toast.error('Error: Not added')
        })
        .finally(() => setIsLoading(false))
    }

    if (isEdit) {
      await apiService
        .editTeamMember(submitData)
        .then(() => {
          toast.success('Changes saved')
          triggerUsers()
          handleCloseModal()
        })
        .catch(() => {
          toast.error('Changes not saved')
        })
        .finally(() => setIsLoading(false))
    }
    if (isLoading) setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  })

  const handleCloseModal = useCallback(() => {
    const handleReset = () => {
      formik.resetForm()
      setValues(initialValues)
      setWalletErrors({})
      setIsDeactivateMember(false)
      setIsLoading(false)
      setIsValidationLoading(false)
    }

    handleReset()
    handleClose()
  }, [handleClose, formik, initialValues])

  // Close modal if no access to activate team member
  useEffect(() => {
    if (isDeactivateMember && isNotAllowed) {
      handleCloseModal()
    }
  }, [isDeactivateMember, isNotAllowed])

  // Preset data
  useEffect(() => {
    if (isOpen && isEdit && ethAddress) {
      setIsLoading(true)
      apiService
        .getUserByEthAddress(ethAddress)
        .then((response) => {
          if (response?.data) {
            const member = response?.data
            setValues({
              name: member.name,
              ethAddress,
              role: member.role,
              deactivated: member.deactivated
            })
            setIsDeactivateMember(member.deactivated)
          }
        })
        .catch((response) => {
          toast.error(getErrorMessage(response))
          handleClose()
        })
        .finally(() => setIsLoading(false))
    }
  }, [isEdit, ethAddress, isOpen, handleClose])

  const changeDeactivateMemberStatus = async (status: boolean) => {
    const name = 'deactivated'
    await formik.setFieldValue(name, status)
    await formik.setFieldTouched(name, true)
    formik.handleSubmit()
  }

  const isError = (fieldName: StateFields) =>
    (formik.touched &&
      formik.touched[fieldName] &&
      formik.errors &&
      formik.errors[fieldName] &&
      true) ||
    (fieldName === 'ethAddress' && !!walletErrors[fieldName]) ||
    false

  const isValid = useMemo(() => !walletErrors.ethAddress, [walletErrors.ethAddress])

  return (
    <CustomModal
      isOpen={isOpen}
      title={`${isAdd ? 'Add' : 'Edit'} Team Member`}
      handleClose={handleCloseModal}
      isLoading={isLoading || isValidationLoading}
    >
      <form onSubmit={formik.handleSubmit} id="team-modal-form">
        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <LabelStyled>
            <TypographyWrapperByName mobile={mobile} color={colors.$black} variant="body1">
              Name{' '}
            </TypographyWrapperByName>

            <TextFieldPrimary
              name="name"
              value={formik.values.name}
              placeholder="Name Surname"
              onChange={formik.handleChange}
              invalid={isError('name')}
            />
          </LabelStyled>
          <ErrorMessage message={formik.errors?.name} isError={isError('name')} />
        </FormControl>

        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
            {"Member's wallet"}
          </TypographyWrapper>
          <TextFieldPrimary
            name="ethAddress"
            value={formik.values.ethAddress}
            onChange={handleChangeAddress}
            invalid={isError('ethAddress')}
            placeholder="Please specify the wallet"
            disabled={type === EModalTypes.Edit}
          />
          <ErrorMessage
            message={formik.errors?.ethAddress || walletErrors.ethAddress}
            isError={isError('ethAddress')}
          />
        </FormControl>

        <FormControl fullWidth>
          <RoleSelector role={formik.values.role || UserRoles.Viewer} onClick={handleChangeRole} />
        </FormControl>

        <Box mt={3} />
        <>
          {isAdd && (
            <ButtonPrimary
              type="submit"
              btnSize="sm"
              disabled={
                !(formik.isValid && formik.dirty && isValid && formik.values.name) || isLoading
              }
            >
              <Typography
                sx={{
                  fontSize: mobile ? '18px' : '16px',
                  textTransform: 'capitalize',
                  fontWeight: 600
                }}
              >
                Add Team Member
              </Typography>
            </ButtonPrimary>
          )}
          {isEdit &&
            (isDeactivateMember ? (
              <ButtonSuccessOutlined
                onClick={() => changeDeactivateMemberStatus(false)}
                btnSize="sm"
                disabled={isNotAllowed}
              >
                <Typography
                  sx={{
                    fontSize: mobile ? '18px' : '16px',
                    textTransform: 'capitalize',
                    fontWeight: 600
                  }}
                >
                  Re-activate Team Member
                </Typography>
              </ButtonSuccessOutlined>
            ) : (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ButtonPrimary
                    type="submit"
                    btnSize="sm"
                    disabled={!(formik.isValid && formik.dirty && isValid) || isLoading}
                  >
                    <Typography
                      sx={{
                        fontSize: mobile ? '18px' : '16px',
                        textTransform: 'capitalize',
                        fontWeight: 600
                      }}
                    >
                      Save Changes
                    </Typography>
                  </ButtonPrimary>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ButtonDangerOutlined
                    onClick={() => changeDeactivateMemberStatus(true)}
                    btnSize="sm"
                    disabled={isNotAllowed}
                  >
                    <Typography
                      sx={{
                        fontSize: mobile ? '18px' : '16px',
                        textTransform: 'capitalize',
                        fontWeight: 600
                      }}
                    >
                      Deactivate
                    </Typography>
                  </ButtonDangerOutlined>
                </Grid>
              </Grid>
            ))}
        </>
      </form>
    </CustomModal>
  )
}

export default TeamModal

interface StyledProps {
  mobile: boolean
}

const LabelStyled = styled('label')`
  & > div {
    width: 100%;
  }
`

const TypographyWrapper = styled(Typography)<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  font-weight: 500;

  span {
    color: ${colors.$secondary2};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const TypographyWrapperByName = styled(TypographyWrapper)`
  @media screen and (max-width: 1600px) {
    margin-top: 5px;
  }
`
