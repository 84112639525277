import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useFormik } from 'formik'
import { IconButton, useMediaQuery } from '@mui/material'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

import { colors } from 'src/utils/colorVariables'
import { ButtonPrimary } from 'src/components/Button'
import { useIsOpen } from 'src/hooks/use-is-open'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { CHAIN_INFO } from 'src/constants'
import useMetaMask from 'src/hooks/useMetaMask'

import { defaultValues, stepFields } from '../constants'
import { ConfirmModal } from '../confirm-modal'
import { validationSchema } from './validation-schema'
import { TemplatePreset } from '../template-preset'
import { TemplateType } from '../templates-list'

import {
  AirdropName,
  TemplateName,
  AirdropRegion,
  MembersType,
  AirdropBudget,
  CalculationPeriod,
  DistributionRule,
  Voting,
  ManualDistribution
} from '../step-components'
import { DetailsPopup } from '../details-popup'
import { SpecificMember } from '../execute-template/specific-members'

interface Props {
  isOneTime?: boolean
}

let timer = null as any

export const TemplateForm = ({ isOneTime }: Props) => {
  const { id = '' } = useParams()

  const [initialValues, handleInitialValues] = useState<TemplateType | undefined>(undefined)
  const [passedSteps, handlePassedSteps] = useState<Array<number>>(id ? [0, 1, 2, 3, 4, 5, 6] : [0])
  const [calculations, handleCalculations] = useState<Record<string, any>>({})
  const [isLoadingData, handleIsDataLoading] = useState<Record<string, boolean>>({})
  const [draftId, handleDraftId] = useState(null)
  const [event, handleEvent] = useState(null)

  const { chainId } = useMetaMask()

  const navigate = useNavigate()

  const isLaptop = useMediaQuery('@media(min-width: 800px)')

  const isLoading = useMemo(() => Object.values(isLoadingData).some((el) => el), [isLoadingData])

  const { isOpen: isConfirmEditOpen, open: openConfirmEdit, close: closeConfirmEdit } = useIsOpen()
  const {
    isOpen: isConfirmArchiveOpen,
    open: openConfirmArchive,
    close: closeConfirmArchive
  } = useIsOpen()
  const { isOpen: isExecuteOpen, open: openExecute, close: closeExecute } = useIsOpen()

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        handleIsDataLoading((state) => ({ ...state, templates: true }))
        const res = await apiService.getTemplate(+id)
        handleInitialValues({
          ...res.data,
          isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no'
        })
      } catch (error) {
      } finally {
        handleIsDataLoading((state) => ({ ...state, templates: false }))
      }
    }
    if (id) {
      fetchTemplate()
    }
  }, [id])

  const onGoBack = () => {
    navigate('/admin/airdrops')
  }

  const onSubmit = async (values: any) => {
    try {
      handleIsDataLoading((state) => ({ ...state, submit: true }))

      const formattedValues = Object.entries(values).reduce((acc, [key, value]: any) => {
        if (key === 'specificMembers') {
          if (value[0].ethAddress) {
            return { ...acc, [key]: value }
          }
          return acc
        }

        if (key === 'percentage') {
          return { ...acc, [key]: +value }
        }

        if (key === 'isVotingRequired') {
          return { ...acc, [key]: value === 'yes' }
        }

        if (value) {
          return { ...acc, [key]: value }
        }
        return acc
      }, {})

      if (isOneTime) {
        const draft = await apiService.createOneTimeEvent({
          network: CHAIN_INFO[chainId].networkType,
          name: values.name,
          region: values.region,
          category: values.category,
          rewardType: values.rewardType,
          frequency: values.frequency,
          distribution: values.distribution,
          isVotingRequired: values.isVotingRequired,
          ...(values.rewardType === 'IsPercentage' && {
            percentage: +values.percentage
          }),
          ...(values.rewardType === 'IsFixedAmount' && {
            budget: calculations.budget || values.amountSum
          }),

          ...(values.specificMembers && {
            members: values.specificMembers.map((member: SpecificMember) => ({
              address: member.ethAddress,
              amount: member.amount
            }))
          })
        })
        handleDraftId(draft.data.id)
        handleEvent({ ...draft.data, isVotingRequired: draft.data.isVotingRequired ? 'yes' : 'no' })
        openExecute()
        return
      }

      if (id) {
        await apiService.updateAirdropTemplate({ templateId: +id, data: formattedValues })
      } else {
        await apiService.createAirdropTemplate(formattedValues)
      }

      toast.success(id ? 'Template successfully edited.' : 'Template successfully created.')
      onGoBack()
    } catch (e: any) {
      if (isOneTime) {
        const message = e.response.data.message || 'Failed to execute. Please try again.'
        toast.error(message)
      } else {
        toast.error(`Failed to ${id ? 'edit' : 'create'} template. Please try again.`)
      }
    } finally {
      handleIsDataLoading((state) => ({ ...state, submit: false }))
    }
  }

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    validateForm,
    touched,
    submitForm,
    setFieldError,
    setFieldTouched
  } = useFormik({
    initialValues: initialValues || defaultValues,
    validationSchema: validationSchema(passedSteps.length, Boolean(isOneTime)),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const fetchCalculations = async () => {
    try {
      handleIsDataLoading((state) => ({ ...state, summary: true }))

      const summary = await apiService.getManualSummary({
        networkId: chainId,
        region: values.region,
        category: values.category,
        rewardType: values.rewardType,
        ...(values.rewardType === 'IsPercentage' && {
          percentage: values.percentage
        }),
        ...(values.rewardType === 'IsFixedAmount' && {
          budget: values.amountSum
        }),
        frequency: values.frequency,
        distribution: values.distribution,
        isVotingRequired: values.isVotingRequired === 'yes'
      })

      setFieldValue('budget', summary.data.budget)

      handleCalculations({
        budget: summary.data.budget,
        calculationPeriod: summary.data.calculationPeriod,
        ...(values.distribution !== 'Manually' && {
          ...(values.distribution !== 'Equally' && { totalPoints: summary.data.totalPoints }),
          pointAmount: summary.data.pointAmount,
          membersCount: summary.data.membersCount
        })
      })
    } catch (error) {
    } finally {
      handleIsDataLoading((state) => ({ ...state, summary: false }))
    }
  }

  useEffect(() => {
    if (
      isOneTime &&
      values.region &&
      values.category &&
      values.rewardType &&
      (values.amountSum || values.percentage) &&
      values.frequency &&
      values.distribution &&
      values.isVotingRequired
    ) {
      clearTimeout(timer)
      timer = setTimeout(fetchCalculations, 250)
    }

    return () => {
      clearTimeout(timer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.region,
    values.category,
    values.rewardType,
    values.amountSum,
    values.percentage,
    values.frequency,
    values.distribution,
    values.isVotingRequired,
    isOneTime
  ])

  const maxSteps = isOneTime && values.category === 'SpecificMembers' ? 8 : 7

  useEffect(() => {
    if (passedSteps.length > maxSteps) {
      handlePassedSteps((state) => {
        state.pop()
        return state
      })
    }
  }, [maxSteps, passedSteps])

  const onNext = useCallback(async () => {
    try {
      await submitForm()

      const res = await validateForm(values)

      if (Object.keys(res).length !== 0) return
      if (!isOneTime) {
        const templates = await apiService.getAirdropTemplate({
          offset: 20,
          duplicate: values.name
        })
        if (
          templates.data.items.length &&
          templates.data.items.some((el: TemplateType) => el.id !== +id)
        ) {
          setFieldError('name', 'A template with that name already exists and can not be repeated.')
          return
        }
      } else if (values.region) {
        const events = await apiService.getAirdropEvents({
          offset: 20,
          duplicate: values.name.replace(/^\s+|\s+$/g, ''),
          region: values.region
        })
        if (
          events.data.items.length &&
          events.data.items.some((el: TemplateType) => el.id !== +id)
        ) {
          setFieldError(
            'name',
            `Event with name '${values.name.replace(/^\s+|\s+$/g, '')}' already exists`
          )
          return
        }
      }

      if (passedSteps.length === maxSteps) {
        if (id) {
          openConfirmEdit()
        } else {
          onSubmit(values)
        }
      } else {
        handlePassedSteps((state) => [...state, state.length])
      }
      setTimeout(() => {
        document.getElementById('endOfForm')?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    } catch (e) {
      console.log('log => error', e)
    }
  }, [values, passedSteps, validateForm, maxSteps, submitForm])

  const changeBudget = (e: any) => {
    if (isLoading) return
    if (values.rewardType === 'IsPercentage') {
      setFieldValue(e.target.name, e.target.value.match(/[0-9]*[.]?[0-9]{0,2}/g)[0] || '', true)
    } else {
      setFieldValue(e.target.name, e.target.value.match(/[0-9]*[.]?[0-9]{0,6}/g)[0] || '', true)
    }
  }

  const actionBtnText = useMemo(() => {
    if (passedSteps.length !== maxSteps) return 'Next Step'
    if (Boolean(id)) return 'Save Changes'
    return isOneTime ? 'Create Airdrop' : 'Create Template'
  }, [passedSteps.length, id, isOneTime, maxSteps])

  const onCloseExecute = async (needDelete = true) => {
    try {
      handleIsDataLoading((state) => ({ ...state, deleteDraft: true }))
      if (draftId && needDelete) {
        await apiService.deleteAirdropDraft(draftId)
      }
      closeExecute()
    } catch (error) {
    } finally {
      handleIsDataLoading((state) => ({ ...state, deleteDraft: false }))
    }
  }

  const confirmEdit = () => {
    onSubmit(values)
  }

  const onArchive = async () => {
    try {
      handleIsDataLoading((state) => ({ ...state, archive: true }))
      await apiService.archiveTemplate(+id)
      navigate('/admin/airdrops')
      toast.success('Template successfully archived.')
    } catch (error) {
      toast.success('Failed to archive template. Please try again.')
    } finally {
      handleIsDataLoading((state) => ({ ...state, archive: false }))
    }
  }

  const disabledNext = useMemo(() => {
    if (calculations.calculationPeriod) {
      return Object.values(calculations).some((value) => !value)
    }

    return false
  }, [calculations])

  if (!isLaptop) return <UseLaptop>Use a device with bigger screen width for this page</UseLaptop>

  return (
    <>
      {isOneTime && isExecuteOpen && (
        <DetailsPopup
          draftId={draftId}
          handleClose={onCloseExecute}
          calculations={calculations}
          event={event}
        />
      )}
      {isLoading && <LoadingIndicator />}

      {isConfirmEditOpen && (
        <ConfirmModal
          isOpen
          handleClose={closeConfirmEdit}
          title="Do you want to save changes?"
          onSubmit={confirmEdit}
        />
      )}
      {isConfirmArchiveOpen && (
        <ConfirmModal
          isOpen
          error
          handleClose={closeConfirmArchive}
          title={`Do you want to archive this template?`}
          onSubmit={onArchive}
        />
      )}
      <Container>
        <Form id="template-form">
          <div className="header">
            <IconButton onClick={onGoBack}>
              <ArrowBackIcon sx={{ color: '#111213' }} />
            </IconButton>
            <div>
              {isOneTime ? 'Manual Airdrop' : `${id ? 'Edit' : 'Creation'} Airdrop Template`}
            </div>
          </div>
          <StepsContainer>
            {passedSteps.includes(0) && (
              <>
                {isOneTime ? (
                  <AirdropName
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name ? errors.name : ''}
                    setFieldTouched={setFieldTouched}
                  />
                ) : (
                  <TemplateName
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name ? errors.name : ''}
                    setFieldTouched={setFieldTouched}
                  />
                )}
              </>
            )}
            {passedSteps.includes(1) && (
              <AirdropRegion
                value={values.region}
                error={touched.region ? errors.region : ''}
                onChange={(value: string) => setFieldValue('region', value, true)}
              />
            )}
            {passedSteps.includes(2) && (
              <MembersType
                value={values.category}
                error={touched.category ? errors.category : ''}
                onChange={(value: string) => {
                  if (value === 'SpecificMembers') {
                    setFieldValue('distribution', 'Manually', true)
                  } else if (values.distribution === 'Manually') {
                    setFieldValue('distribution', 'TribeSize', true)
                  }
                  setFieldValue('category', value, true)
                }}
              />
            )}

            {passedSteps.includes(3) && (
              <AirdropBudget
                value={values.rewardType}
                error={touched.rewardType ? errors.rewardType : ''}
                onChange={(value: string) => setFieldValue('rewardType', value, true)}
                isOneTime={isOneTime}
                changeBudget={changeBudget}
                amountValue={values.amountSum}
                percentValue={values.percentage}
                amountError={touched.amountSum ? errors.amountSum : ''}
                percentError={touched.percentage ? errors.percentage : ''}
              />
            )}
            {passedSteps.includes(4) && (
              <CalculationPeriod
                value={values.frequency}
                error={touched.frequency ? errors.frequency : ''}
                onChange={(value: string) => setFieldValue('frequency', value, true)}
              />
            )}
            {passedSteps.includes(5) && (
              <DistributionRule
                value={values.distribution}
                category={values.category}
                error={touched.distribution ? errors.distribution : ''}
                onChange={(value: string) => setFieldValue('distribution', value, true)}
              />
            )}
            {passedSteps.includes(6) && (
              <Voting
                value={values.isVotingRequired as string}
                error={touched.isVotingRequired ? errors.isVotingRequired : ''}
                onChange={(value: string) => setFieldValue('isVotingRequired', value, true)}
              />
            )}
            {passedSteps.includes(7) && (
              <ManualDistribution
                value={values.budgetType || 'sum'}
                onChange={(value: string) => setFieldValue('budgetType', value, true)}
                step={8}
                errors={errors}
                touched={touched}
                specificMembers={values.specificMembers}
                setFieldValue={setFieldValue}
                budget={calculations.budget || values.amountSum}
                region={values.region}
                template={values}
              />
            )}
          </StepsContainer>
          <ActionContainer id="actions">
            <ButtonPrimary onClick={onNext} disabled={disabledNext}>
              {actionBtnText}
            </ButtonPrimary>
          </ActionContainer>
          <div id="endOfForm">
            {!isOneTime && (
              <>
                {passedSteps.length === maxSteps &&
                  (Boolean(id) ? (
                    <DeleteButton onClick={openConfirmArchive}>Archive Template</DeleteButton>
                  ) : (
                    <EditTolltip>The template can be edited later. </EditTolltip>
                  ))}
              </>
            )}
          </div>
        </Form>
        <Sidebar>
          <TemplatePreset
            keys={stepFields[passedSteps[passedSteps.length - 1]].split(',')}
            values={values}
            calculations={calculations}
            isExecute={isOneTime}
            isOneTime={isOneTime}
          />
        </Sidebar>
      </Container>
    </>
  )
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 224px;
  column-gap: 80px;
  grid-template-rows: calc(100vh - 88px - 46px - 30px);
`

export const Form = styled.div`
  padding-right: 16px;
  overflow: auto;
  .header {
    display: flex;
    align-items: center;
    gap: 21px;
    margin-bottom: 80px;
    div {
      font-weight: 700;
      text-align: center;
      flex: 1;
    }
  }
`

export const Sidebar = styled.div``

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 32px;
  font-weight: 400;
  font-size: 14px;
  button {
    text-transform: none;
    max-width: 180px;
    min-height: 44px;
  }
  .enterContainer {
    display: flex;
    align-items: center;
    img {
      margin-left: 9px;
    }
  }
  .enter {
    font-weight: 700;
    font-size: 16px;
  }
`

const DeleteButton = styled(ButtonPrimary)`
  margin-top: 24px;
  max-width: 180px;
  text-transform: none;
  background-color: transparent !important;
  color: ${colors.$error};
  min-height: 44px;
`

const EditTolltip = styled.div`
  color: ${colors.$secondary2};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 24px;
`

const UseLaptop = styled.div`
  padding: 32px;
  width: 100%;
  height: 200px;
  font-size: 24px;
  color: ${colors.$secondary};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
