import React from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

import { colors } from 'src/utils/colorVariables'
import { MemberModal } from '../Members/MemberModal'
import SendNFT from '../SendNft/SendNFT'
import { useAccessController } from '../../../hooks/useAccessController'
import { UserRoles } from '../../../state/effector/users/user.types'

interface IProps {
  setIsSendNftOpen: (param: boolean) => any
}

interface StyledProps {
  isAllowed: boolean
}

const QuickActions: React.FC<IProps> = ({ setIsSendNftOpen }) => {
  const { isAllowed: isAllowedEditorUp } = useAccessController(UserRoles.Editor)
  return (
    <Wrapper isAllowed={isAllowedEditorUp}>
      {/* <TitleWrapper>Quick actions</TitleWrapper> */}
      <ButtonsWrapper>
        <SendNFT handleOpenModal={setIsSendNftOpen} />
        <MemberModal type="add" />
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default QuickActions

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop)
})<StyledProps>`
  // background-color: ${colors.$bgLight};
  padding: 16px;
  opacity: ${({ isAllowed }) => (isAllowed ? 1 : 0.5)};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

// const TitleWrapper = styled('div')`
//   font-weight: 700;
//   font-size: 20px;
//   width: 100%;

//   @media (max-width: 1000px) {
//     display: none;
//   }
// `

const ButtonsWrapper = styled('div')`
  width: 100%;
  display: flex;
  grid-column-gap: 12px;
  grid-row-gap: 10px;
  flex-direction: column;

  button {
    min-width: 180px;
    width: 100%;
    margin-left: 0;
  }

  @media (min-width: 1000px) {
    justify-content: flex-end;
    flex-direction: row;

    button {
      max-width: 180px;
    }
  }
`
