import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  styled,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchBar from 'src/components/SearchBar'
import apiService from 'src/services/api'
import { $logs, saveLogsInStore } from 'src/state/effector/history/logs.store'
import { ELogActionTypes, GetLogsParams, ILog } from 'src/state/effector/history/logs.types'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { colors } from 'src/utils/colorVariables'
import { isMobile } from 'src/utils/isMobile'
import { ActionTypeSelect } from './ActionTypeSelect'
import { LogsTable } from './LogsTable'
import isEqual from 'lodash/isEqual'
import { useStore } from 'effector-react'
import EmptyStateBox from '../Members/EmptyStateBox'
import { TablePagination } from '../Table/Pagination'

const OFFSET_VALUE = 25

export const Logs: React.FC = () => {
  const [page, setPage] = useState(1)

  const [teamWallet, setTeamWallet] = useState('')
  const [actionWallet, setActionWallet] = useState('')
  const [select, setSelect] = useState<ELogActionTypes[]>([])
  const logs = useStore($logs)

  useEffect(() => {
    const getLogs = () => {
      const logsParams: GetLogsParams = { page, offset: OFFSET_VALUE, teamWallet, actionWallet }

      if (!isEqual(select, [])) logsParams.actionTypes = select.join(',')

      apiService
        .getLogs(logsParams)
        .then((response) => {
          const logsData: ResponseWithPagination<ILog[]> = response?.data
          saveLogsInStore(logsData)
        })
        .catch(() => {
          saveLogsInStore(null)
        })
    }

    getLogs()
  }, [page, teamWallet, actionWallet, select])

  const handleResetPage = () => (page !== 1 ? setPage(1) : null)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.currentTarget.value.trim()
    const inputName = event.target.name

    if (inputName === 'teamWallet') {
      setTeamWallet(searchWord)
    }
    if (inputName === 'actionWallet') {
      setActionWallet(searchWord)
    }
    handleResetPage()
  }

  const handleSelect = (event: SelectChangeEvent<ELogActionTypes[] | []>) => {
    const selectedValue: string | [] | ELogActionTypes[] = event.target.value

    if (!Array.isArray(selectedValue)) return

    setSelect(selectedValue)

    handleResetPage()
  }

  const handleClearFilters = () => {
    setTeamWallet('')
    setActionWallet('')
    setSelect([])
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const mobile = isMobile()
  return (
    <Box>
      <Typography
        color={colors.$secondary}
        sx={{ fontSize: mobile ? 18 : 28, fontWeight: 300 }}
        variant="subtitle1"
      >
        Logs
      </Typography>
      {logs ? (
        <>
          <HrLine />
          <Grid container spacing={2} sx={{ paddingBottom: '25px' }}>
            <Grid item lg={3} md={6} xs={12}>
              <SearchBar
                name="teamWallet"
                value={teamWallet}
                onChange={handleSearchChange}
                placeholder="Search Team Wallet"
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <SearchBar
                name="actionWallet"
                value={actionWallet}
                onChange={handleSearchChange}
                placeholder="Search Action Wallet"
              />
            </Grid>
            <Grid item lg={4} md={8} xs={12}>
              <ActionTypeSelect value={select} onChange={handleSelect} />
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
              <ClearFilterButton variant="contained" onClick={handleClearFilters}>
                Clear Filters
              </ClearFilterButton>
            </Grid>
          </Grid>
          {logs?.items?.length ? (
            <>
              <Box>
                <LogsTable />
              </Box>
              <TablePagination response={logs} handleChange={handleChangePagination} page={page} />
            </>
          ) : (
            <EmptyStateBox message="No records found" />
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

const HrLine = styled(Box)`
  border-bottom: 1px solid silver;
  margin-top: 32px;
  margin-bottom: 30px;
`

const ClearFilterButton = styled(Button)`
  background-color: black;
  color: white;
  white-space: nowrap;
  height: 100%;
  line-height: 2;
  text-transform: none;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: ${colors.$gray2};
  }
`
