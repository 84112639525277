import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import styled from '@emotion/styled'
import LoadingButton from '@mui/lab/LoadingButton'

import { EUseGetUsers, getUsers } from 'src/hooks/useGetUsers'
import {
  $isLoadingUsers,
  $pendingUsers,
  $pendingUsersTrigger
} from 'src/state/effector/users/users.store'
import { colors } from 'src/utils/colorVariables'
import { isMobile } from 'src/utils/isMobile'
import PendingWhitelistTable from '../../Members/PendingWhitelist/PendingWhitelistTable'
import EmptyStateBox from '../../Members/EmptyStateBox'
import { HideUnhideSwitch } from '../../Members/HideUnhideSwitch'

const OFFSET_VALUE = 25

export const PendingWhitelist: React.FC = () => {
  const [offset, setOffset] = useState(OFFSET_VALUE)
  const [showHiddenUsers, setShowHiddenUsers] = useState(false)

  const mobile = isMobile()

  const pendingUsers = useStore($pendingUsers)
  const isLoading = useStore($isLoadingUsers)
  const trigger = useStore($pendingUsersTrigger)

  useEffect(() => {
    const getUsersFn = getUsers(
      {
        offset,
        page: 1,
        whitelist: 'PENDING',
        showHiddenUsers
      },
      EUseGetUsers.pendingUsers
    )

    getUsersFn()
  }, [offset, showHiddenUsers, trigger])

  const handleShowMore = () => {
    if (!pendingUsers?.nextPage) return
    setOffset((prev) => prev + OFFSET_VALUE)
  }

  const handleChangeSwitch = (value: boolean) => {
    setShowHiddenUsers(value)
  }

  return (
    <div>
      <Typography
        color={colors.$secondary}
        sx={{ fontSize: mobile ? 18 : 20, fontWeight: 500 }}
        variant="subtitle1"
        pb={3}
      >
        Pending Whitelist ({pendingUsers?.items?.length || 0})
      </Typography>
      {pendingUsers?.items?.length ? (
        <>
          <Box>
            <PendingWhitelistTable />
          </Box>
          {pendingUsers.items.length >= OFFSET_VALUE && (
            <Box
              sx={{
                padding: '20px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <LoadingButtonStyled
                color="info"
                variant="outlined"
                disabled={!pendingUsers?.nextPage}
                onClick={handleShowMore}
                loading={isLoading}
              >
                Show more
              </LoadingButtonStyled>
            </Box>
          )}
        </>
      ) : (
        <EmptyStateBox message={'All done! Great job 👍'} />
      )}
      <HideUnhideSwitch checked={showHiddenUsers} onChange={handleChangeSwitch} />
      {pendingUsers?.items?.length && pendingUsers.items?.length >= OFFSET_VALUE ? (
        <Box
          sx={{
            padding: '20px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingButtonStyled
            color="info"
            variant="outlined"
            disabled={!pendingUsers?.nextPage}
            onClick={handleShowMore}
            loading={isLoading}
          >
            Show more
          </LoadingButtonStyled>
        </Box>
      ) : null}
    </div>
  )
}

const LoadingButtonStyled = styled(LoadingButton)`
  text-transform: none;
  border-radius: 6px;
  font-weight: 600;
  color: ${colors.$blue};
  border-color: ${colors.$blue};
`
